import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import { Helmet } from 'react-helmet';

const Termsandconditions = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Terms & Conditions - Whizamet</title>
</Helmet>
<section className='privacy'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Terms & Conditions</h1>
           </div>
            </div>
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='privacycontent'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='pricontent'>
                  <h2>Terms and conditions</h2>
               <p>Welcome to Whizamet. Throughout the site, the terms “we”, “us” and “our” refer to Whizamet. By accessing our website or using our services, you agree to comply with and be bound by these terms and conditions. These terms apply to all users of our website, including without limitation users who are browsers, customers, merchants and content contributors. If you don't agree to our terms and conditions, please stop using our website.</p>
                
                <h3>By consenting to our terms of use, you agree not to:</h3>
                <ul>
                    <li>Use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction. </li>
                    <li>Transmit worms, viruses, or any destructive code that may disrupt the functioning of our website, its users or their devices.</li>
                    <li>Collect, store or share personal information of other users without their consent.</li>
                    <li>Violate copyrights, trademarks, patents or other intellectual property rights belonging to us or any third party.</li>
                    <li>Impersonate any person or entity, or provide false information with the intent to mislead or deceive others.</li>
                    <li>Interfere with the normal functioning of our website including but not limited to overloading servers, spamming or doing any activity that might degrade the user experience.</li>
                    <li>Violate guidelines, policies or rules provided on the website related to user behavior, content submission or interaction with other users.</li>
                    <li>Disclose sensitive or confidential information obtained through the website including but not limited to trade secrets, proprietary business information or user data. </li>
                </ul>
                <h3>Termination</h3>
                <p>We have the right to terminate the services with written notice if you breaches any terms of the agreement. Upon termination, all outstanding fees must be paid.</p>
                <h3>Intellectual Property</h3>
                <p>Any materials or intellectual property developed during the project will belong to the client upon full payment. However, we retain the right to showcase the work for promotional purposes.</p>
                <h3>Disclaimer </h3>
                <p>Our website strives to provide accurate and timely information but does not guarantee the completeness, accuracy, or timeliness of the information. Users acknowledge that they use the information at their own risk and discretion. Our website may also include third-party content, and we don't guarantee the accuracy and timeliness of such content.</p>
                <h3>Limitation of Liability </h3>
                <p>In no event shall Whizamet, its operators or its affiliates be liable for any direct, indirect, incidental, special, or consequential damages, or damages for loss of profits, revenue, data, or use, incurred by the user or any third party, whether in an action in contract or tort, arising from the use of the website or any content, services, or products obtained through the website. Our website and its operators shall not be liable for any delay, interruption, or unavailability of the website or any related services.</p>
                <h3>Indemnification</h3>
                <p>You agree to indemnify and hold the company, its affiliates, officers, directors, employees, agents, and partners harmless from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with the user's access to or use of the website, services, or content. This indemnification includes, but is not limited to, any breach of these terms and conditions by the user, violation of applicable laws, or infringement of any intellectual property rights.</p>
                <h3>Governing Law </h3>
                <p>These terms and conditions are governed by and construed in accordance with the local laws.</p>
                <h3>Changes to Terms </h3>
                <p>We reserve the right to modify these terms and conditions at any time. You’re advised to visit this page to stay updated with the changes in our terms and conditions.</p>
                <h3>Contact </h3>
                <p>If you have any questions or doubts about our privacy policy, please reach out to us via call or email at <a href="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
                </div>
            </div>
        </div>
    </div>
</section>







<Footer_v1 />
</>
)
}

export default Termsandconditions

