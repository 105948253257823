import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Careeropenings extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
          <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="true">
                        Graphics Designer
                        </h3>
                        <div id="faq-item-2-1" className="collapse show" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We are looking for an experienced graphics designer proficient in crafting compelling design strategies and visual designs for our diverse clientele. Drop-in your resume at  <strong><a href="mailto:hr@whizamet.com">hr@whizamet.com</a></strong></p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="false"> 
                        Content Writer
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                        <p>
                        We’re seeking a content writer with strong writing skills, creativity and a passion for creating high-quality content. If you have the skills to create engaging content, please send your resume to <strong><a href="mailto:hr@whizamet.com">hr@whizamet.com</a></strong>.
                        </p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Digital Marketer
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We have an open position for an experienced digital marketer with expertise in developing and executing effective digital marketing campaigns across digital platforms. Drop in your resume at <strong><a href="mailto:hr@whizamet.com">hr@whizamet.com</a></strong>.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        SEO Analyst
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            We are hiring a skilled SEO Analyst who can conduct comprehensive SEO audits, implement effective strategies to improve website rankings and boost organic traffic. Kindly send your resume to <strong><a href="mailto:hr@whizamet.com">hr@whizamet.com</a></strong>.     
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        Social Media Marketer
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            We have an exciting opportunity for a social media marketer who has expertise in developing and implementing social media strategies across all social platforms to drive audience engagement and brand awareness. If you’re interested, please send your resume to <strong><a href="mailto:hr@whizamet.com">hr@whizamet.com</a></strong>.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Careeropenings