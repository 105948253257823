
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import speaker from '../../images/speaker.png';
import leadgen from '../../images/leadgen.png';
import linkedinad from '../../images/linkedinad.webp';
import webdesigning from '../../images/webdesigning.png';
import link2 from '../../images/link2.webp';
import link4 from '../../images/link4.webp';
import link5 from '../../images/link5.webp';
import link3 from '../../images/link3.webp';
import link6 from '../../images/link6.webp';
import likedin2 from '../../images/likedin2.webp';
import LinkedInAudit from '../../images/LinkedInAudit.png';
import LinkedInAdStrategy from '../../images/LinkedInAdStrategy.png';
import LinkedInAdManagement from '../../images/LinkedInAdManagement.png';
import LinkedInAudienceAudits from '../../images/LinkedInAudienceAudits.png';
import LinkedInRetargeting from '../../images/LinkedInRetargeting.png';
import LinkedInAdReporting from '../../images/LinkedInAdReporting.png';

import React, {  useEffect } from 'react';
import * as $ from 'jquery';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';

const LinkedinAds = () => {
  useEffect(() => {
        $(document).ready(function() {
            $("div.bhoechie-tab-menu>div.list-group>a").click(function(e) {
                e.preventDefault();
                $(this).siblings('a.active').removeClass("active");
                $(this).addClass("active");
                var index = $(this).index();
                $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
                $("div.bhoechie-tab>div.bhoechie-tab-content").eq(index).addClass("active");
            });
        });
    
    }, []);
  

  
    return (
<>
<NavbarV3 />
<Helmet>
<title> Linkedin Advertising Agency | Linkedin Ads Agency</title>
<meta name="description" content="Whizamet is a performance-driven LinkedIn advertising agency that creates high-converting and ROI-focused LinkedIn ads to convert potential leads into conversions. " />
</Helmet>
<section className='googleadsbner'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>LinkedIn Advertising Agency</h1>
                <p className='bnrtext'>Get LinkedIn advertising services that result in <br/>sustainable, scalable growth.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={likedin2} className='likedin2' alt='circle' />
            </div>
        </div>
    
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='linkdin-one'>

<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our solutions</h3>
        <h2 className='webheadong'>Services that will elevate your <br />LinkedIn presence</h2>
     </div>
        </div>
            </div>
<div className='row' id='business'>
<div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInAudit} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn audit</a></h4>
    <p>Our team will conduct an in-depth audit of your existing ads, ad spending, and audience to figure out the best way to reduce your cost per lead.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInAdStrategy} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn ad strategy</a></h4>
    <p>We create advanced LinkedIn strategies tailored to your services and implement paid ad strategies to give your sales a significant boost.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInAdManagement} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn ad management</a></h4>
    <p>From campaign strategy to ad creation, optimization, remarketing and performance tracking, our experts will handle all aspects of your campaign.</p>
    </div>
    </div>
    </div>
    </div>
  
    <div className='row'>
    </div>
    </div>
    <div className='row' id='business'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInRetargeting} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn retargeting</a></h4>
    <p>With LinkedIn retargeting, we allow you to re-engage with the audience who had previously interacted with your ads or visited your website.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInAdReporting} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn Ad Optimization</a></h4>
    <p>Our LinkedIn team conducts in-depth analysis of your campaign analytics and makes informed decisions to optimize its performance.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={LinkedInAudienceAudits} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="">LinkedIn Ad Reporting </a></h4>
    <p>We consistently monitor your daily ad spend, engagement, and conversions to refine existing campaigns and plan upcoming ones. </p>
    </div>
    </div>
    </div>
    </div>
    
    </div>
      </div>
</section>
<section className='linkdin-two'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12' id='pc-first'>
             <div class="tech-left">
             <div class="seo2-left-inner"> 
             <div>
             <h2 className='thrive'>Driving growth through Proactive, advanced < br/>and data-driven strategies</h2> 
              <p class="content-wh">We pride ourselves on our ability to anticipate trends, identify opportunities and <br></br>craft strategies that will keep you ahead in the LinkedIn game. </p>
              </div>
               </div>
                </div> 
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12' id='pc-first'>
              <img src={linkedinad} className='linkdin22' alt='' />
              </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <div className='gadsbox'>
                   <h4 className='chat-wh'>Data-driven</h4>
                   <p>Our strategies are based on data, and our optimization processes are designed to be dynamic and adaptable.</p>
                    </div>
                    <div className='gadsbox'>
                    <h4 className='chat-wh'>Advanced Targeting</h4>
                   <p>Our extensive experience in LinkedIn advertising means that we have the knowledge of concepts that can get qualified leads.</p>
                    </div>
                    <div className='gadsbox'>
                    <h4 className='chat-wh'>Proactive approach</h4>
                   <p>We don't wait for opportunities but create them by staying ahead of the competition and market trends.</p>
                    </div>
            
                </div> 
            </div>
          
        </div>
  
 </section>



<section className="linkdin-three">
<div className="container">
<div className='Personalized'>
        <h3 class="mainsubheading">What makes us the best</h3>
        <h2 className='webheadong'>Why businesses choose us for <br />LinkedIn marketing</h2>
     </div>

  <div className="row">
 
      <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bhoechie-tab-menu">
        <div className="list-group">
          <a href="#" className="list-group-item2 active text-left">
          We have a dedicated and experienced team of LinkedIn marketers who know the nitty-gritty of LinkedIn
          </a>
          <a href="#" className="list-group-item2 text-left">
         
          We created customized campaigns tailored to your marketing objectives and target audience. 
          </a>
          <a href="#" className="list-group-item2 text-left">
          
          With Whizamet, you’ll never be in the dark. We will keep you updated about your campaign progress and improvement areas.
          </a>
          <a href="#" className="list-group-item2 text-left">
          
          We have a track record of delivering results for businesses across different verticals.
          </a>
          <a href="#" className="list-group-item2 text-left">
            
          When you hire us, you can rest assured that we are an extension of your team that will take care of every facet of your campaign.
          </a>
        </div>
      </div>
     
      <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12 bhoechie-tab">
        {/* flight section */}
        <div className="bhoechie-tab-content active">
          <center>
          <img src={link2} className='link' alt='' />
          </center>
        </div>
        {/* train section */}
        <div className="bhoechie-tab-content">
          <center>
          <img src={link3} className='link' alt='' />
          </center>
        </div>
        {/* hotel search */}
        <div className="bhoechie-tab-content">
          <center>
          <img src={link4} className='link' alt='' />
          </center>
        </div>
        <div className="bhoechie-tab-content">
          <center>
          <img src={link5} className='link' alt='' />
          </center>
        </div>
        <div className="bhoechie-tab-content">
          <center>
          <img src={link6} className='link' alt='' />
          </center>
        </div>
      </div>
    </div>
  </div>



</section>


   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default LinkedinAds

