import React from 'react';
import homebpo from "../../images/homebpo.png";

import callcenvideo from '../../images/callcenvideo.mp4';

const Callcenter = () => {
    return (
<>
<section className='bpo'>
    <div className='container'>
    <div className='row text-center'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
        <h2 className='Business'>Propelling businesses to new heights <br />of growth and success </h2>
        <p className='content'>Whether it's boosting your sales, increasing your profitability, propelling your business growth or <br />ensuring customer satisfaction, we’ve got you covered. </p>
    </div>
    </div>
     <div className='row' id='Aenean'>
     <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12' id="hme">
      <div className='bpo-box'>
        <h3>Inbound support  </h3>
        <p>Convert missed opportunities into successful sales. </p>
          </div>
          <div className='bpo-box'>
        <h3>Outbound Call Center </h3>
        <p>Expand your reach, boost sales potential and optimize ROI. </p>
          </div>
          <div className='bpo-box'>
        <h3>Operations Management</h3>
        <p>Streamline your processes, drive business growth and reduce operational expenses. </p>
          </div>    
        
     </div>
     
     <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center' id='myimg1'>
     <video src={callcenvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='callcenvideo'>
</video>
      {/* <img src={homebpo} className='homebpo' alt='' /> */}
     </div>

     <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12' id="hme">
     <div className='bpo-box'>
      <h3>Lead Generation </h3>
      <p>Get high-quality leads for your business with targeted strategies. </p>
      </div>
      <div className='bpo-box'>
      <h3>Chat & email support </h3>
      <p>Engage your customers in conversations that lead to potential sales.  </p>
      </div>
      <div className='bpo-box'>
      <h3>Telemarketing</h3>
      <p>Exploit new sales opportunities for increased revenue and business growth.  </p>
      </div>

     </div>
     </div>
    </div>
</section>


</>
)
}

export default Callcenter

