import React, { Component } from 'react';


class AboutN8 extends Component {

    render() {

       

    return <section className='team-sec'>
        <h3 class="mainsubheading animated fadeIn"> Join our team</h3>
         <h2 className='main-heading2'>Jump-start your career
         with<br></br><span class="colororange"> Whizamet </span></h2>
         <div class="text-center">
         <div class="bnrbtn"><a class="theme-btn-22 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
</div>
              
             </section>
			
        }
}

export default AboutN8