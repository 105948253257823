import React, { useRef, useState } from "react";
import PS1 from  "../../images/PS1.png";
import PS2 from "../../images/PS2.png";
import PS3 from "../../images/PS3.png";
import PS4 from "../../images/PS4.png";



// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Graphictools = () => {
  return (
    <>
      <div className="Graphictools">
            <div className="container-fluid">
            <div className="Graphictools-wrapper align-items-center">
         <div className="Graphictools-slider">
          <div className="swiper-Industriesserve">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 0,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                           1440: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={PS1} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS2} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS3} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS4} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS3} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS4} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={PS1} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={PS2} alt="" className="PS1" />
          </SwiperSlide>
         
         
                                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Graphictools;