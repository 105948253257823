import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';

import caseimg2 from '../../images/caseimg2.jpg';

import Page_header from '../global-components/page-header';
import { Helmet } from 'react-helmet';


const Homestaycasestudy = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Homestay Casestudy - Whizamet</title>
<meta name="description" content="" />
</Helmet>
<Page_header headertitle="Homestays Casestudy" />
{/* <section className='casesty'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
            <div className='casesty-content'>
            <h3 class="Expert animated fadeIn">Homestays</h3>
            <h1>Homestay Case Study</h1>
                <p className='bnrtext'>With 10+ years of working as a preferred Website Design and <br />Development company for brands.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 ellipse'>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 upimg'>
                <div className='container c1'>
                  <div className='row'>
                         <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg2} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='container c2'>
                  <div className='row'>
                        <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg3} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
               
            </div>  
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section> */}



<section className='csesty'>
 <div className='container'>
    <div className='row'>
        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 caseleft'>
          <h4 className='back'> About the client </h4>
            <p className='backpara'>Our homestay client was a proud owner of six homestays in Mussoorie-The Log House, The Silverton Chalet, The Horizon Villa, The Mini Horizon, The Pink Magnolia Cottage and The Eleven Oaks. The owner provided the finest collection of luxury villas, charming cottages and pet-friendly homes for a relaxing staycation in Mussoorie with homely comforts and modern amenities. </p>
          <h4 className='back'>The objective</h4>
            <p className='backpara'>The homestays’ owner aimed to increase the number of bookings and occupancy for each property. Additionally, she intended to boost search engine rankings for all 6 properties and wanted to have a consistent presence on social media platforms. </p>
            <h4 className='back'>The challenge </h4>
            <p className='backpara'>The initial challenge was the limited online visibility of all 6 properties on online platforms. Outdated websites and inactive presence across social media platforms were the biggest roadblocks in bookings. Navigational challenges and the complex booking process were another challenge that we had to tackle. Furthermore, to cater to a diverse segment of guests, we required strong marketing strategies. </p>
            <h4 className='back'>The strategy</h4>
            <p className='backpara'>To increase bookings and occupancy rates for all 6 properties, we implemented multifaceted marketing strategies that combined paid ads, search engine optimization, website designing, and social media management. </p>
<div class="cont">
<div class="rightbox">
  <div class="rb-container">
    <ul class="rb">
      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Websites redesign 
        </div>
        <div class="item-title">We thoroughly analyzed the existing websites of all 6 properties and redesigned them to improve user experience, navigation and booking functionalities.</div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Paid ads 
        </div>
        <div class="item-title">We executed targeted online advertising campaigns across Facebook and other social media platforms through creative ad copies that highlighted each property's features, amenities discounts and offers. We consistently monitored the campaign performance for the best results.</div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Social media management
        </div>
        <div class="item-title">We developed a cohesive social media presence across social platforms, including Facebook and Instagram. We established and maintained active profiles on Facebook and Instagram to increase visibility. Furthermore, we connected with potential guests on social media platforms by actively responding to their comments and messages. </div>
      </li>


      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Content creation
        </div>
        <div class="item-title">Our content writers created engaging content highlighting the scenic beauty of Mussoorie, high-end amenities and unique features of each homestay. Moreover, we created a content calendar to maintain a consistent posting schedule on social channels.</div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
       Search engine optimization
        </div>
        <div class="item-title">We conducted a thorough SEO audit of the websites of each property to boost online visibility and implemented on-page and off-page SEO strategies that included keyword optimization, link building and local SEO strategies to identify potential guests looking for a staycation in Mussoorie. We optimized website content, meta tags and images to boost organic search rankings.</div>
      </li>

    </ul>

  </div>
</div>
</div>
  
            <h4 className='back'>The results</h4>
            <p className='backpara'>Within the first quarter of implementing our digital marketing strategy, we were able to significantly increase online bookings and occupancy rates across all 6 properties, leading to increased revenue for our client. The redesigned websites resulted in increased user engagement and reduced bounce rates.</p>
            <p className='backpara'>Our SEO strategies improved search engine rankings and organic traffic. We positioned  “The Log House” on the first page of Google search rankings. Moreover, with our SMM strategies, we contributed to substantial follower growth on social media platforms for all properties and garnered positive reviews from clients.</p>
            {/* <img src={cs01} alt='' className='cs' /> */}

            </div>

        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center'>
           <img src={caseimg2} alt='' className='caseimg' />
        </div>
    </div>
  </div>
</section>


<Footer_v1 />
</>
)
}

export default Homestaycasestudy

