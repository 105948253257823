import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import casestudy1 from '../../images/casestudy1.jpg';
import casestudy2 from '../../images/casestudy2.jpg';
import casestudy3 from '../../images/casestudy3.jpg';


class Casestudiesgrid extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'
    return (
		<div className="ltn__blog-area ltn__blog-item-3-normal mb-100 mt-100 go-top">
			<div className="container">
			<div className="row">
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/homestaycasestudy"><img src={casestudy1} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/"><i className="fas fa-tags" />Homesaty</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/homestaycasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/homestaycasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/seocasestudy"><img src={casestudy2} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/service"><i className="fas fa-tags" />SEO</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/seocasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/seocasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/marketingcasestudy"><img src={casestudy3} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/service"><i className="fas fa-tags" />Content Marketing</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/marketingcasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/marketingcasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/seocasestudy"><img src={casestudy2} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/service"><i className="fas fa-tags" />SEO</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/seocasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/seocasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/homestaycasestudy"><img src={casestudy1} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/"><i className="fas fa-tags" />Homesaty</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/homestaycasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/homestaycasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
				{/* Blog Item */}
				<div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/marketingcasestudy"><img src={casestudy3} alt='' className='' /> </Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-tags go-top">
							<Link to="/service"><i className="fas fa-tags" />Content Marketing</Link>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/marketingcasestudy">Common Engine Oil Problems and Solutions</Link></h3>
					<p>Excellent publicity is an advertising agency that came into the market in 2011 and gained an envious reputation.</p>

					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<div className="ltn__blog-btn read">
						<Link to="/marketingcasestudy">Read more</Link>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>


				{/*  */}
			</div>
			<div className="row">
				<div className="col-lg-12">
				<div className="ltn__pagination-area text-center">
					<div className="ltn__pagination">
					<ul>
						<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
						<li><Link to="#">1</Link></li>
						<li className="active"><Link to="#">2</Link></li>
						<li><Link to="#">3</Link></li>
						{/* <li><Link to="#">...</Link></li>
						<li><Link to="#">5</Link></li> */}
						<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
					</ul>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
	  
    )
  }
}

export default Casestudiesgrid;
