import React from 'react';
import Footer_v1 from '../../global-components/footer';
import NavbarV3 from '../../global-components/navbar-v3';
import circle from '../../../images/circle.png';
import { Helmet } from 'react-helmet';
import search from "../../../images/smm-images/10838785_4574922.png";
import smm4 from "../../../images/smm-images/9074825_1341-[Converted].png";
import smm5 from "../../../images/smm-images/6450145_3075760.png";
import smm6 from "../../../images/smm-images/7230637_3594559.png";
import search1 from "../../../images/24070982_bwink_med_06_single_08-[Converted].png";
import credigi1 from "../../../images/creative-digital/cre-digi-1.png";
import credigi2 from "../../../images/creative-digital/cre-digi-2.png";
import credigi3 from "../../../images/creative-digital/cre-digi-3.png";
import credigi5 from "../../../images/creative-digital/cre-digi-5.png";
import Bgflyinglogos from '../../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../../section-components/bgflyinglogos2';
import Industriesserve from '../../section-components/clients/client1';
import ExceptionalDesigns from '../../../images/ExceptionalDesigns.png';
import innovative from '../../../images/innovative.png';
import QuickTurnaround from '../../../images/QuickTurnaround.png';
import SimplifiedGraphics from '../../../images/SimplifiedGraphics.png';
import UnlimitedRevisions from '../../../images/UnlimitedRevisions.png';
import ImpactfulVisuals from '../../../images/ImpactfulVisuals.png';
import AffordableServices from '../../../images/AffordableServices.png';
import BespokeServices from '../../../images/BespokeServices.png';
import faqvideo from '../../../images/faqvideo.mp4';
import Serivcecontactform from '../../section-components/serivcecontactform';
import Faqseocity from '../../global-components/faqcity';

const WDCinRaleigh

    = () => {
        return (
            <>
                <NavbarV3 />
                <Helmet>
                    <title>Responsive Web Design and Development Company in  Raleigh

                    </title>
                    <meta name="description" content="Opt for interactive and responsive web design services from the best web design and development company in  Raleigh

." />
                </Helmet>
                <section className='california'>
                    <div className='container'>
                        <div className='row mt-5'>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                <div className='bnr-cont'>
                                    <h3 class="mainsubheading ">Responsive Web Design</h3>
                                    <h1>Innovative Web Solutions in  Raleigh

                                    </h1>
                                    <p className='bnrtext'>Engage Traffic, Build New Customers, and Improve Conversions with Interactive <a href='/web-development' className='an1'> Web Design </a> Services in  Raleigh

                                        . </p>
                                    <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
                                </div>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-md-6 col-12 im1'>
                                <img src={search} className='search1' alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='left-seoshape1'></div>
                    <img src={circle} className='seocircle' alt='circle' />
                    <img src={circle} className='seocircle2' alt='circle' />
                </section>
                <section className='callww smm1'>
                    <div className='container'>
                        <div className='row text-center'>
                            <div className='col-xxl-12'>
                                <h2>What we do
                                </h2>
                                <p className='yro'>We help you turn your potential leads into paying customers. </p>
                            </div>
                        </div>
                        <div className='row smm4' id='stunning'>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>Shopify Development </h3>
                                        <p> For eCommerce businesses, our Shopify development services provide a smooth online shopping experience. We build and customize Shopify stores that are visually appealing, easy to navigate, and optimized for conversions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>WordPress Development</h3>
                                        <p>WordPress is a powerful platform for creating dynamic websites. Our WordPress development services include custom theme creation, plugin integration, and site maintenance. We ensure your WordPress site is secure, scalable, and easy to manage.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi3} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>React Development</h3>
                                        <p>Using  <a href='/react-development' className='an1'> React </a>, our developers create highly interactive and user-friendly web applications. React's component-based architecture allows us to build scalable and efficient applications that deliver a better user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>Web Optimization</h3>
                                        <p> Our web optimization services are designed to improve your site's performance and user experience. We focus on improving page load times, mobile responsiveness, and overall site speed.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>Web Content Creation </h3>
                                        <p>Our team of skilled writers creates engaging web content that attracts your audience and boosts your site's SEO. We specialize in creating blog posts, articles, product descriptions, etc. personalized to your brand's voice and target audience.   </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                                <div className='digi-single-serivce k11'>
                                    <span><img src={credigi5} className='cre-digi' alt='cre-digi-1' /></span>
                                    <div className='digi-single-content'>
                                        <h3>Custom Web Design</h3>
                                        <p> Stand out with a unique, custom-designed website. Our designers work closely with you to create a site that reflects your brand's identity and meets your specific needs. From layout to color schemes, we ensure every element is customized to your vision.  </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <div class="bnrbtn smm3"><a class="theme-btn-1 btn btn-effect-1 text-uppercase smmm" href="/contact-us" tabindex="0">Launch Campaign    </a></div>
                <section className='acalkeywords smm2'>
                    <div className='container'>
                        <div className='row text-center'>
                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                <h3 class="mainsubheading">Our Comprehensive Approach </h3>
                                <h2>Our Web Creation Process </h2>
                            </div>
                        </div>
                        <br />
                        <div className='container'>
                            <div className='row mb-5 mt-3'>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 image-parent'>
                                    <img src={smm4} className='smm-pic4' alt='' />
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                    <h3> Consultation and Planning</h3>
                                    <p>We start by understanding your business, target audience, and objectives. In our initial consultation, we gather insights into your brand’s identity, values, and unique selling points. This phase allows us to identify your specific needs and set clear project goals.
                                        <br></br>Our team conducts thorough research on industry trends, competitors, and user behavior. Using these insights, we create a comprehensive plan that outlines the project scope, timelines, and key milestones. This strategic approach ensures that every aspect of your website is meticulously planned and aligned with your goals.
                                    </p>
                                </div></div> </div>
                        <div className='container'>
                            <div className='row mb-5 mt-5'>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                    <h3>Design and Prototyping</h3>
                                    <p>In this phase, our talented designers craft wireframes and mockups to visualize the website’s structure and layout. We focus on creating an intuitive user interface (UI) and an engaging user experience (UX). Through iterative feedback sessions, we refine the design until it perfectly captures your vision.
                                    </p>
                                    <div class="bnrbtn smm5 "><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Start Branding</a></div>
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 image-parent'>
                                    <img src={smm5} className='smm-pic5' alt='' />
                                </div>
                            </div>
                        </div></div>
                    <div className='container'>
                        <div className='row mb-5 mt-5'>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 image-parent'>
                                <img src={smm6} className='smm-pic4' alt='' />
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                <h3>Development</h3>
                                <p>Once the design is approved, our skilled developers bring it to life using the latest technologies and coding standards. We ensure that your website is responsive, fast-loading, and optimized for search engines. Our developers work closely with designers to maintain design integrity and functionality.
                                    <br></br>Meanwhile, our content strategists work to develop high-quality,  <a href='/search-engine-optimization' className='an1'> SEO </a>-friendly content that resonates with your audience. We integrate this content smoothly into the design, ensuring it complements the visual elements and enhances the overall user experience.
                                </p>
                                <div class="bnrbtn smm5"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Get a Quote</a></div>
                            </div>
                        </div></div>
                </section>
                <section className='digi-logos'>
                    <div className='container'>
                        <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
                        <h2 className='Minimize'>Clients who trust us </h2>
                        <p class="bnrtext">We’ve partnered with several clients, ranging from budding startups to Fortune 500 companies. <br />Take a look at our valuable associations.  </p>
                        <div className='mobile-client'>
                            <Industriesserve />
                        </div>
                    </div>
                    <div className='logos-reviws'>
                        <br />
                        <Bgflyinglogos />
                        <br />
                        <br />
                        <Bgflyinglogos2 />
                        <br />
                        <br />
                        <Bgflyinglogos />
                        <br />
                        <br />
                        <Bgflyinglogos2 />
                        <br />
                        <br />
                        <Bgflyinglogos />
                        <br />
                    </div>
                </section>
                <section className='acalkeywords'>
                    <div className='container'>
                        <div className='row text-center'>
                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                <h3 class="mainsubheading">Our benefits </h3>
                                <h2>Why Choose Whizamet As Your Web Partner? </h2>
                            </div>
                        </div>
                        <br />
                        <div className="row ltn__custom-gutter">
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={ExceptionalDesigns} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Expertise and Experience </h4>
                                        <p> Our team is well-versed in the latest technologies and design trends.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={QuickTurnaround} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Customized Solutions </h4>
                                        <p>Our personalized designs ensure your website stands out from the competition.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={UnlimitedRevisions} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Comprehensive Services</h4>
                                        <p> You can rely on us for all your web-related needs, ensuring a smooth and efficient process.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 ">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={innovative} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Client-Centric Approach </h4>
                                        <p>We prioritize open communication, collaboration, and transparency throughout the project.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ltn__custom-gutter">
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={SimplifiedGraphics} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>SEO & Performance Optimization</h4>
                                        <p> We focus on performance optimization to provide fast, responsive, and user-friendly websites.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={ImpactfulVisuals} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Ongoing Support and Maintenance</h4>
                                        <p>We are always available to assist with any changes or enhancements.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={AffordableServices} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Proven Results</h4>
                                        <p>Our results-driven approach ensures measurable success, from improved user engagement to increased conversion rates.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
                                <div className="ltn__feature-item ltn__feature-item-6 c1">
                                    <img src={BespokeServices} className='ppc1' alt='' />
                                    <div className="ltn__feature-info">
                                        <h4>Innovation and Creativity</h4>
                                        <p> We ensure your website not only meets industry standards but also sets new ones. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='homefaqs'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
                                <video src={faqvideo} controls
                                    muted
                                    autoPlay={"autoplay"}
                                    preLoad="auto"
                                    loop id='faqvideo'>
                                </video>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                <h3 className='mainsubheading'>Your doubts answered </h3>
                                <h2>Frequently asked questions </h2>
                                <div className="ltn__faq-area">
                                    <div className="ltn__faq-inner ltn__faq-inner-2">
                                        <div id="accordion_2">
                                            {/* card */}
                                            <div className="card">
                                                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                                                    1. What makes Whizamet's website creation process unique?
                                                </h3>
                                                <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                                                    <div className="card-body">
                                                        <p>Our website creation process is highly collaborative and client-focused. We start with an in-depth consultation to understand your business goals and target audience. Our design and development phases are iterative. We ensure your feedback is incorporated at every stage.
                                                            <br></br>We prioritize both aesthetics and functionality. This results in a website that looks great and performs optimally.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* card */}
                                            <div className="card">
                                                <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true">
                                                    2.	How long does it typically take to design and develop a website in  Raleigh

                                                    ?
                                                </h3>
                                                <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                                                    <div className="card-body">
                                                        <div className="ltn__video-img alignleft">
                                                        </div>
                                                        <p>The timeline for designing and developing a website varies depending on the project's complexity and specific requirements. On average, a standard website takes 6-8 weeks from initial consultation to launch. More complex projects, such as e-commerce sites or custom web applications, may take longer. We provide a detailed timeline during the planning phase to keep you informed every step of the way.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* card */}
                                            <div className="card">
                                                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                                                    3.	Do you offer ongoing support and maintenance after the website is launched?
                                                </h3>
                                                <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                                                    <div className="card-body">
                                                        <p>We offer comprehensive support and maintenance services post-launch. This includes regular updates, security checks, performance optimization, and content updates. Our goal is to ensure your website remains secure and up-to-date. We ensure it continues to deliver excellent user experiences.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* card */}
                                            <div className="card">
                                                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                                                    4.	Can you help with content creation for my website in  Raleigh

                                                    ?
                                                </h3>
                                                <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                                                    <div className="card-body">
                                                        <p>We have a team of skilled content creators who can develop high-quality, SEO-friendly content tailored to your brand and audience. Whether you need blog posts, product descriptions, or multimedia content, we can handle all your content needs to ensure your website is both engaging and informative.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* card */}
                                            <div className="card">
                                                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                                                    5.	What platforms and technologies do you specialize in?
                                                </h3>
                                                <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                                                    <div className="card-body">
                                                        <p>Our team is proficient in a wide range of platforms and technologies. We specialize in WordPress development, Shopify development, and React development for dynamic web applications. We also use the latest tools and best practices in web optimization, SEO, and responsive design to ensure your website is future-proof and performs exceptionally well across all devices.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* card */}
                                        </div>
                                    </div>
                                </div>
                                <div className='fqbtn'>
                                    <a class="theme-btn btn btn-effect-1 text-uppercase" href="/faqs" tabindex="0">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Serivcecontactform />
                <Footer_v1 />

            </>
        )
    }


export default WDCinRaleigh

    ;

