import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import question from '../../images/question.png';
import Faqemail from '../section-components/faqemail';
import etarget from '../../images/etarget.png';
import etarget2 from '../../images/etarget2.png';
import etarget3 from '../../images/etarget3.png';
import guestposting from '../../images/guestposting.png';
import bloging from '../../images/bloging.png';
import emailmarketingresult from '../../images/emailmarketingresult.png';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';
import faqvideo from '../../images/faqvideo.mp4';

const Emailmarketing = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title> Email Marketing Agency | Email Marketing Specialist</title>
<meta name="description" content="Whizamet is a full-service email marketing agency that provides top services to help you reach your audience and increase sales. Connect with our specialists today." />
</Helmet>
<section className='Emailmarketing'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Email Marketing Services</h1>
                <p className='bnrtext'>Emails may not always drive instant results, but when they do, they pave the way towards <br />starting meaningful conversations and fostering long-lasting relationships.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>
       <div className='row' id='emailbnrrow'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={etarget} alt='' className='etarget' />
            <h4>Direct connection with <br />customers</h4>
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={etarget2} alt='' className='etarget' />
            <h4>Targeted  <br />engagement <br /></h4>
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={etarget3} alt='' className='etarget' />
            <h4>Cost-effective  <br />impact<br /></h4>
           </div>
        </div>
       </div>
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='emailserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">What we do</h3>
     <h2>We create comprehensive solutions <br />that fuel business growth</h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Email marketing strategy</h3>
      <p className="description">
      Our email marketing team is an expert in creating tailored experiences that match your business goals. Whether you want interactive emails that boost engagement or promotional emails that fuel growth, we offer a wide gamut of services to ensure that your message reaches the target audience at the right time.
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Database audit services</h3>
      <p className="description">
      We provide comprehensive database audit services to improve the effectiveness of email campaigns. Our team gathers the mail list of your recipients and creates targeted lists for optimal performance. We pay minute attention to every detail, meticulously auditing the recipient’s contact and account information.
      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Content planning</h3>
      <p className="description">
      Content planning involves creating a roadmap for your email campaigns to achieve desired results. Our content creators create high-quality, engaging content, including well-crafted copy, attention-grabbing headlines and attractive visuals that meet your business objectives.
      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
 </div>
           <div className="row" id='adipisicing'>
           <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Creating designs</h3>
      <p className="description">
      Our design wizards specialize in creating visually appealing email designs that create a lasting impression. Right from color schemes to typography, we ensure that your emails reflect your brand essence. Also, using cutting-edge techniques, we make sure that our every email adapts seamlessly to every screen size.
      </p>
          <div className="service-icon">
          <img src={bloging} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Comprehensive monitoring</h3>
      <p className="description">
      Our dedicated team keeps a vigilant eye on every aspect of your email campaign. We consistently monitor open rates, track bounced emails, and categorize subscribed and unsubscribed emails. Furthermore, we also keep a tab on your email list, implementing strategies to grow it organically.
      </p>
          <div className="service-icon">
          <img src={KeywordResearch} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Insightful reporting</h3>
      <p className="description">
      Our reports are proof of our success and provide us guidance for new campaigns, showing what works best and what areas you need to improve on. Moreover, they provide an insight into recipients' behaviour- what they click, how they engage and what type of content they view.
      </p>
          <div className="service-icon">
          <img src={Technical} alt='' className='localseo' />
           </div>
    </div>
  </div>

</div>
     
</div>
</section>
<section className='seoresult'>
    <div className='container'>
        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='reslt'>
            <h3 class="mainsubheading">Creating unique connections</h3>
     <h2>Customized strategies, <br/>Measurable results</h2>
     <p class="content">In the digital world, a one-size-fits-all approach does not yield results. Therefore, we create highly engaging email templates that communicate directly to the audience. Our every email design is tailored as per to your customer’s needs, ensuring a personalized experience.</p>
            </div>
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={emailmarketingresult} className='emailmarketingresult' alt='' />
            </div>
        </div>
    </div>
</section>
<section className='emaol'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12' id='pc-first'>
             <div class="seo2-left">
             <div class="seo2-left-inner"> 
             <div>
             <h3 class="mainsubheading">Why choose us</h3>
              <h2>Benefits of our Email marketing services</h2> 
              <p class="content">We offer comprehensive solutions that are effective, affordable and suitable for industries across different verticals.</p>
              </div>
               </div>
                </div> 
                </div>
                <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12'>
               <div className='row' id='match'>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Custom-made solutions</h4>
                   <p>We craft custom-made solutions that match the unique requirements of clients.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Cost-effectiveness guaranteed</h4>
                   <p>We ensure that you receive top-notch services without straining your budget.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Versatility across industries</h4>
                   <p>Our services cater to a multitude of industries- be it healthcare, technology or any other sector.</p>
                    </div>
                </div>
               </div>
               <div className='row' id='pc'>
               <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Cutting-edge technology</h4>
                   <p>We harness the power of the latest tools and techniques to widen your business reach.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Data-driven decisions</h4>
                   <p>Our decisions are based on data. We employ cutting-edge analytics tools to analyze campaigns.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Dedicated team</h4>
                   <p>Our dedicated team has the expertise to transform emails into powerful tools for your success.</p>
                    </div>
                </div>
               </div>
                </div> 
            </div>
          
        </div>
  
 </section>
 <section className='email-process'>
  <div className='container'>
  <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">How we do it </h3>
     <h2>Our proven steps to generate<br /> maximum ROI</h2>
      </div>
    </div>
    <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <ul className="process">
  <li className="process__item">
    <span className="process__number">1</span>
    <span className="process__title">Strategic  &amp; planning</span>
    <span className="process__subtitle">
    We work closely with you to understand your goals, target audience and messaging objectives.
    </span>
  </li>
  <li className="process__item">
    <span className="process__number">2</span>
    <span className="process__title"> Creative content creation</span>
    <span className="process__subtitle">
    From attention-grabbing subject lines to compelling call-to-actions, we create content that turns clicks into conversions.
    </span>
  </li>
  <li className="process__item">
    <span className="process__number">3</span>
    <span className="process__title">Design & Development</span>
    <span className="process__subtitle">
    Our design experts bring your concept to life by creating visually appealing and responsive email templates.
    </span>
  </li>
  <li className="process__item">
    <span className="process__number">4</span>
    <span className="process__title">Deployment & Analysis</span>
    <span className="process__subtitle">
    We deploy your emails at strategic timings while measuring their success through key metrics.
    </span>
  </li>
</ul>
      </div>
    </div>
  </div>
 </section>
 <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
          {/* <img src={question} alt='' className='question' /> */}
          <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered</h3>
          <h2>Frequently asked questions</h2>
          <Faqemail />
         
        </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Emailmarketing

