import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import webdesigning from '../../images/webdesigning.png';
import contentwriting from  '../../images/contentwriting.webp';
import branding from '../../images/branding.png';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Serivcecontactform from '../section-components/serivcecontactform';
import funel from '../../images/funnel.png';
import { Helmet } from 'react-helmet';
import Industriesserve from '../section-components/clients/client1';
import demandgeneration from  '../../images/demandgeneration.png';
import emailmarketing1 from  '../../images/emailmarketing1.png';
import linkedinmarketing from  '../../images/linkedinmarketing.png';
import landingpage1 from  '../../images/landingpage1.png';


const Leadgeneration = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Lead Generation Services | Lead Generation Agency</title>
<meta name="description" content="Searching for a lead generation agency to drive qualified leads for your business? At Whizamet, we provide the best lead generation services to grow your leads." />
</Helmet>
<section className='Lead-Generation leadgen'>
<div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Lead Generation Services</h1>
                <p className='bnrtext'>Fuel your sales pipelines with qualified leads with our ROI-focused lead generation services </p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>

        <div className='row' id='digi-reputation'>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={demandgeneration} className='whizserv' alt='' />
                </div>
                <h3> Demand generation </h3>
                <hr />
             <p>From increasing your brand awareness to fostering customer loyalty, we will build relationships that will boost your visibility. </p>
            
                </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={emailmarketing1} className='whizserv' alt='' />
                </div>
                <h3>Email marketing </h3>
                <hr />
              <p>We go beyond just sending emails. We create highly effective campaigns that increase engagement and conversions.</p>
            
                </div>
            </div> 
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={linkedinmarketing} className='whizserv' alt='' />
                </div>
                <h3>Linkedin marketing </h3>
                <hr />
                <p>We will improve your LinkedIn lead generation efforts by managing your entire campaign from start to finish.</p>
              
                </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={landingpage1} className='whizserv' alt='' />
                </div>
                <h3>Landing pages</h3>
                <hr />
              <p> We are experts in creating high-performing landing pages for every type of website and business. </p>
          
                </div>
            </div>        
        </div>
     
           </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='lead-gen'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <h2>B2B lead generation services that drive real results for every business</h2>
            <p class="content">Whizamet is a lead generation agency that specializes in generating qualified leads for businesses. Our lead generation team works collaboratively to fill your sales pipeline with high-converting targeted leads. </p>
            <p class="content">Our team is well aware of the nuances of the lead genesis game. No matter what type of business you’re into, we have the chops to get relevant leads straight to your doorstep. </p>
                 </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={funel} className='funel' alt='' />
            </div>
        </div>
    </div>
</section>



   <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='combination'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h2>Quality over Quantity: That’s our approach </h2>
                <p class="content">While some lead generation agencies focus on the number of leads, we are different. Our approach to lead generation is “quality over quantity.” We carefully craft our lead generation strategies, making sure that every lead we generate is not just a number but a meaningful relationship that we have to nurture. </p>
                <div className='inslist'>
                    <h3>Higher conversion rates</h3>
                    <p> By targeting individuals genuinely interested in your products or services, we maximize the chances of converting them into loyal customers. </p>
                </div>
                <div className='inslist'>
                    <h3>Long-term growth</h3>
                    <p>Through nurturing relationships with your leads, we’re creating loyal brand advocates who will contribute to your long-term success. </p>
                </div>  
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-center'>
           <img src={contentwriting} className=' contentwriting1' alt='' />
            </div>
        </div>
    </div>
</section>

<section className='Lead-Scalable'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <h2>Here’s why we stand out </h2>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <p class="content">Take advantage of our unmatched expertise to acquire validated leads for your business that can significantly boost your sales and help you grow your business by leaps and bounds. .</p>
        </div>   
    </div>
    <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
           <h4>Hot leads every day</h4>
            <p>With us, you’ll unlock a steady stream of high-quality leads on a daily basis, provided your campaign is running smoothly. </p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
             <h4>Better visibility </h4>
            <p>Stay ahead of the competition by getting higher visibility in front of your target audience. 
</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
          <h4>Boost conversions with retargeting </h4>
            <p>With our team by your side, you’ll never miss out on potential customers who have visited your website in the past. </p>
           </div>  
            </div>
        </div>
</div>
</section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Leadgeneration

