import React, { useRef, useState } from "react";
import landingpg5 from  "../../images/landingpg5.webp";
import landingpg6 from  "../../images/landingpg6.webp";
import landingpg7 from  "../../images/landingpg7.webp";
import landingpg8 from  "../../images/landingpg8.webp";
import landingpg9 from  "../../images/landingpg9.webp";
import landingpg10 from  "../../images/landingpg10.webp";
import landingpg11 from  "../../images/landingpg11.webp";
import landingpg12 from  "../../images/landingpg11.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules


const Landingpagetool = () => {
  return (
    <>
      <div className="Shopifyctools">
            <div className="container-fluid">
            <div className="Shopifyctools-wrapper align-items-center">
         <div className="Shopifyctools-slider">
          <div className="swiper-Shopifyctools">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 0,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                           1440: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={landingpg5} alt="" className="landingpg5" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg6} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg7} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg12} alt="" className="shop1" />
          </SwiperSlide>
         
          <SwiperSlide>
            <img src={landingpg8} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg9} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg10} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={landingpg11} alt="" className="shop1" />
          </SwiperSlide>
       
                                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Landingpagetool;