import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import whitboad1 from '../../images/whitboad1.png';
import circle from  '../../images/circle.png';
import whiteboardvideo from '../../images/whiteboardvideo.gif';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';
import couple from '../../images/couple.gif';
import whitteam from '../../images/whitteam.webp';
const Whiteboardanimation = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best Whiteboard Animation Video Services in India</title>
<meta name="description" content="Bring your ideas to life with our whiteboard animation video services. Engage audiences creatively and tell your story visually. Elevate your brand with captivating animations." />
</Helmet>
<section className='Whiteboardanimation'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Whiteboard Animation Video</h1>
           <p className='bnrtext'>We create animated videos that explain complex stories in a simple way.</p>
              <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
             </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
           
            <img src={whitboad1} alt='' className='whitboad' />
            </div>
        </div>
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='white-Solutions'>
    <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">What we do</h3>
     <h2>We transform ideas into <br />engaging stories</h2>
     <p class="content">We offer a wide gamut of video animation services that boost engagement, increase conversions and maximize ROI.</p>
     </div>
        </div>
            </div>
            <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
             <h4>Explainer videos</h4>
            <p>We make informative explainer videos that give clear, concise and compelling explanations of your products or services.</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
               <h4>Corporate videos</h4>
            <p>We convert boring data into dynamic animations that will inspire, inform and captivate the interest of your audience.</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
            <h4>Educational videos</h4>
            <p>Our team creates engaging videos that simplify complex topics, making learning more enjoyable.</p>
           </div>  
            </div>
        </div>      
    </div>
</section>
<section className='white-manage'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <h2>We’re not just animators, we’re video strategists</h2>
            <p class="content">Our expertise goes beyond video animation. As one of the best whiteboard video animation agencies, we have mastered the art of creating compelling visuals that convey your brand’s story, message and products in the most effective manner.</p>
            <p class="content">Whether you’re looking for engaging whiteboard animation videos, product demos or professional corporate videos, we are your go-to- choice for all your animation requirements.</p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={couple} alt='' className='couple' />
            </div>
        </div>
    </div>
</section>
<section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='Whiteboard-craft'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
         <h2>We create animations that drive great metrics</h2>
         <p class="content">Whizamet is more than a creative agency. We’re your long-term partners committed to delivering exceptional results.</p>
        <p class="content">With innovation at our core, we specialize in creating engaging video campaigns that achieve great results on every platform- be it a website, social media or email marketing campaign.</p>
        <p class="content">Regardless of your business objectives- whether you're looking to generate higher leads, increased conversions or more sales, we have the expertise to turn your goals into achievements.</p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <img src={whitteam} alt='' className='whitteam' />
            </div>   
        </div>
    </div>
</section>
<section className='Whiteboard-detail'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <img src={whiteboardvideo} alt='' className='whiteboardvideo' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h2>The Whizamet difference- Providing quality services without burning your pocket</h2>
                <p class="content">At Whizamet, we believe that every business, irrespective of its size, should have access to impressive visual content. That’s why we deliver top-notch whiteboard video animation services without putting a strain on your pocket. Our team is dedicated to creating compelling videos that communicate your message, all while being cost-effective.</p>
                <p class="content">Additionally, we have a predefined process for video animations with multiple layers of quality control and quick turnaround times that ensure a seamless journey from start to finish.</p>
            </div>
        </div>
    </div>
</section>




<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Whiteboardanimation

