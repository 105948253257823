import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import B2btools from '../section-components/b2btools';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import guestposting from '../../images/guestposting.png';
import BackLinkBuilding from '../../images/BackLinkBuilding.png';
import BBSSERIVCE from '../../images/BBSSERIVCE.webp';
import Insights from '../../images/Insights.webp';
const B2B = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>B2B Marketing Agency | B2B Digital Marketing Agency</title>
<meta name="description" content="Whizamet is a full-service B2B marketing agency that delivers high-quality and result-driven digital marketing services for B2B businesses. Get started with us today." />
</Helmet>

<section className='B2B'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>B2B Marketing Agency</h1>
                <p className='bnrtext'>We help B2B companies foster sustainable business growth, achieve high-quality lead <br />generation and measurable revenue. </p>
                 </div>
            </div>
        </div>
     <div className='row'>
     <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
     <B2btools />
     </div>
     </div>
    </div>
    <div className='left-seoshape1'></div>
  
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='B2B-sec1'>
<div className="container">
<div className='row'>
   <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-md-6 col-12'>
   <div className='organizations'>
    <h3 class="mainsubheading">B2B marketing </h3>
    <h2>Scalable B2B marketing that knows no boundaries</h2>
    <p class="content">At Whizamet, we create campaigns that defy limits, reaching audiences across the globe and propelling your business to new heights. </p>
    <p class="content">Whether you’re a local brand or a global business, we can elevate your marketing game with our scalable B2B marketing strategies. </p>
    <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
</div>
</div>
  <div className='col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-md-6 col-12 text-center'>
    <img src={BBSSERIVCE} className='BBSSERIVCE' alt='BBSSERIVCE' />
    </div>
</div>
</div>
</section>
<section className='bb2'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12' id='pc-first'>
             <div class="seo2-left">
             <div class="seo2-left-inner"> 
             <div>
             <h3 class="mainsubheading">Our Growth Solutions  </h3>
              <h2>Our multi-channel B2B marketing solutions </h2> 
             
              </div>
               </div>
                </div> 
                </div>
                <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12'>
               <div className='row'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Web services </h4>
                   <p>Design websites that ensure a smooth journey for customers at every stage of the pipeline. </p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>SEO </h4>
                   <p>Make your brand discoverable by audience and drive traffic through white hat SEO strategies. </p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Social media marketing </h4>
                   <p>Engage audiences on social channels via compelling thought leadership content. </p>
                    </div>
                </div>
               </div>
               <div className='row' id='pc'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Demand generation </h4>
                   <p>Speed up your demand with paid marketing, strategic ABM and multi-channel campaigns. </p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Content creation </h4>
                   <p>High-quality content creation to make your brand stand out as a thought leader in the digital world. </p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='ppcbox'>
                   <h4>Branding </h4>
                   <p>Create a strong foundation for successful marketing with data-driven strategies and captivating creatives. </p>
                    </div>
                </div>
               </div>
                </div> 
            </div>
          
        </div>
  
 </section>
 <section className='pipeline'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={Insights} className='Leadb2b' alt='Leadb2b' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <h2>Data-driven insights to create winning strategies </h2>
                <p class="content">At Whizamet, data is the backbone of all our high-performing campaigns and successful strategies. We harness the power of qualitative and quantitative data to unravel hidden insights and discover growth opportunities for you. </p>
                <p class="content">As a leading B2B marketing agency, we know the nitty-gritty details that help ambitious brands stand out in the crowded digital space. </p>
            </div>

        </div>
    </div>
 </section>
<section className='B2Bserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
         <div className='Personalized'>
        <h3 class="mainsubheading">Why choose us </h3>
     <h2> Why are we different from other B2B marketing agencies ? </h2>
     <p class="content">Whizamet is not a typical B2B marketing agency. We’re your growth partners and an extension of your team committed to scaling your brand. </p>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Expertise</h3>
      <p className="description">
      Our expertise in various verticals can help you grow your brand, increase sales and generate revenue. 
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Innovative thinking </h3>
      <p className="description">
      We integrate our decades of experience with data-driven insights and cutting-edge technology to create out-of-the-box solutions. 
      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Continuous refinement </h3>
      <p className="description">
      We don't believe in the “stop-start” approach. Instead, we focus on consistent monitoring and improving campaigns for better results. 
      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Tailored strategies </h3>
      <p className="description">
      Whether you’re a startup looking for rapid growth or an established brand seeking for market dominance, we have you covered. 
      </p>
          <div className="service-icon">
          <img src={BackLinkBuilding} alt='' className='localseo' />
           </div>
    </div>
  </div>
</div>
  
</div>
</section>

<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default B2B

