import React, { Component } from 'react';


class Faqgraphic extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        What makes your graphic design services stand out from others in the industry?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                        <p><srtong>Our graphic design services stand out for several reasons. </srtong></p>
                           <ul className='understand'>
                            <li>Firstly, we understand your unique brand identity and target audience to ensure our designs align with the interests of your audience. </li>
                            <li>Secondly, our experienced graphic designers stay up-to-date with the latest design trends and technologies. </li>
                            <li>Additionally, we offer a wide spectrum of services, including logo design, branding, website design, print materials, and digital marketing assets to establish your brand identity. </li>

                           </ul>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How long does it take to finish the design project?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            {/* <div className="ltn__video-img alignleft">
                            <img src={publicUrl+"assets/img/bg/17.jpg"} alt="video popup bg image" />
                            <a className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----" href="https://www.youtube.com/embed/LjCzPp-MK48?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
                                <i className="fa fa-play" />
                            </a>
                            </div> */}
                            <p>There are various factors that affect the duration of the design project, such as the client’s requirements and project complexity. On average, graphic designing projects take a few weeks to several weeks to complete. Our designers collaborate closely with our clients to establish project timelines and ensure timely delivery.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Does your graphic designing agency provide customized services?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes, we do. We have a variety of customized plans for our clients. You may let us know your design preferences, budget and deadline. We will customize our services as per your needs.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How will the clients be engaged throughout the design process?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our clients are actively involved in the design process. They are the ones who will provide us with the information, review the entire process and approve the final design.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How much do you charge for your graphic designing services?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>The cost of our graphic designing services varies depending on several factors, such as project complexity, the number of revisions, and the scope of the work. We offer customized packages based on your project details. Please let us know your specific requirements so that we can provide you with a quote tailored to your needs.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqgraphic