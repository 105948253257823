import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import gds from  '../../images/gds.gif';
import refine from  '../../images/refine.webp';
import Review from  '../../images/Review.webp';
import onlineshopping from  '../../images/onlineshopping.png';
import RealValue from  '../../images/RealValue.webp';
import Shopingad from  '../../images/Shopingad.webp';

import dashboard from  '../../images/dashboard.png';
import socialstrategy from  '../../images/socialstrategy.png';
import gshopping from '../../images/gshopping.webp';
import { Helmet } from 'react-helmet';

import Serivcecontactform from '../section-components/serivcecontactform';

const GoogleShopping = () => {

    return (
<>
<NavbarV3 />
<Helmet>
<title>Online Shopping Ads | Google Shopping Ads Company</title>
<meta name="description" content="Seeking a reliable and recognized Google shopping ads company? At Whizamet, we create ROI-driven online shopping ads that maximize leads, conversions, and sales." />
</Helmet>
<section className='googleshopingsbner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Google Shopping Ads Agency</h1>
            <p className='bnrtext'>Make your e-commerce store break through the noise with trusted Google shopping ads services.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={gshopping} className='gsping' alt='' />
            </div>
        </div>
    
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className="ps-timeline-sec">
 <div className="container">
  <div className='row'>
    <div className='col-xxl-12 col-lg-12 col-md-12 col-12'>
       
        <h2>How we convert your e-commerce store <br />into a profitable venture</h2>
        <div className="heading-separator" />
      </div>
   
  </div>
      <div className="row">
      <div className='col-xxl-12 col-lg-12 col-md-12 col-12'>
      <ol className="ps-timeline">
      <li>
        <div className="img-handler-top">
        <img src={gds} className='mb-1-6 rounded' alt='circle' />
        </div>
        <div className="ps-bot">
          <p>We’ll discuss your goals and expectations to create the right strategy for your business.</p>
        </div>
        <span className="ps-sp-top">Onboard</span>
      </li>
      <li>
        <div className="img-handler-bot">
        <img src={RealValue} className='mb-1-6 rounded' alt='circle' />
        </div>
        <div className="ps-top">
          <p>
          We’ll set your ad campaign with intelligent bidding, data-driven targeting and optimized structures.
          </p>
        </div>
        <span className="ps-sp-bot">Set up</span>
      </li>
      <li>
        <div className="img-handler-top">
        <img src={refine} className='mb-1-6 rounded' alt='circle' />
        
        </div>
        <div className="ps-bot">
          <p>
          We’ll monitor your campaigns on a regular basis through advanced optimization techniques to improve performance.
          </p>
        </div>
        <span className="ps-sp-top">Refine</span>
      </li>
      <li>
        <div className="img-handler-bot">
        <img src={Review} className='mb-1-6 rounded2' alt='circle' />
        </div>
        <div className="ps-top">
          <p>We’ll send you monthly reports to keep you informed about the progress of your Google ad shopping campaign.</p>
        </div>
        <span className="ps-sp-bot">Report</span>
      </li>
    </ol>
    </div>
      </div>
    </div>
  </section>
  <section className='googleadsvalue22'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12' id='pc-first'>
             <div class="tech-left">
             <div class="seo2-left-inner"> 
             <div>
              <h3 class="mainsubheading white animated fadeIn">What We Offer</h3>
    <h2 className='Minimize'>Services that maximize every marketing<br></br> penny spent </h2>
              </div>
               </div>
                </div> 
            </div>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
               <div className='row gy-5 row-cols-xl-3 row-cols-lg-1 row-cols-md-1 row-cols-sm-1 row-cols-1 justify-content-center'>
               <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                    <div className="card service-wrapper rounded border-0 shadow p-4">
      <div className="icon text-center text-custom h1 shadow rounded bg-white">
      <img src={onlineshopping} className='uim-primary' alt='circle' />
      
      </div>
      <div className="content">
        <h3 className="iconboxtitle">Google shopping management</h3>
        <p className="text-muted mt-3 mb-0">
        Our campaign management services focus on increasing returns on your shopping campaigns through precise targeting and persona-based modelling.
        </p>
        
      </div>
      <div className="big-icon h1 text-custom">
        <span className="uim-svg" style={{}}>
        <img src={onlineshopping} className='uim-primary' alt='circle'/>
            <rect
              width={20}
              height={15}
              x={2}
              y={3}
              className="uim-tertiary"
              rx={3}
            />

        </span>
      </div>
    </div>
                    
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                    <div className="card service-wrapper rounded border-0 shadow p-4">
      <div className="icon text-center text-custom h1 shadow rounded bg-white">
      <img src={dashboard} className='uim-primary' alt='circle' />
      
      </div>
      <div className="content">
        <h3 className="iconboxtitle">Product feed optimisation</h3>
        <p className="text-muted mt-3 mb-0">
        Our experts will work with you to refine the data and content within product feeds for better visibility and improved performance in the digital marketplace.
        </p>
        
      </div>
      <div className="big-icon h1 text-custom">
        <span className="uim-svg" style={{}}>
        <img src={dashboard} className='uim-primary' alt='circle'/>
            <rect
              width={20}
              height={15}
              x={2}
              y={3}
              className="uim-tertiary"
              rx={3}
            />

        </span>
      </div>
    </div>
                    
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                    <div className="card service-wrapper rounded border-0 shadow p-4">
      <div className="icon text-center text-custom h1 shadow rounded bg-white">
      <img src={socialstrategy} className='uim-primary' alt='circle' />
      
      </div>
      <div className="content">
        <h3 className="iconboxtitle">Merchant centre management</h3>
        <p className="text-muted mt-3 mb-0">
        From setting up the merchant centre to supporting rejections or warnings, we offer everything that simplifies your selling experience.
        </p>
        
      </div>
      <div className="big-icon h1 text-custom">
        <span className="uim-svg" style={{}}>
        <img src={socialstrategy} className='uim-primary' alt='circle'/>
            <rect
              width={20}
              height={15}
              x={2}
              y={3}
              className="uim-tertiary"
              rx={3}
            />

        </span>
      </div>
    </div>
                    
                    </div>
                </div>
               </div>

                </div> 
            </div>
          
        </div>
  
 </section>
 <section className='techsupport-two'>
 <div className='container'>
        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <h3 class="mainsubheading">Why choose us</h3>
     <h2>We create powerful campaigns that <br></br>lead to better CTR</h2>
     <h3 className="iconboxtitle">Attract visitors</h3>
     <p class="content-width22">We’ll not only help you get clicks but also create eye-grabbing ads that will attract visitors and convert them into loyal customers.</p>
     <h3 className="iconboxtitle">Wider reach</h3>
     <p class="content-width22">Whenever a potential customer searches for the products or services you provide, they will find your product listing.</p>
     <h3 className="iconboxtitle">Higher ROI</h3>
     <p class="content-width22">Whether you’re a budding startup or a well-established brand, we can create a successful campaign for you that will generate profitable returns.</p>
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='fbimge'>
                <img src={Shopingad} className='Shopingad' alt='' />
            </div>
        </div>
    </div>

</section>


   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default GoogleShopping

