import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ContMap extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="google-map1">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.345736125829!2d76.72332677610245!3d30.68054708815195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fefb18ae3342d%3A0xbd232bde01368bba!2sWhizamet%20Services%20Pvt%20Ltd%20-%20B2B%20Service!5e0!3m2!1sen!2sin!4v1705899759072!5m2!1sen!2sin"width="100%" height="600px" frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
		</div>
        }
}

export default ContMap