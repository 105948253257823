import React from 'react';
import IEC from '../../images/IEC.png';
import ISO from '../../images/ISO.png';
import Vector from '../../images/Vector.png';

const AboutN6 = () => {
    return (
<>
<section className='sectionrrr'>
				<div className="container" >
				<div className="row">
					<div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                    <div className='bg-img' id='whitebg'>
                    <h3 class="mainsubheading animated fadeIn">Our achievements </h3>
                    <h2>Explore our award cabinet </h2>
                    <br />
                    <img src={IEC} alt='' className='IEC' />
                    <img src={ISO} alt='' className='ISO' />
                    <img src={Vector} alt='' className='Vector' />
                     </div>
					</div>
                    
					</div>
				</div>
				</section>




</>
)
}

export default AboutN6

