import React from 'react';
import PageHeader from './global-components/page-header';
import Error from './section-components/error';

import Footer_v1 from './global-components/footer';
import NavbarV3 from './global-components/navbar-v3';

const ErrorPage = () => {
    return <div>
        <NavbarV3 />
        <PageHeader headertitle="404 Page" />
        <Error />
    
        <Footer_v1 />
    </div>
}

export default ErrorPage

