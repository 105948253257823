import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from '../../images/circle.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import guestposting from '../../images/guestposting.png';
import BackLinkBuilding from '../../images/BackLinkBuilding.png';
import bloging from '../../images/bloging.png';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import OnPage from '../../images/OnPage.png';
import seoresults from '../../images/seoresults.png';
import DedicatedTeam from '../../images/DedicatedTeam.png';
import ContinuousOptimization from '../../images/ContinuousOptimization.png';
import CustomizedSolutions from '../../images/CustomizedSolutions.png';
import DataDrivenStrategies from '../../images/DataDrivenStrategies.png';
import QuickResults from '../../images/QuickResults.png';
import ROIDrivenApproach from '../../images/ROIDrivenApproach.png';
import TargetedPrecision from '../../images/TargetedPrecision.png';
import Transparency2 from '../../images/Transparency2.png';
import faqvideo from '../../images/faqvideo.mp4';
import question from '../../images/question.png';
import Faqppc from '../section-components/faqppc';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';



const Payperclick = () => {
  return (
    <>
      <NavbarV3 />
      <Helmet>
        <title>PPC Services in India | PPC Company in India</title>
        <meta name="description" content="Looking for a result-driven PPC company in India? At Whizamet, we provide the best PPC services that drive traffic, increase conversions and maximize your ROI." />
      </Helmet>
      <section className='seobner'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <div className='seocontentbg1'>
                <h1>Pay Per Click</h1>
                <p className='bnrtext'>Looking for a PPC agency to maximize your ad spend? Your search ends here.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
              </div>
            </div>
          </div>

        </div>
        <div className='left-seoshape1'></div>
        <img src={circle} className='seocircle' alt='circle' />
        <img src={circle} className='seocircle2' alt='circle' />
      </section>
      <section className='ppc-sec1'>
        <div className="container">
          <div className="row">
            {/* card1 */}
            <div className="card card-circle">
              <div className="circle-card-body">
                <h5 className="card-title">Increase business <br />visibility</h5>
                <p className="card-text">
                  Our PPC campaigns can help you reach your target audience, increase your online visibility & grow your revenue.
                </p>
              </div>
            </div>
            {/* card2 */}
            <div className="card card-circle">
              <div className="circle-card-body">
                <h5 className="card-title">Improve growth </h5>
                <p className="card-text">
                  With our PPC services, you can accelerate your business growth by driving immediate traffic to your website.
                </p>
              </div>
            </div>
            {/* card3 */}
            <div className="card card-circle ">
              <div className="circle-card-body">
                <h5 className="card-title"> Maximize conversion rates</h5>
                <p className="card-text">
                  Our PPC campaigns reach the right audience at the right time, thus converting your potential leads into conversions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='ppcserv'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <div className='Personalized'>
                <h3 class="mainsubheading">Our PPC services</h3>
                <h2>ROI-focused PPC services that make your  <br />every impression count</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">PPC audit </h3>
                <p className="description">
                  Struggling with dropped rankings or searching for ways to enhance your campaign performance? We have you covered.
                </p>
                <div className="service-icon">
                  <img src={localseo} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Keyword research</h3>
                <p className="description">
                  We leverage our expertise to get an insight into your customer’s search behavior based on search volume and competition.
                </p>
                <div className="service-icon">
                  <img src={EcommerceSEO} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Landing page creation</h3>
                <p className="description">
                  Our designers create optimized landing pages with stunning designs for effective PPC campaigns that drive leads and generate ROI.
                </p>
                <div className="service-icon">
                  <img src={guestposting} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Ad campaign creation</h3>
                <p className="description">
                  Our PPC experts create highly effective and innovative ad campaigns that will definitely get you more conversions.
                </p>
                <div className="service-icon">
                  <img src={BackLinkBuilding} alt='' className='localseo' />
                </div>
              </div>
            </div>
          </div>
          <div className="row" id='adipisicing'>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Retargeting ads</h3>
                <p className="description">
                  Through retargeting & remarketing campaigns, we engage you with past site visitors and build interactions that will lead to high click-through rates.
                </p>
                <div className="service-icon">
                  <img src={bloging} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Campaign management</h3>
                <p className="description">
                  Our dedicated team refines bids for every campaign for optimal performance, consistently monitoring your CTR and cost per click.
                </p>
                <div className="service-icon">
                  <img src={KeywordResearch} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">PPC ads on various platforms</h3>
                <p className="description">
                  From Google ads to Facebook, Instagram, Twitter, LinkedIn and Bing, we can run PPC campaigns across diverse platforms.
                </p>
                <div className="service-icon">
                  <img src={Technical} alt='' className='localseo' />
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
              <div className="serviceBox">
                <h3 className="title">Custom reporting</h3>
                <p className="description">
                  Our comprehensive reports allow you to see how we are spending your money and what we are doing with your campaign.
                </p>
                <div className="service-icon">
                  <img src={OnPage} alt='' className='localseo' />
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section className='seoresult'>
        <div className='container'>
          <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <div className='reslt'>
                <h3 class="mainsubheading">Fueling growth. Maximizing ROI</h3>
                <h2>We work endlessly to enhance the performance of<br /> your PPC campaigns</h2>
                <p class="content">At Whizamet, we don’t believe in “set it and forget approach.” Instead, we believe in adopting proactive strategies and continuous campaign improvement. Whether it's setting up your campaign from scratch or tweaking the bid strategy to get the most out of your budget, we always look for ways to exceed our client’s expectations by adding more dollars into their pocket.</p>
              </div>
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <img src={seoresults} className='seoresults' alt='' />
            </div>
          </div>
        </div>
      </section>
      <section className='pcc1'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12' id='pc-first'>
              <div class="seo2-left">
                <div class="seo2-left-inner">
                  <div>
                    <h3 class="mainsubheading">Ad types</h3>
                    <h2>Targeted PPC ad types to <br />boost your visibility</h2>
                    <p class="content">Whether it's Google, Facebook, LinkedIn, Tik Tok or any other channel, we’ll make sure that you’re found on the right platform at the right time.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12'>
              <div className='row'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>Google Ads</h4>
                    <p>Google Ads puts your business in front of people actively looking for your products & services on Google.</p>
                  </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>Facebook Ads</h4>
                    <p>Whether your audience is scrolling, searching or researching on Facebook, we ensure that they find you wherever they go.</p>
                  </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>LinkedIn ads</h4>
                    <p>With our expertly crafted LinkedIn ads, we empower you to make meaningful connections on the world’s largest professional network.</p>
                  </div>
                </div>


                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>Instagram ads </h4>
                    <p>From captivating visuals to strategic targeting, we build PPC campaigns that will help you gain more followers, likes and leads.</p>
                  </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>Shopping ads</h4>
                    <p>With eye-catching product displays, precise targeting and result-driven strategies, our shopping ads will get you qualified leads.</p>
                  </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12 mb-5'>
                  <div className='ppcboxnew'>
                    <h4>Display ads</h4>
                    <p>Through attention-grabbing images and compelling messages, our display ads are guaranteed to elevate your brand’s presence on the web.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='ppckeywords'>
        <div className='container'>
          <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <h3 class="mainsubheading">Our benefits </h3>
              <h2>Better ads.Better leads. <br />Better results</h2>
            </div>
          </div>
          <br />
          <div className="row ltn__custom-gutter">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={DedicatedTeam} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Dedicated team</h4>
                  <p>When you hire us, you’re hiring a team that will work with you to build ad campaigns to create a buzz around your brand.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={ROIDrivenApproach} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>ROI-driven approach</h4>
                  <p>With Whizamet, you’ll get comprehensive marketing strategies focused on increasing your revenue.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={QuickResults} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Quick results</h4>
                  <p>Partner with us to get quick results, high-quality leads and maximized revenue- all at affordable cost.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={CustomizedSolutions} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Customized solutions</h4>
                  <p>We understand your business, goals and target audience before creating an action plan.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ltn__custom-gutter">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={Transparency2} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Transparency</h4>
                  <p>We believe in being transparent and open. You’ll always stay updated about how your campaign is performing.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={DataDrivenStrategies} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Data-driven strategies</h4>
                  <p>Our decisions are backed by data. We use data-driven insights to create robust strategies that deliver exceptional results.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={TargetedPrecision} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Targeted precision</h4>
                  <p>Our campaigns target your audience with laser precision, ensuring that your message reaches the right audience at the right time.</p>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-6">
                <img src={ContinuousOptimization} className='ppc1' alt='' />
                <div className="ltn__feature-info">
                  <h4>Continuous optimization</h4>
                  <p>We consistently evaluate data, tweak strategies, and adapt to market trends to ensure that your campaigns are ahead of others.</p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>

      <section className='homefaqs'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <video src={faqvideo} controls
                muted
                autoPlay={"autoplay"}
                preLoad="auto"
                loop id='faqvideo'>
              </video>
              {/* <img src={question} alt='' className='question' /> */}
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <h3 className='mainsubheading'>Your doubts answered </h3>
              <h2>Frequently Asked Questions</h2>
              <Faqppc />

            </div>
          </div>
        </div>
      </section>
      <Serivcecontactform />
      <Footer_v1 />
    </>
  )
}

export default Payperclick

