import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Contactcenterfaq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Do you have the required infrastructure to handle my business processes efficiently?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes. We use cutting-edge infrastructure and technology for the best results. By outsourcing your contact center needs to Whizamet, you can save operational expenses on setting up infrastructure and hiring an in-house team. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        What benefits will I get by outsourcing my contact center needs to Whizamet?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                           <p>
                           By outsourcing your contact center requirements to us, you’ll enjoy a myriad of benefits, including:
                            </p>
                            <ul>
                                <li>Substantial cost savings </li>
                                <li>Increased profits </li>
                                <li>Streamlined operations with fewer resources</li>
                                <li>Minimized administrative hassles </li>
                                <li>Skilled talent with vast experience</li>
                                <li>Quick turnaround times</li>
                            </ul>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Do your contact center agents undergo training?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes. Our contact center agents undergo comprehensive training to ensure that they are equipped with the necessary skills to provide high-quality services for your unique requirements.  </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How secure are your contact center solutions in handling sensitive customer data?

                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We prioritize data security and adhere to industry standards and regulations by implementing encryption, secure data storage, and access control measures to protect sensitive customer information.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        Are your contact center solutions scalable?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes. You can easily add or reduce users, upgrade features, and expand capabilities to accommodate your growing needs without disrupting ongoing operations.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Contactcenterfaq