import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from '../section-components/social';
import Copyright from './copyright';
import certfication1 from "../../images/certfication1.png";
import certfication2 from "../../images/certfication2.png";
import IAF from "../../images/IAF.png";
import circle from "../../images/circle.png";

class Footer_v1 extends Component {

	componentDidMount() {
		const $ = window.$;
		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		$('.go-top').find('a').on('click', function () {

			$(".quarter-overlay").fadeIn(1);

			$(window).scrollTop(0);

			setTimeout(function () {
				$(".quarter-overlay").fadeOut(300);
			}, 800);

		});


		$(document).on('click', '.theme-btn-1 ', function () {
			$('div').removeClass('modal-backdrop');
			$('div').removeClass('show');
			$('div').removeClass('fade');
			$('body').attr("style", "");
		});
	}

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'
		let imgattr = "Footer logo"

		return (
			<footer className="ltn__footer-area  ">
				<div className="footer-top-area  section-bg-2 plr--5">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
								<div className="footer-widget footer-about-widget">
									<div className="footer-logo">
										<div className="site-logo">
											<img src={publicUrl + "assets/img/whizametlogo.webp"} alt="Logo" className='whilogo' />

										</div>
									</div>
									<div className="footer-address">
										<p><strong className='Note'>Note:</strong> <strong> Whizamet Services Pvt Ltd is A Posh Complied Company</strong></p>
										<ul>
											<li>
												<div className="footer-address-icon">
													<i className="icon-placeholder" />
												</div>
												<div className="footer-address-info">
													<p>IT-C-7, Netsmartz Center, 6th Floor, Sector 67 SAS Nagar, Punjab-160062</p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-call" />
												</div>
												<div className="footer-address-info">
													<p>For Business : <a href="tel:+91 9876769096">+91 9876769096</a></p>
													<p>For Training : <a href="tel:+91 9779444969">+91 9779444969</a></p>
												</div>
											</li>
											<li>
												<div className="footer-address-icon">
													<i className="icon-mail" />
												</div>
												<div className="footer-address-info">
													<p><a href="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
												</div>
											</li>
										</ul>
									</div>
									<div className="ltn__social-media mt-20">
										<Social />
									</div>
								</div>
							</div>
							<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Digital Marketing Services</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/search-engine-optimization">SEO</Link></li>
											<li><Link to="/guest-post-service">Guest Post Service</Link></li>
											<li><Link to="/online-reputation-management">Online Reputation Management</Link></li>
											<li><Link to="/graphic-designing">Graphic Designing</Link></li>
											<li><Link to="/email-marketing">Email Marketing</Link></li>
											<li><Link to="/social-media-marketing">Social Media Marketing</Link></li>
											<li><Link to="/seo-agencies-in-usa">SEO Agencies In USA</Link></li>
											<li><Link to="/smm-agencies-in-usa">SMM Agencies In USA</Link></li>
											<li><Link to="/digital-marketing-agencies-in-usa">Digital Marketing Agencies In USA</Link></li>
											{/* <li><Link to="/digital-marketing-agencies-in-usa">Digital Marketing Agencies In USA</Link></li> */}

										</ul>
									</div>
								</div>
							</div>
							<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Web Services</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/web-development">Web Design & Development</Link></li>
											<li><Link to="/shopify-development">Shopify Development</Link></li>
											<li><Link to="/wordpress-development">WordPress Development</Link></li>
											<li><Link to="/e-commerce-services">E-Commerce Services</Link></li>
											<li><Link to="/landing-page-design">Landing Page Design</Link></li>
											<li><Link to="/react-development">React Js Development</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
								<div className="footer-widget footer-menu-widget clearfix">
									<h4 className="footer-title">Marketing Ads</h4>
									<div className="footer-menu go-top">
										<ul>
											<li><Link to="/ppc">Pay Per Click</Link></li>
											<li><Link to="/google-ads">Google Ads</Link></li>
											<li><Link to="/google-shopping-ads">Google Shopping Ads</Link></li>
											<li><Link to="/facebook-ads">Facebook ads</Link></li>
											<li><Link to="/twitter-ads">Twitter Ads</Link></li>
											<li><Link to="/linkedin-ads">LinkedIn ads</Link></li>
										</ul>
									</div>
								</div>
							</div>

						</div>
						<div className='row text-center' id='vcrt'>
							<div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
								<ul className='certi'>
									<li><img src={certfication2} className='certfication1' alt='' /><br /><strong> RNo.: </strong> 305023052036Q</li>
									<li><img src={certfication1} className='certfication1' alt='' /><br /><strong> RNo.: </strong> 305023052033IS</li>
									<li><img src={IAF} className='IAF' alt='' /><br /><strong> IAF </strong> </li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<img src={circle} className='footer-circle' alt='' />
				<img src={circle} className='footer-circle2' alt='' />
				<Copyright />
			</footer>
		)
	}
}


export default Footer_v1