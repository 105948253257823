import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import design1 from '../../images/design1.webp';
import design2 from '../../images/design2.webp';
import design3 from '../../images/design3.webp';
import design4 from '../../images/design4.webp';
import design5 from '../../images/design5.webp';
import design6 from '../../images/design6.webp';
import design7 from '../../images/design7.webp';
import design8 from '../../images/design8.webp';
import design9 from '../../images/design9.webp';
import design10 from '../../images/design10.webp';

class   WebSlider extends React.Component {
 
  
  render() {
   
    
   
    return (
<section className='webslick'>
  <div className='container-fluid'>
      <div className="wrapper1">
      <div className="center-slider">
        <div><a href="https://theprofise.com/"><img src={design1} alt="" className="design1" /></a></div>
        <div><a href="http://bluebellinn.ca/"><img src={design2} alt="" className="design2" /></a></div>
        <div><a href="https://americansmarttrucking.com/"><img src={design3} alt="" className="design3" /></a></div>
        <div><a href="https://dreamdynamicserp.com/"><img src={design4} alt="" className="design4" /></a></div>
        <div><a href="#"><img src={design5} alt="" className="design5" /></a></div>
        <div><a href="https://mackenzie-boutique.com/"><img src={design6} alt="" className="design6" /></a></div>
        <div><a href="https://doublediamondcleaners.com/"><img src={design7} alt="" className="design7" /></a></div>
        <div><a href="#"><img src={design8} alt="" className="design8" /></a></div>
        <div><a href="https://confidolandbase.com/"><img src={design9} alt="" className="design9" /></a></div>
        <div><a href="https://mohitsaxena.in/"><img src={design10} alt="" className="design10" /></a></div>
      </div>
    </div>
    </div>
    </section>
    );
  }
}



export default WebSlider;
