import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import  reactoffer from '../../images/reactoffer.png';
import r1 from '../../images/r1.png';
import r2 from '../../images/r2.png';
import r3 from '../../images/r3.png';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import Reactools from '../section-components/reactools';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';

const Reactdevelopment = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>React JS Development Services | React JS Development Company</title>
<meta name="description" content="Whizamet is a notable React JS development company providing full-fledged React JS development services to clients across the globe. Contact us to discuss your needs." />
</Helmet>

<section className='ecomm-web'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">React JS</h3>
     <h2>React JS Development <br />Services</h2>
          </div>
         </div>
        </div>
       <div className='row'>
   <Reactools />
       </div>
    </div>
</section>
<section className='reactbtmbnr'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <h2>Building highly scalable React JS websites </h2>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <p class="content">At Whizamet, our passion for ReactJS development drives us to create dynamic, interactive, and scalable websites. We leverage the power of this innovative open-source technology to craft cutting-edge progressive websites that level up your business game. </p>
        </div>   
    </div>
    <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={r1} className='r1' alt='' />
            </span>
            <h4>React JS development</h4>
            <p>We work closely with you to understand your requirements and create interactive UI designs and engaging websites with the latest features.</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={r2} className='r1' alt='' />
            </span>
            <h4> React JS migration </h4>
            <p>We provide comprehensive React JS migration services, ensuring secure upgradation of your existing websites to a robust React JS framework. </p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={r3} className='r1' alt='' />
            </span>
            <h4>React JS integration </h4>
            <p>From integrating React JS components to improving your website performance through advanced techniques, we cover every aspect of the integration.</p>
           </div>  
            </div>
        </div>
</div>
</section>
   <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='ReactJS'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12' id='algorithms'>
            <h2>Why makes us your trusted partner </h2>
            <p class="content">Being one of the best React JS web development companies, we collaborate with a spectrum of businesses, from budding startups to big enterprises and empower them to widen their business horizons through groundbreaking technologies. Choose us as your website development partner to watch your business soar in the digital world.</p>
        <ul className='Visitors'>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We create agile strategies for stress-free and timely delivery. </li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We specialize in delivering flawless user experiences. </li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We guarantee you'll never hit a tech roadblock with Whizamet. Our dedicated support will fix your issues in the blink of an eye. 
</li>
                           </ul>
            </div>
       
       
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <img src={reactoffer} className='reactoffer' alt='ecommbnr' /> 
        </div>
        </div>
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='react-step'>
    <div className='container'>
    <div className='row' >
    <h3 class="mainsubheading">Process</h3>
     <h2>Our fine tuned approach to creating a <br />smooth React JS website</h2>
    </div>
    <div className='row'>
    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
  <div className="reactprocess">
    <div
      className="item item-1 animate wow fadeInRight"
      data-wow-delay="0.1s"
      style={{
        visibility: "visible",
        animationDelay: "0.1s",
        animationName: "fadeInRight"
      }}
    >
      <div className="line" />
      <div className="circle-wrap">
        <div className="mask">
          <div className="wave-pulse wave-pulse-1" />
          <div className="wave-pulse wave-pulse-2" />
          <div className="wave-pulse wave-pulse-3" />
        </div>
        <h6 className="circle">1</h6>
      </div>
      <div className="content-wrap">
        <h3>Ideation </h3>
        <div className="process-text">
        It all begins with understanding your goals, target audience and unique business identity. 
        </div>
      </div>
    </div>
    <div
      className="item item-1 animate wow fadeInRight"
      data-wow-delay="0.15s"
      style={{
        visibility: "visible",
        animationDelay: "0.15s",
        animationName: "fadeInRight"
      }}
    >
      <div className="line" />
      <div className="circle-wrap">
        <div className="mask">
          <div className="wave-pulse wave-pulse-1" />
          <div className="wave-pulse wave-pulse-2" />
          <div className="wave-pulse wave-pulse-3" />
        </div>
        <h6 className="circle">2</h6>
      </div>
      <div className="content-wrap">
        <h3>Development </h3>
        <div className="process-text">
        Our expert developers transform designs into reality by harnessing the power of React JS. 
        </div>
      </div>
    </div>
    <div
      className="item item-1 animate wow fadeInRight"
      data-wow-delay="0.20s"
      style={{
        visibility: "visible",
        animationDelay: "0.2s",
        animationName: "fadeInRight"
      }}
    >
      <div className="line" />
      <div className="circle-wrap">
        <div className="mask">
          <div className="wave-pulse wave-pulse-1" />
          <div className="wave-pulse wave-pulse-2" />
          <div className="wave-pulse wave-pulse-3" />
        </div>
        <h6 className="circle">3</h6>
      </div>
      <div className="content-wrap">
        <h3>Optimization </h3>
        <div className="process-text">
        We optimize your website to ensure that it loads in minimum time and provides a delightful user experience. 
        </div>
      </div>
    </div>
    <div
      className="item item-1 animate wow fadeInRight"
      data-wow-delay="0.25s"
      style={{
        visibility: "visible",
        animationDelay: "0.25s",
        animationName: "fadeInRight"
      }}
    >
      <div className="line" />
      <div className="circle-wrap">
        <div className="mask">
          <div className="wave-pulse wave-pulse-1" />
          <div className="wave-pulse wave-pulse-2" />
          <div className="wave-pulse wave-pulse-3" />
        </div>
        <h6 className="circle">4</h6>
      </div>
      <div className="content-wrap">
        <h3>Launch </h3>
        <div className="process-text">
        Your website is finally launched and ready to engage & captivate your audience with its smooth user experience. 
        </div>
      </div>
    </div>
  </div>
</div>
</div>

    </div>
</section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Reactdevelopment

