import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import csimg1 from '../../images/csimg1.jpg';
import csimg2 from '../../images/csimg2.jpg';
import csimg3 from '../../images/csimg3.jpg';
import Casestudiesgrid from '../blog-components/case-studies';




const Casestudies = () => {
    return (
<>
<NavbarV3 />
<section className='casesty'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
            <div className='casesty-content'>
            <h3 class="Expert animated fadeIn">Homestays</h3>
            <h1>Case Studies</h1>
                <p className='bnrtext'>With 10+ years of working as a preferred gained an envious reputation Website Design and Development company for brands.</p>
                <div class="bnrbtn pt-btn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 ellipse'>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 upimg'>
                <div className='container c1'>
                  <div className='row'>
                         <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12 nopad'>                           
                          </div>
                         <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-6 nopad'>
                              <img src={csimg1} className='caseim1' alt='' />
                          </div>
                          <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-6 nopad'>
                              <img src={csimg2} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12 nopad'>                           
                          </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='container c2'>
                  <div className='row'>
                        <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12 nopad'>                           
                          </div>
                         <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-3 nopad'>
                              <img src={csimg1} className='caseim1' alt='' />
                          </div>
                          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-5 nopad'>
                              <img src={csimg1} className='caseimno' alt='' />
                          </div>
                          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-3 nopad'>
                              <img src={csimg3} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12 nopad'>                           
                          </div>
                  </div>
                </div>
              </div>
               
            </div>  
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>



<Casestudiesgrid />

<Footer_v1 />
</>
)
}

export default Casestudies

