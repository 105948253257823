import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NavbarV3 extends Component {

    render() {
        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
		<div>
           <header className="ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent gradient-color-4---">
			  <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-black">
			    <div className="container">
			      <div className="row">
				
		   {/* LOGO */}
			        <div className="col">
			          <div className="site-logo-wrap">
			            <div className="site-logo go-top">
			              <Link to="/"><img src={publicUrl+"assets/img/whizametlogo.webp"} alt="Logo"  className='Whizametlogo'/></Link>
			            </div>
			          </div>
			        </div>

            {/* DESKTOP MENU */}
			        <div className="col header-menu-column menu-color-white justify-content">
			          <div className="header-menu d-none d-xl-block go-top">
			            <nav>
			              <div className="ltn__main-menu">
			                <ul>
							  <li><Link to="/">Home</Link></li>			
							  <li className="menu-icon"><Link to="/about">About Us</Link>
							  <ul className="sub-menu">
								<li><Link to="/our-portfolio">Our Portfolio</Link> </li>
								<li><Link to="/career">Career</Link></li>
			                    </ul>
			                  </li>
							  <li class="menu-icon"><a href="/">Solutions</a>
								<ul class="mega-menu">
									<li><a href="#">Digital Marketing</a>
										<ul>
										<li><Link to="/search-engine-optimization">SEO</Link></li>
										<li><Link to="/online-reputation-management">Online Reputation Management</Link></li>
										<li><Link to="/graphic-designing">Graphic Designing</Link></li>
										<li><Link to="/email-marketing">Email Marketing</Link></li>
										<li><Link to="/digital-marketing">Digital Marketing</Link></li>
										<li><Link to="/social-media-marketing">Social Media Marketing</Link></li>
										<li><Link to="/whiteboard-animation-video-services">Whiteboard Animation Video Services</Link></li>
										<li><Link to="/wikipedia-page-creation">Wikipedia Page Creation</Link></li>
										<li><Link to="/guest-post-service">Guest Post Service</Link></li>							
										</ul>
									</li>
									<li><Link to="#">Web Development</Link>
										<ul>
										<li><Link to="/web-development">Web Designing & Development</Link></li>
										<li><Link to="/shopify-development">Shopify Development</Link></li>
										<li><Link to="/wordpress-development">WordPress Development</Link></li>
										<li><Link to="/e-commerce-services">E-Commerce Services</Link></li>
										<li><Link to="/landing-page-design">Landing Page Design</Link></li>
										<li><Link to="/react-development">React JS Development</Link></li>
										</ul>
									</li>
									<li><Link to="#">Marketing Ads</Link>
									    <ul>
									    <li><Link to="/ppc">Pay Per Click</Link></li>
									    <li><Link to="/google-ads">Google Ads</Link></li>
										<li><Link to="/google-shopping-ads">Google Shopping Ads</Link></li>
										<li><Link to="/facebook-ads">Facebook Ads</Link></li>
										<li><Link to="/twitter-ads">Twitter Ads</Link></li>
										<li><Link to="/linkedin-ads">LinkedIn Ads</Link></li>
										<li><Link to="/instagram-influencer-marketing">Instagram Influencer Marketing</Link></li>
										<li><Link to="/b2b">B2B Services</Link></li>	
										<li><Link to="/lead-generation">Lead Generation</Link></li>			
									</ul>
									</li>
									<li><Link to="#">BPO/KPO Solution</Link>
										<ul>
										<li><Link to="/bpo-solutions">BPO Solutions</Link></li>	
										<li><Link to="/dispatch">Dispatch Services</Link></li>	
										<li><Link to="/freight-brokerage">Freight Brokerage</Link></li>
										<li><Link to="/contact-center-solutions">Contact Center Solution</Link></li>
										</ul>
									</li>
								</ul>
								</li>
								
						{/* <li className="menu-icon"><Link to="#">Case Studies</Link>
								<ul className="sub-menu">
						<li><Link to="/dispatchcasestudy">Dispatch Casestudy</Link></li>
						<li><Link to="/ormcasestudy">ORM Casestudy</Link></li>
						<li><Link to="/homestaycasestudy">Homestay Casestudy</Link></li>
						</ul>
						</li> */}
							<li><a href="https://whizametmedia.com/">Training</a></li>
							<li><a href="/contact-us">Contact Us</a></li>
							<li><a href="/blog">Blog</a></li>	               	
			             	 {/* <li className="special-link">
							<a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Call Us Now</a>
			            </li> */}
						{/* <div className="mini-cart-icon mini-cart-icon-2">
								<a href="#ltn__utilize-cart-menu" className="ltn__utilize-toggle">
								<span className="mini-cart-icon">
									<i class="icon-phone-call" /> 
								</span>
								<h6><span>Call us at</span> <span className="ltn__secondary-color">+91 9876769096</span></h6>
								</a>
							</div> */}

                        {/* <div className="get-support clearfix">
			              <div className="get-support-icon">
			                <i className="icon-call" />
			              </div>
			              <div className="get-support-info">
			                <h6>Get Support</h6>
			                <h4><a href="tel:+123456789">123-456-789-10</a></h4>
			              </div>
			            </div> */}
			    </ul>

			              </div>
			            </nav>
				
			          </div>
			        </div>

            {/* CALL NOW BUTTON */}
					<div className="col">
						<div className="ltn__header-options">
						    <ul>
							    <li className="special-link1">
							       <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Call Now</a>
			                    </li>
						   </ul>
						</div>
					</div>

            {/* MOBILE MENU BUTTON */}
			        <div className="col--- ltn__header-options ltn__header-options-2 ">
			          <div className="mobile-menu-toggle d-xl-none">
			            <a href="#ltn__utilize-mobile-menu" className="ltn__utilize-toggle">
			              <svg viewBox="0 0 800 600">
			                <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top" />
			                <path d="M300,320 L540,320" id="middle" />
			                <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) " />
			              </svg>
			            </a>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</header>

			{/* MOBILE MENU */}
			<div id="ltn__utilize-mobile-menu" className="ltn__utilize ltn__utilize-mobile-menu">
				<div className="ltn__utilize-menu-inner ltn__scrollbar">
					<div className="ltn__utilize-menu-head">
					    <div className="site-logo">
						    <Link to="/"><img src={publicUrl+"assets/img/Whizametlogo.webp"} alt="Logo"  className='Whizametlogo'/></Link>
					    </div>
					    <button className="ltn__utilize-close">×</button>
					</div>
				<div className="ltn__utilize-menu">
					<ul>
						<li><a href="/">Home</a></li>
						<li><Link to="/about">About Us</Link></li>
						<li><Link to="#">Digital Marketing</Link>
						<ul className="sub-menu">
						<li><Link to="/seo">SEO</Link></li>
						<li><Link to="/online-reputation-management">Online Reputation Management</Link></li>
						<li><Link to="/graphic-designing">Graphic Designing</Link></li>
						<li><Link to="/email-marketing">Email Marketing</Link></li>
						<li><Link to="/digital-marketing">Digital Marketing</Link></li>
						<li><Link to="/social-media-marketing">Social Media Marketing</Link></li>
						<li><Link to="/whiteboard-animation-video-services">Whiteboard Animation Video</Link></li>
						<li><Link to="/wikipedia-page-creation">Wikipedia Page Creation</Link></li>
						<li><Link to="/guest-post-service">Guest Post Service</Link></li>	
						</ul>
						</li>
						<li><Link to="#">Web Development</Link>
						<ul className="sub-menu">
						<li><Link to="/shopify-development">Shopify Development</Link></li>
						<li><Link to="/wordpress-development">WordPress Development</Link></li>
						<li><Link to="/e-commerce-services">E-Commerce Services</Link></li>
						<li><Link to="/web-design-and-development">Web Designing & Development</Link></li>
						<li><Link to="/landing-page-design">Landing Page Design</Link></li>
						<li><Link to="/react-development">React JS Development</Link></li>
						</ul>
						</li>
						<li><Link to="#">Pay Per Click</Link>
						<ul className="sub-menu">
						<li><Link to="/ppc">Pay Per Click</Link></li>
						<li><Link to="/google-ads">Google Ads</Link></li>
						<li><Link to="/google-shopping-ads">Google Shopping Ads</Link></li>
						<li><Link to="/facebook-ads">Facebook Ads</Link></li>
						<li><Link to="/twitter-ads">Twitter Ads</Link></li>
						<li><Link to="/linkedin-ads">LinkedIn Ads</Link></li>
						<li><Link to="/instagram-influencer-marketing">Instagram Influencer Marketing</Link></li>
						<li><Link to="/b2b">B2B Services</Link></li>	
						<li><Link to="/lead-generation">Lead Generation</Link></li>		
						</ul>
						</li>
						<li><Link to="#">BPO/KPO Solution</Link>
						<ul className="sub-menu">
						<li><Link to="/bpo-solutions">BPO Solutions</Link></li>	
						<li><Link to="/dispatch">Dispatch Services</Link></li>	
						<li><Link to="/freight-brokerage">Freight Brokerage</Link></li>
						<li><Link to="/contact-center-solutions">Contact Center Solution</Link></li>								
						</ul>
						</li>
						<li><Link to="#">Case Studies</Link>
						<ul className="sub-menu">
						<li><Link to="/dispatch-casestudy">Dispatch Casestudy</Link></li>
						<li><Link to="/orm-casestudy">ORM Casestudy</Link></li>
						<li><Link to="/homestay-casestudy">Homestay Casestudy</Link></li>
						</ul>
						</li>
						<li><Link to="/blog">Blog</Link></li>				
						<li><Link to="/our-portfolio">Our Portfolio</Link> </li>
						<li><Link to="/career">Career</Link></li>
						<li><Link to="/contact-us">Contact</Link></li>
					</ul>
				</div>
				
				</div>
			</div>


		</div>
        )
    }
}


export default NavbarV3