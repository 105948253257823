import React, { Component } from 'react';


class Faqemail extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        What makes your email marketing services unique?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our email marketing services stand out for their personalized approach, creative design, and data-driven strategies. We focus on understanding your brand’s unique identity and audience to ensure that our email marketing campaigns match your customers' needs and interests and drive tangible results for your business. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How do you ensure my email campaigns stand out in crowded inboxes?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
  
                            <p>We focus on creating visually appealing and engaging email campaigns that capture attention and drive instant action. From custom-designed templates to engaging content and strategic timing, we leverage a wide array of tactics to ensure that your emails stand out in crowded inboxes.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        What kind of results can I expect from your email marketing services?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our email marketing services can help you achieve measurable results, including increased open rates, click-through rates, conversion rates, and maximum ROI. We track and analyze campaign performance consistently to optimize and improve results.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        Can you integrate email marketing with other digital marketing channels for better results?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes, we can integrate your email marketing with other digital marketing services to help you achieve exceptional results. Whether it’s integrating with social media, content marketing, or PPC advertising, we create combined multi-channel campaigns that maximize reach and effectiveness.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How can I get started with your email marketing services?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Getting started with us is easy. Reach out to us via call or email, and we will schedule a consultation to understand your business requirements.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqemail