import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import { Helmet } from 'react-helmet';

const Privacypolicy = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Privacy Policy - Whizamet</title>
</Helmet>
<section className='privacy'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Privacy Policy</h1>
           </div>
            </div>
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='privacycontent'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='pricontent'>
                  <h2>Privacy policy</h2>
                    <p>At Whizamet, we are committed to safeguarding the privacy of our clients and website visitors. This Privacy Policy outlines how we collect, use, disclose, and protect the personal information you provide to us. By using our services or accessing our website, you consent to the privacy practices described in this policy. </p>
                  <h3>Information we collect</h3>
                  <p><strong>We may collect personal information such as your name, email address, phone number, and other details required to provide our services.</strong></p>
                  <h3>How we use your information:</h3>
                  <p><strong>We use the collected information for the following purposes:</strong></p>
                  <ul>
                    <li>To provide you with the services requested by you.</li>
                    <li>To communicate with you regarding our services, updates, promotions etc.</li>
                    <li>To tailor our offerings and content to match your interests.</li>
                    <li>To improve our website and services based on the feedback.</li>
                    <li>To answer your queries and resolve issues.</li>
                  </ul>
                  <h3>Data security</h3>
                  <p><strong>Security of your data is our utmost priority. We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, any transmission of data on the Internet is not entirely secure, therefore we cannot guarantee 100% security of data transferred through the Internet.</strong></p>
                     <h3>Cookies and tracking technology</h3>
                     <p>Our website may use cookies and other tracking technology to enhance user experience and analyze website traffic. You can modify your browser settings to disable cookies, but you may not be able to enjoy the full functionalities of our website.</p>
                     <h3>Third-party disclosure</h3>
                     <p>We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except for trusted partners who assist us in providing services.</p>
                     <h3>Your rights</h3>
                     <p> You have the right to access, correct, or delete your personal information at any time. If you wish to exercise these rights, contact us at <a href="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
                     <h3>Changes to the privacy policy</h3>
                     <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. You’re advised to visit the page regularly to stay updated with the changes in our privacy practices.</p> 
                     <h3>Contact us</h3>
                     <p>If you have any questions or queries about our privacy policy, feel free to contact us via call or email us at <a href="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<Footer_v1 />
</>
)
}

export default Privacypolicy

