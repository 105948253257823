import React, { useRef, useState } from "react";
import PS1 from  "../../images/PS1.png";
import PS2 from "../../images/PS2.png";
import PS3 from "../../images/PS3.png";
import PS4 from "../../images/PS4.png";

import graphicslider1 from  "../../images/graphicslider1.png";
import graphicslider2 from "../../images/graphicslider2.png";
import graphicslider3 from "../../images/graphicslider3.png";
import graphicslider4 from "../../images/graphicslider4.png";
import graphicslider5 from "../../images/graphicslider5.png";
import graphicslider6 from "../../images/graphicslider6.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Graphicslider = () => {
  return (
    <>
      <div className="Graphictools">
            <div className="container-fluid">
            <div className="Graphictools-wrapper align-items-center">
         <div className="Graphictools-slider">
          <div className="swiper-Industriesserve">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 3000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         700: {
                       slidesPerView: 3,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 0,
                          },
                          1024: {
                    slidesPerView: 3,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1440: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={graphicslider1} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider2} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider3} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider4} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider5} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider6} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={graphicslider1} alt="" className="PS1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={graphicslider2} alt="" className="PS1" />
          </SwiperSlide>
         
         
                                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Graphicslider;