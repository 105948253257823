import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import caseimg3 from '../../images/caseimg3.jpg';
import csorm1 from '../../images/csorm1.webp';
import csorm2 from '../../images/csorm2.webp';
import csorm3 from '../../images/csorm3.webp';
import csorm4 from '../../images/csorm4.webp';
import csorm5 from '../../images/csorm5.png';
import csorm6 from '../../images/csorm6.png';
import Page_header from '../global-components/page-header';
import { Helmet } from 'react-helmet';


const Seocasestudy = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>ORM Casestudy - Whizamet</title>
<meta name="description" content="" />
</Helmet>
<Page_header headertitle="ORM Casestudy" />
{/* <section className='casesty'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
            <div className='casesty-content'>
            <h3 class="Expert animated fadeIn">Homestays</h3>
            <h1>Homestay Case Study</h1>
                <p className='bnrtext'>With 10+ years of working as a preferred Website Design and <br />Development company for brands.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 ellipse'>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 upimg'>
                <div className='container c1'>
                  <div className='row'>
                         <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg2} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='container c2'>
                  <div className='row'>
                        <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg3} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
               
            </div>  
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section> */}



<section className='csesty'>
 <div className='container'>
    <div className='row'>
        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 caseleft'>
          <h4 className='back'> About the client </h4>
            <p className='backpara'>Jole Figliomeni, the CEO and the founder of The Figliomeni group, a women-owned business consultancy company in Italy, and Salvatore Virzi, a commercialista in Italy who helps individuals overcome financial problems and build a better financial future, sought our ORM services to remove negative online content from search engines.</p>
          <h4 className='back'>The objective</h4>
            <p className='backpara'>These two successful entrepreneurs from Italy intended to remove negative content and inaccurate information from search results that adversely affected their professional image and credibility. Unfavourable search results were significantly damaging their professional reputation and business growth. They wanted to establish a positive online presence and professional reputation that reflected their achievements and business expertise.  Additionally, they wanted to boost their visibility on search engines to attract potential clients for their business. </p>
            <h4 className='back'>The challenge </h4>
            <p className='backpara'>The biggest challenge was the negative online content, including blogs and articles that adversely affected the credibility and reputation of our clients. </p>
            <p className='backpara'>Second, understanding legal considerations associated with content removal and suppression was also significant. </p>
            <p className='backpara'>Third, rebuilding trust for our clients, whose personal and professional reputations were tarnished by negative content required a thoughtful narrative.</p>
            <h4 className='back'>The strategy</h4>
            <p className='backpara'>Our ORM strategy focused on removing misleading and negative information and highlighting their expertise and achievements. To establish clients’ trust and credibility in the face of adverse online content, we developed a multifaceted targeted approach combining strategic content optimization and effective SEO strategies.</p>
<div class="cont">
<div class="rightbox">
  <div class="rb-container">
    <ul class="rb">
      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Content optimization 
        </div>
        <div class="item-title">We created and optimized professional profiles for both clients on business directories, LinkedIn and other platforms. Furthermore, we created engaging SEO-optimized content, including blogs, articles and press releases, highlighting our clients’ expertise and achievements. This helped us to overshadow negative content. </div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Negative content suppression and removal 
        </div>
        <div class="item-title">We researched and identified negative and inaccurate information, including articles, posts, etc, through appropriate channels and employed advanced ORM strategies to remove unfavourable content. This included partnering with online platforms, legal channels, and contacting publishers to remove or update inaccurate information. Also, we deployed highly effective SEO strategies to suppress negative content and consistently monitored search results to ensure a positive reputation.</div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Search engine optimization
        </div>
        <div class="item-title">Our SEO experts deployed effective on-page SEO strategies to improve the visibility of positive content in search results.</div>
      </li>

    </ul>

  </div>
</div>
</div>
  
            <h4 className='back'>The results</h4>
            <p className='backpara'>Our ORM and SEO strategies significantly helped us boost online visibility for our clients. We successfully removed all negative links and replaced them with positive content, thus helping our clients attract increased business opportunities. Through negative content removal and promotion of positive narratives, we successfully build trust and credibility for our clients. Furthermore, with consistent monitoring, we ensured long-term protection from reputational threats and damages. </p>
            <img src={csorm1} alt='' className='cs' />
            <img src={csorm2} alt='' className='cs' />
            <img src={csorm3} alt='' className='cs' />
            <img src={csorm4} alt='' className='cs' />
            <img src={csorm5} alt='' className='cs' />
            <img src={csorm6} alt='' className='cs' />
            </div>

        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center'>
           <img src={caseimg3} alt='' className='caseimg' />
        </div>
    </div>
  </div>
</section>


<Footer_v1 />
</>
)
}

export default Seocasestudy

