import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CeoVideo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'
        let imagealt = 'image'

    return <>
	
	<div className="ltn__video-popup-area ltn__video-popup-margin---">
			  <div className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image ltn__animation-pulse1" data-bs-bg={publicUrl+"assets/img/bg/ceo.webp"}>
			    <a className="ltn__video-icon-2 ltn__video-icon-2-border---" href="https://www.youtube.com/embed/HVtbUjwWy6c?si=iU72zCm9B_grmnOj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay;=1&showinfo=0" data-rel="lightcase:myCollection">
			      <i className="fa fa-play" />
			    </a>
			  </div>
			</div>
			</>
        }
}

export default CeoVideo