import React, { Component } from 'react';


class Freightfaq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Why should I choose a freight broker instead of a trucking company? 
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Finding a cost-efficient solution to ship your goods can pose a significant challenge for shippers. Excelling in diverse lanes, some trucking companies are efficient in managing full loads, while others excel in LTL. At Whizamet, we specialize in identifying the most suitable carrier for our customer’s unique needs.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How does Whizamet offer better pricing as compared to other freight brokers?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                           
                            <p>When you request us for a personalized quote, we get in touch with several carriers from our network to get you the most competitive rate, including expedited shipping. With just a single call, you’ll get the best shipping quote tailored to your requirements.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Does Whizamet offer after-hour support? 
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Absolutely. At Whizamet, we know the significance of being available round the clock for our carriers and shippers and resolving issues that arise. We provide customized services according to your needs, including 24/7 support. Our dedicated team is available to assist you at any hour, ensuring a seamless and hassle-free shipping experience.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        Do you provide real-time shipment tracking?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Yes. We leverage cutting-edge tracking technology and consistently communicate with our carrier partners and drivers. This helps us to provide you with accurate details about the location of your shipment and ensure transparency throughout the shipping process. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        Why should I choose Whizamet for freight brokerage services?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>With an extensive experience of more than 7 years, we’ve developed a robust network of reliable carriers to meet our clients' shipping requirements. Our dedicated team carefully evaluates each shipment, which helps us deliver the most efficient and customized solutions to our clients. Our streamlined services reduce your costs and improve efficiency. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Freightfaq