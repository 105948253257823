import React from 'react';
import Footer_v1 from '../../global-components/footer';
import NavbarV3 from '../../global-components/navbar-v3';
import circle from '../../../images/circle.png';
import { Helmet } from 'react-helmet';
import digitalpick from "../../../images/seoantonia/concept_0808-[Converted].png";
import CeoVideo from '../../section-components/ceo-video';
import credigi1 from "../../../images/creative-digital/cre-digi-1.png";
import credigi2 from "../../../images/creative-digital/cre-digi-2.png";
import credigi3 from "../../../images/creative-digital/cre-digi-3.png";
import credigi4 from "../../../images/creative-digital/cre-digi-4.png";
import credigi5 from "../../../images/creative-digital/cre-digi-5.png";
import Bgflyinglogos from '../../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../../section-components/bgflyinglogos2';
import Industriesserve from '../../section-components/clients/client1';
import ExceptionalDesigns from '../../../images/ExceptionalDesigns.png';
import innovative from '../../../images/innovative.png';
import QuickTurnaround from '../../../images/QuickTurnaround.png';
import SimplifiedGraphics from '../../../images/SimplifiedGraphics.png';
import UnlimitedRevisions from '../../../images/UnlimitedRevisions.png';
import ImpactfulVisuals from '../../../images/ImpactfulVisuals.png';
import AffordableServices from '../../../images/AffordableServices.png';
import BespokeServices from '../../../images/BespokeServices.png';
import StickySidebar from '../../Location-components/location-services';
import faqvideo from '../../../images/faqvideo.mp4';
import Faqdigital from '../../section-components/faqdigital';
import Serivcecontactform from '../../section-components/serivcecontactform';
import seopick from '../../../images/seoantonia/29314731_2204_w039_n003_212b_p1_212-[Converted].png';
import pic from '../../../images/seoantonia/29768937_10y_6cq20b90adimu831dpgmoubjd5pg-[Converted].png';
import Faqseocity from '../../section-components/faqdigitalseocity';

const SEOagencyinlansing = () => {
    return (
        <>
            <NavbarV3 />
            <Helmet>
                <title>"Top Rated SEO Company in Lansing | Whizamet SEO Services
                " </title>
                <meta name="description" content="Whizamet is the best SEO company in Lansing, helping a diverse range of businesses increase their Google search rankings and targeted traffic. Contact us now.
" />
            </Helmet>

            <section className='california'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <div className='bnr-cont'>
                                <h3 class="mainsubheading ">SEO solutions</h3>
                                <h1>SEO Agency In Lansing</h1>
                                <p className='bnrtext'>Boost your website traffic, attract organic traffic and increase your leads with the best SEO company in Lansing </p>
                                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>

                            </div>

                        </div>
                        <div className='col-xxl-6 col-xl-6 col-md-6 col-12 im'>
                            <img src={digitalpick} className='shopi221' alt='' />
                        </div>
                    </div>

                </div>
                <div className='left-seoshape1'></div>
                <img src={circle} className='seocircle' alt='circle' />
                <img src={circle} className='seocircle2' alt='circle' />
            </section>
     {/* <StickySidebar /> */}
      
            <section className='callww'>
                <div className='container'>
                    <div className='row text-center'>
                        <div className='col-xxl-12'>
                        <h2>What we do </h2>
                            <p className='yro'>We help you elevate your online presence and rank higher in SERPs.</p>
                        </div>
                    </div>
                    <div className='row' id='stunning'>

                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Keyword research  </h3>
                                    <p> We conduct extensive research to identify the most relevant and high-impact keywords in your domain to improve your content and website visibility on search engines. </p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Link Building</h3>
                                    <p>We obtain authoritative high-quality backlinks from trustworthy and reputable websites in your niche to enhance your site’s credibility and generate targeted traffic.  </p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi3} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Technical SEO  </h3>
                                    <p>We perform a comprehensive technical audit to identify and resolve technical issues in your website that impact its functionality and visibility on search engines. 

</p>
                                </div>
                            </div>

                        </div>

                   
               
                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Local SEO </h3>
                                    <p>As one of the best SEO agencies in Lansing
, we employ personalized SEO strategies, including strategic keyword research, profile optimization and citation management to help you dominate local search results.  </p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>On page SEO  </h3>
                                    <p>From keyword optimization to responsive web design, user-focused content and URL optimization, our on-page SEO services enhance the technical aspects of your website to drive increased traffic. 
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
                            <div className='digi-single-serivce k1'>
                                <span><img src={credigi5} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Off-page SEO </h3>
                                    <p>We employ a range of strategies, including link building, guest posting, maintaining an active social media presence, managing online reputation and optimizing local SEO presence to establish your website’s authority. </p>
                                </div>
                            </div>

                        </div>
                        </div>
                    </div>
          
            </section>
                    <section>
                    <div className='container'>
                    <div className='row' id='stunning'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
                        <div className='row'>
                        <h2>Search engine optimization</h2>
                        <p>Worried about your business getting lost amidst the bustling online landscape? Fret not. Our <a className='g1' href='https://whizamet.com/search-engine-optimization'> SEO services</a> in Lansing
 can help your business stay ahead of the competition and maximize revenue. We are the best SEO company in Lansing
 that can help you increase online visibility, increase organic traffic, drive high-quality leads and boost conversions. Our team of SEO experts conducts extensive research, leverages advanced tools, and stays ahead of Google algorithms to ensure a strong online presence for your business. </p>
                    </div> </div>
                 <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <div className='row'>
                           <img src={seopick} className='seo' alt=''/>       
                           </div>   </div>
                           <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <p className='b'>With search engines playing a critical role as a primary gatekeeper of online visibility, we use strategic SEO strategies and optimize various aspects of your website, such as content, keywords, URL, meta tags, and site structure, to ensure your website aligns with search engine algorithms. With our SEO company in Lansing
, you’re sure to experience:</p>
                            <ul>
                                <li>Increase in keyword rankings</li>
                                <li>Improvement in click-through-rates</li>
                                <li>More organic traffic </li>
                                <li>Amplified conversions </li>
                               <div class="bnrbtn bt1"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Get A Quote</a></div>
                            </ul>
                            </div>                 
                           </div>
          </div>
            </section>

  <section>
                <div className='container'>
                    <div className='row' id='stunning'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
                        <div className='row'>
                        <h2> Online reputation management </h2>
                        <p>With a multitude of competitors out there, maintaining a positive reputation for your business is significant. We are the best <a className='g1' href='https://whizamet.com/online-reputation-management'> ORM company </a> in Lansing
 that can help you identify, suppress and eliminate negative online content against your name, brand /company or IP. With our wide array of online reputation management services and personalized strategies, we create a positive buzz around your brand, establishing its credibility and positive reputation. Our Online reputation management services include: </p>
                    </div> </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <div className='row'>
                        <ul className='o1'>
                            <li className='f1'>Brand audit:<p className='b'>Our brand audit services help us assess your brand's performance in the marketplace and against your key competitors, allowing us to align your marketing strategies with your target audience. 
                            </p></li>
                            <li className='f1'>Reputation monitoring:<p className='b'> Our ORM experts keep watch over your online presence, including online mentions, reviews, and social media conversations, to proactively manage reputation issues. 
                            </p></li>
                            <li className='f1'>Brand enhancement:<p className='b'>By developing and executing highly effective strategies that showcase your strengths, achievements and positive customer experiences, we strive to create a positive buzz around your brand and its products and services. 
                            </p></li>
                        </ul>
</div>  </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
             <ul className='o1'>
                <li className='f1'>Review management:<p className='b'>We regularly monitor customer reviews, address concerns, resolve queries and encourage satisfied customers to leave positive reviews to improve your online reputation.
                            </p></li>
                            <li className='f1'>Social media management:<p className='b'>We optimize your profiles and handle all aspects of your brand’s presence on social media to cast your brand in a favorable light. 
                            </p></li>
                            <li className='f1'>Crisis management:<p className='b'> We act quickly to mitigate unforeseen situations that could harm your online reputation and address issues head-on to restore your brand’s positive reputation. 
                            </p></li>
                            </ul>
            </div>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
            <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Start Branding </a></div>   
            </div>
   </div>
                </div>
            </section>
            <section>
             <div className='container'>
                <div className='row' id='stunning'>
                <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
                        <div className='row'>
                        <h2>  Email marketing  </h2>
                        <p><a className='g1' href='https://whizamet.com/email-marketing'>Our email marketing services</a> are designed to connect you with your customers and spread the right word about your product or services to your audience at the right time. Our informational email enables your target audience to receive valuable information about your business and its offerings. As one of the best email marketing agencies in Lansing
, we design engaging email campaigns that boost your sales and grow your business to new heights. Our email marketing services include: </p>
                    </div> </div>
<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
   <ul className='o1'>
    <li className='f1'>Email marketing strategy:<p className='b'>We create personalized and segmented emails that align with your business goals and target audience. 
    </p></li>
    <li className='f1'>Database audit:<p className='b'>We compile recipient mailing lists and build targeted email leads from lead generation. Our team reformats and audits recipients and contact details to ensure accuracy. 
    </p></li>
    <li className='f1'>Email designing:<p className='b'> We don't use predesigned templates. Instead, we create captivating and responsive email designs compatible with various screen sizes and devices. 
    </p></li>
    <li className='f1'>Monitoring & reporting: <p className='b'> We consistently track and monitor key metrics, including open rate,  bounced emails, subscribed or unsubscribed emails, conversion rate, customer conversion, email list growth, geostatistics, and traffic inflow for web pages after each campaign. Furthermore, we refine our campaigns for optimized performance. 
    </p></li>
    <li className='f1'> <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Launch campaign </a></div>   </li>
   </ul>
</div>
<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                    <div className='row'>
                    <img src={pic} className='seo' alt=''/>
                    </div> </div>
</div>
 </div>
            </section>
            <br>
            
            
            </br>
        <section className='digi-logos'>
                <div className='container'>
                    <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
                    <h2 className='Minimize'>Clients who trust us </h2>
                    <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
                    <div className='mobile-client'>
                        <Industriesserve />
                    </div>
                </div>
                <div className='logos-reviws'>
                    <br />
                    <Bgflyinglogos />
                    <br />
                    <br />
                    <Bgflyinglogos2 />
                    <br />
                    <br />
                    <Bgflyinglogos />
                    <br />
                    <br />
                    <Bgflyinglogos2 />
                    <br />
                    <br />
                    <Bgflyinglogos />
                    <br />
                </div>
            </section>
            <section className='acalkeywords'>
  <div className='container'>
    <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">Why choose us</h3>
     <h2>Reasons that help us gain an edge</h2>
      </div>
    </div>
    <br />
  <div className="row ltn__custom-gutter">
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<img src={ExceptionalDesigns} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Innovation </h4>
					<p>We consistently brainstorm to generate creative ideas that resonate with your business objectives.</p>
					</div>
				</div>
				</div>
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={QuickTurnaround} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Advanced technology</h4>
					<p>We stay one step ahead of the curve when it comes to using cutting-edge SEO tools and techniques.</p>
					</div>
				</div>
				</div>
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={UnlimitedRevisions} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Result-driven approach</h4>
					<p>Our SEO services focus on achieving measurable results that contribute to your business growth and ROI.</p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={innovative} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Personalized strategies </h4>
					<p>We don't believe in a one-size-fits-all approach. Instead, we develop personalized SEO strategies based on your unique needs and goals. 
                    </p>
					</div>
				</div>
				</div>
			</div>
            <div className="row ltn__custom-gutter">
			<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<img src={SimplifiedGraphics} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Dedicated team </h4>
					<p>We pride ourselves on having a dedicated and experienced team of SEO professionals committed to driving exceptional results for our clients.  
                    </p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={ImpactfulVisuals} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Industry experience  </h4>
					<p>We are an SEO agency in Lansing boasting an experience of more than 10 years of propelling online growth for businesses across diverse industries.  
                    </p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={AffordableServices} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Data-driven approach </h4>
					<p>Every move we make is calculated and backed by meaningful insights to ensure impactful results for our esteemed customers. 
                    </p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={BespokeServices} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<div className="ltn__feature-info">
					<h4>Gamut of services  </h4>
					<p>From SEO to ORM, email marketing,SMM, web designing and much more, we offer an array of services that your business needs for digital success. 
                    </p>
                    </div>
					</div>
				</div>
				</div>
			</div>    
  
    </div>
</section>
    
            <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
          {/* <img src={question1} alt='' className='question' /> */}
          <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
          <div className="ltn__faq-area">
    <div className="ltn__faq-inner ltn__faq-inner-2">
        <div id="accordion_2">
         
            <div className="card">
                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                    How do you adapt SEO strategies for seasonal trends and events in Lansing?
                </h3>
                <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>We adapt SEO strategies for seasonal trends and events in Lansing by creating seasonal content, optimizing for event-specific keywords, promoting local festivals and activities, and adjusting PPC campaigns to reflect seasonal demand. This approach helps businesses capitalize on local seasonal opportunities and increase visibility during peak periods.</p>
                    </div>
                </div>
            </div>

           
            <div className="card">
                <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true">
                    How can proactive ORM strategies help Lansing businesses mitigate the impact of negative online reviews?
                </h3>
                <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>Proactive ORM strategies help Lansing businesses mitigate the impact of negative online reviews by monitoring reviews across platforms, responding promptly and professionally to feedback, encouraging satisfied customers to leave positive reviews, and implementing strategies to improve overall customer experience. By maintaining a proactive approach, businesses can build resilience against negative reviews and maintain a positive online reputation.</p>
                    </div>
                </div>
            </div>

           
            <div className="card">
                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                    How do you measure the ROI of email marketing campaigns for Lansing businesses?
                </h3>
                <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>We measure the ROI of email marketing campaigns for Lansing businesses by tracking key performance indicators such as open rates, click-through rates, conversion rates, and revenue generated from email campaigns. Additionally, we analyze customer lifetime value and compare campaign costs to assess overall profitability and effectiveness in driving business growth in Lansing.</p>
                    </div>
                </div>
            </div>

            <div className="card">
                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                    How can structured data markup enhance SEO results for Lansing businesses?
                </h3>
                <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>Structured data markup can enhance SEO results for Lansing businesses by providing search engines with additional context about the content on their websites. This can lead to enhanced rich snippets, improved visibility in search engine results pages (SERPs), and increased click-through rates. Businesses in Lansing can use structured data to highlight events, products, reviews, and other relevant information that can attract local customers.</p>
                    </div>
                </div>
            </div>

          
            <div className="card">
                <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                    How do you integrate online reputation management with social media strategies to enhance brand perception in Lansing?
                </h3>
                <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                    <div className="card-body">
                        <p>We integrate online reputation management with social media strategies to enhance brand perception in Lansing by actively engaging with followers, responding to comments and messages promptly, sharing positive customer experiences, addressing concerns transparently, and showcasing community involvement. This integrated approach helps build a positive brand image and strengthen relationships with Lansing's local community.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

          <div className='fqbtn'>
          <a class="theme-btn btn btn-effect-1 text-uppercase" href="/faqs" tabindex="0">View All</a>
          </div>
        </div>
      </div>
    </div>
   </section>
   <Serivcecontactform />
            <Footer_v1 />

        </>
    )
}

export default SEOagencyinlansing
