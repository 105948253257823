import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import faqvideo from '../../images/faqvideo.mp4';
import question from '../../images/question.png';
import Faqdigital from '../section-components/faqdigital';
import digitalcreative from "../../images/creative-digital/digitalcreative.webp"
import cr01 from "../../images/creative-digital/cr01.webp"
import cr02 from "../../images/creative-digital/cr02.webp"
import cr03 from "../../images/creative-digital/cr03.webp"
import credigi1 from "../../images/creative-digital/cre-digi-1.png"
import credigi2 from "../../images/creative-digital/cre-digi-2.png"
import credigi3 from "../../images/creative-digital/cre-digi-3.png"
import credigi5 from "../../images/creative-digital/cre-digi-5.png";
import orangecircle from "../../images/creative-digital/orangecircle.webp";
import mobidigi from "../../images/creative-digital/mobidigi.png";
import herogirl from "../../images/creative-digital/herogirl.webp";


import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';
import cr04 from "../../images/creative-digital/cr04.webp"
import cr05 from "../../images/creative-digital/cr05.webp"
import cr06 from "../../images/creative-digital/cr06.webp"
import ditalgirl from "../../images/creative-digital/ditalgirl.webp"

import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';


const Digitalmarketing = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Digital Marketing Agency Services | Digital Marketing Consulting Services</title>
<meta name="description" content="Whizamet is a top digital marketing agency offering best-in-class online marketing services. Amplify your online reach with our digital marketing consulting services." />
</Helmet>
<section className='cretivedigital'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12'>
              <h2 className='creative-heading'>We Are A Creative <br /><span>Digital </span>Agency</h2>
              <img src={mobidigi} className='mobidigi' alt='' />
              <div className='img-box-digital'>
                 <div class="tooltip1 top">
                    <img
                     decoding="async"
                    className="wow fadeInUp  animated"
                     data-wow-duration="500ms"
                    data-wow-delay="1400ms"
                    src={cr01}
                     alt="Hero"
                     style={{
                       visibility: "visible",
                      animationDuration: "500ms",
                       animationDelay: "1400ms",
                    animationName: "fadeInUp"
                      }}
/>
            
                 </div>
              
                 <div class="tooltip2 top">
                 <img
  decoding="async"
  className="cr02 wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1000ms"
  src={cr02}
  alt="icon"
  style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1000ms",
    animationName: "zoomIn"
  }}
/>
                 
             
                 </div>
                 <div class="tooltip3 top">
                 <img
  decoding="async"
  className="hero-thumb__icon5 wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1000ms"
  src={cr03}
  alt="icon"
  style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1000ms",
    animationName: "zoomIn"
  }}
/>

                      </div>
                 {/* <div class="tooltip4 top">
                   <img src={cr04} className='cr01' alt='' />
                 </div> */}
               
                 <div class="tooltip5 top">
                 <img
  decoding="async"
  className="hero-thumb__icon6 wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1200ms"
  src={cr05}
  alt="icon"
  style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1200ms",
    animationName: "zoomIn"
  }}
/>
              
                 </div>
                 <div class="tooltip6 top">
                 <img
  decoding="async"
  className="hero-thumb__icon5 wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1000ms"
  src={cr06}
  alt="icon"
  style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1000ms",
    animationName: "zoomIn"
  }}
/>
                
                 </div>
                        
    <img src={digitalcreative} className='digitalcreative' alt='' />
    <div className='gry-bg'>
    <img src={ditalgirl} className='ditalgirl' alt='' />
    </div>
   
              </div>
            

            </div>
        </div>
    </div>
</section>
<section className='creative2'>
<div className='container'>
                    <div className='row text-center'>
                        <div className='col-xxl-12'>
                            <h2>Holistic Digital Marketing Services</h2>
                            <p className='yro'>Is your business lacking online visibility? We can help you boost your online presence
                                and reach your target audience.</p>
                        </div>
                    </div>
                    <div className='row' id='stunning'>

                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>SEO</h3>
                                    <p>Improve organic visibility, increase quality traffic and take your website to new heights in search engine rankings with our SEO services. </p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>SMM</h3>
                                    <p>Our experts can manage your social presence across all platforms, helping you expand your reach and boost engagement.</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi3} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Graphic Designing</h3>
                                    <p>Whether you want a new logo, engaging videos, creative social posts or anything in between, we have you covered.</p>
                                </div>
                            </div>

                        </div>

                   
               
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi1} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Email Marketing</h3>
                                    <p>Our email marketing experts specialize in generating qualified leads for your business with targeted email campaigns.</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi2} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Online Reputation</h3>
                                    <p>With result-oriented ORM strategies, we strive to build, improve and protect your hard-earned online reputation. </p>
                                </div>
                            </div>

                        </div>

                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                            <div className='digi-single-serivce'>
                                <span><img src={credigi5} className='cre-digi' alt='cre-digi-1' /></span>
                                <div className='digi-single-content'>
                                    <h3>Marketing Ads</h3>
                                    <p>We can boost your presence through PPC, google ads, LinkedIn ads, and Facebook ads both organically & promotionally.</p>
                                </div>
                            </div>

                        </div>
                        </div>
                    </div>

</section>
<section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='creative3'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
        <div className='nubiii'>
          <h2 >Grow Traffic. Generate Leads. Boost Revenue</h2>
          <p className='higdfher'>With our digital marketing expertise, we’re confident to deliver 2x higher visibility and 10x more sales for your business.</p>
        
          <p className='kickstart'>Let’s kickstart your journey towards a better online presence.</p>
          <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Call Us Now</a>
        </div>
      </div>
      <div className='col-xxl-6 col-xl-6 col-md-6 col-12'>
      <div className='orng-sec'>
     
  <p className='p1' 
  decoding="async"
 style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "2000ms",
    animationName: "zoomIn"
  }}>We fuel the sales Pipeline</p>
  <p className='p2' 
  decoding="async"
 style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "2000ms",
    animationName: "zoomIn"
  }}>We Drive Growth</p>

<p className='p3' 
  decoding="async"
 style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "2000ms",
    animationName: "zoomIn"
  }}>We Meet Targets</p>
 <img
  decoding="async"
  className="orangecircle wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1000ms"
  src={orangecircle}
  alt="icon"
  style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1000ms",
    animationName: "zoomIn"
  }}
/>
<img 
 decoding="async"
  className="ditalgirl-bottom wow zoomIn  animated"
  data-wow-duration="500ms"
  data-wow-delay="1000ms"
  alt="icon"
src={herogirl} 
style={{
    visibility: "visible",
    animationDuration: "500ms",
    animationDelay: "1000ms",
    animationName: "zoomIn"
  }}
  />
      </div>
      </div>

    </div>
  </div>
</section>

<section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered</h3>
          <h2>Frequently asked questions</h2>
        <Faqdigital />
        
        </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Digitalmarketing

