import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Edevelopment from '../../images/Edevelopment.webp';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from  '../../images/circle.png';
import Visibility from '../../images/Visibility.png';
import customerservice from "../../images/customerservice.png";
import freightbrokeg from '../../images/freightbrokeg.png';
import OManagement from "../../images/OManagement.png";
import leadgeneration from "../../images/leadgeneration.png";
import usatruck from "../../images/usatruck.png";
import livechat from "../../images/livechat.png";
import whitecircle from '../../images/whitecircle.webp';
import usamap from "../../images/usamap.png";
import headset from '../../images/headset.png';
import question from '../../images/question.png';
import Bpofaq from '../section-components/Bpofaq';
import Freightfaq from '../section-components/freightfaq';
import faqvideo from '../../images/faqvideo.mp4';



const Freightbrokerage = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Top Freight Brokerage Agency in India</title>
<meta name="description" content="Optimize your shipping with our freight brokerage agency. Reliable solutions, strategic partnerships, and seamless logistics. Elevate your freight management with us" />
</Helmet>
<section className='Freight-bnner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-10 col-12'>
            <div className='BPOsolutions'>
            <h1>Your Reliable <span className='bpospan'>Shipping Partner</span> For On-Time Deliveries </h1>
                <p className='bnrtext'>We specialize in matching shippers with the best carriers at the best rates to ensure safe and timely delivery. </p>
               <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
              </div>
              </div>
      
    </div>

    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='Freightfirst'>
 <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'> 
           <h2>Freight brokerage services that scale your <span className='bpospan'>business</span> to new heights </h2>
     <p class="content">We’ll manage your shipping needs while you focus on your business growth. </p>
        </div>
            </div>
        <div className='row' id='apab'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
         <h3>Order management </h3>
            <p>Our team will collect crucial information about your load, including freight size and type, to find a suitable carrier for your freight.</p>
         </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
         <h3>Carrier evaluation </h3>
            <p>We find and vet the best carriers from our vast network to ensure safe and on-time freight delivery. </p>
         </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
          <h3>Carrier negotiations </h3>
            <p>
            We negotiate with carriers for better terms, rates and conditions to secure the best deal for our clients.
            </p>
         </div>
            </div>
           
           
        </div>
        <div className='row' id='equipment'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
         <h3>Pick up arrangement</h3>
            <p>Once we match the shipment to the best carrier, we’ll arrange a quick pick-up of goods from the shipper's location. </p>
         </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
         <h3>Shipment tracking </h3>
            <p>Our team consistently tracks your shipment while it's in transit and updates you about the progress and status.</p>
         </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
         <div className='smoothing'>
          <h3>Compliance </h3>
            <p>
            We’ll manage your compliance, including paperwork, insurance, permits, licenses and other legal aspects.
            </p>
         </div>
            </div>
           
           
        </div>
        
    </div>
</section>

<section className='Freight-center'>
<div className='container'>
        <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <h3 className="mainsub">For carriers </h3>
        <h2 className='smooth'>Maximize your profits with a wide array of <br /> shipping opportunities</h2>
        
         <div className='Out'>
         <img src={whitecircle} className='whitecircle' alt='' />
            <div className='F-b1ox1'>
         <img src={Visibility} className='Visibility' alt='' />
         <h3>Preferred Lanes </h3>
                 </div>
   

            <div className='F-b1ox2'>
         <img src={Visibility} className='Visibility' alt='' />
         <h3>Competitive Pricing </h3>
                 </div>
 

            <div className='F-b1ox3'>
         <img src={Visibility} className='Visibility' alt='' />
         <h3>Timely Payments </h3>
                 </div>
     
         </div>
         
                       </div>
                                      </div>
                  </div>
     
   </section>

<section className='Clearance'>
   <div className='container'>
      <div className='row'>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
         <img src={usatruck} className='usatruck' alt='circle' />
         </div>
         <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <h2>Experience “The Freight brokerage” difference with Whizamet</h2>
            <ul className='identify'>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reliable and vetted network of 15,000+ trusted carriers </li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Flexible solutions for all your shipping requirements </li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Timely in-transit shipment monitoring </li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Detailed reporting and updates for shipment visibility </li>
   <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Accessible and responsive team available round the clock</li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Streamlined processes that ensure timely delivery of shipment</li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Expert issue resolution through reliable communication and coordination </li>
	<li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Reduced burden and cost savings</li>
</ul>
         </div>
        
      </div>
   </div>
</section>
<section className='dispatchlast'>
<div className='container'>
    <div className='row'>
<div className="col-xxl-6 col-xl-6 col-lg- 12 col-md-12 col-12">
<img src={freightbrokeg} className='freightbrokeg' alt='' />
  
  </div>
  <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12"id='space'>
  <h3 className='light-text'>Ready to </h3>
<h2 className='dark-text'>ship with us?</h2>
{/* <h3 div className='light-text2'> hassle-free driving?</h3> */}
<p className='white-text'>We’ll help you deliver your shipment on time without any hassle. Get in touch with us, and let’s get started. </p>
<a href="/contact-us"><button type="button" class="btn btn-outline-secondary">Get in touch</button></a>
  </div>

  </div>
  </div>

</section>

   <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
    <Freightfaq />
         
        </div>
      </div>
    </div>
   </section>


<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Freightbrokerage

