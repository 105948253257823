import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import caseimg from '../../images/caseimg.jpg';
import cs01 from '../../images/cs01.webp';
import cs02 from '../../images/cs02.webp';
import cs03 from '../../images/cs03.webp';
import cs04 from '../../images/cs04.webp';
import cs05 from '../../images/cs05.webp';
import cs06 from '../../images/cs06.webp';
import cs07 from '../../images/cs07.webp';
import cs08 from '../../images/cs08.webp';
import cs09 from '../../images/cs09.webp';
import cs010 from '../../images/cs010.webp';
import cs011 from '../../images/cs011.webp';
import cs013 from '../../images/cs013.webp';
import cs014 from '../../images/cs014.webp';
import cs015 from '../../images/cs015.png';
import Page_header from '../global-components/page-header';
import { Helmet } from 'react-helmet';


const Marketingcasestudy = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Dispatch Casestudy - Whizamet</title>
<meta name="description" content="" />
</Helmet>

<Page_header headertitle="Dispatch Casestudy" />
{/* <section className='casesty'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
            <div className='casesty-content'>
            <h3 class="Expert animated fadeIn">Homestays</h3>
            <h1>Homestay Case Study</h1>
                <p className='bnrtext'>With 10+ years of working as a preferred Website Design and <br />Development company for brands.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12 ellipse'>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 upimg'>
                <div className='container c1'>
                  <div className='row'>
                         <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                              <img src={csimg2} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                <div className='container c2'>
                  <div className='row'>
                        <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                         <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
                              <img src={csimg1} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-12'>
                              <img src={csimg3} className='caseim' alt='' />
                          </div>
                          <div className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-12'>                           
                          </div>
                  </div>
                </div>
              </div>
               
            </div>  
        </div>
       
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section> */}



<section className='csesty'>
 <div className='container'>
    <div className='row'>
        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12 caseleft'>
          <h4 className='back'> About the client </h4>
            <p className='backpara'>American Smart Trucking is a prominent truck dispatch company in the USA that provides professional truck dispatch and related services to owner-operators, carriers and trucking companies who are frustrated with managing back-office operations and wasting their time on low-paying freight. The company alleviates the burdens of owner-operators, carriers and drivers by helping them find loads, negotiate rates, manage paperwork and much more so that they can focus on important aspects of their business rather than worrying about back-end tasks. </p>
          <h4 className='back'>The objective</h4>
            <p className='backpara'>American Smart Trucking intended to expand its network and was looking to hire drivers, owner-operators and carriers for their truck dispatch services. Their aim was to not only increase the number of professionals using their truck dispatch services but also to become a reliable partner in the trucking and logistics industry. </p>
            <h4 className='back'>The challenge </h4>
            <p className='backpara'>When looking to expand a customer base, there are countless challenges that one has to encounter. The biggest challenge was the limited network. American Smart Trucking struggled with a limited network of drivers, owner-operators and carriers. The challenge was to expand their network to attract potential clients for their dispatch services.</p>
            <p className='backpara'>The second challenge was limited search engine visibility. A robust digital marketing strategy was required to increase search engine rankings and attract an extensive and qualified pool of carriers, owner-operators and drivers.</p>
            <p className='backpara'>High competition in the truck dispatch industry was yet another challenge that prevented our client from being a trusted partner for truck dispatch. The increased competition made it extremely challenging for American Smart Trucking to stand out from the competition.</p>
            <h4 className='back'>The strategy</h4>
            <p className='backpara'>To overcome these challenges and meet our client's objectives, we developed powerful online marketing strategies that included targeted online advertising, paid ad campaigns, social media campaigns and search engine optimization to attract drivers, carriers and owner-operators. </p>
<div class="cont">
<div class="rightbox">
  <div class="rb-container">
    <ul class="rb">
      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Targeted paid advertising campaigns 
        </div>
        <div class="item-title">We developed and executed targeted paid ad campaigns on Facebook, utilizing Facebook's advertising capabilities to reach a niche audience interested in truck dispatch services. We highlighted the key features of the company's dispatch services and the benefits for drivers, owner-operators and carriers through engaging ad content, focusing on increased earnings, preferred lanes, efficient load management and round-the-clock support. </div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Search engine optimization
        </div>
        <div class="item-title">Our SEO experts deployed comprehensive SEO strategies that included optimizing website content, implementing relevant keywords and building quality backlinks to improve the search engine rankings of American Smart Trucking.</div>
      </li>

      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Content marketing 
        </div>
        <div class="item-title">We created engaging content, including blog posts and informative articles that described the company's USPs and their approach to finding the best loads. This approach provided valuable information to the target audience and also contributed to increased search engine rankings.</div>
      </li>


      <li class="rb-item" ng-repeat="itembx">
        <div class="timestamp">
        Social media
        </div>
        <div class="item-title">We leveraged social media channels, including Instagram, Facebook and LinkedIn to actively engage with American Smart Trucking's potential customers. Through regular posting and sharing industry updates and success stories, we built a sense of trust and credibility for the company and increased its presence on social platforms.</div>
      </li>

    </ul>

  </div>
</div>
</div>
  
            <h4 className='back'>The results</h4>
            <p className='backpara'>Our digital marketing strategies led to a substantial increase in job applications from drivers, owner-operators and carriers. We successfully hired thousands of owner-operators, carriers and drivers that increased the company’s customer base and contributed to revenue growth.</p>
            <p className='backpara'>Furthermore, with our consistent SEO efforts, we improved the company’s search engine rankings, positioning them on the first page of search results. In addition, by actively engaging with the company's potential clients on social media channels, we were able to build strong connections with them and drive positive feedback and reviews that strengthened our client’s reputation and credibility in the trucking dispatch industry. </p>
            <img src={cs01} alt='' className='cs' />
            <img src={cs02} alt='' className='cs' />
            <img src={cs03} alt='' className='cs' />
            <img src={cs04} alt='' className='cs' />
            <img src={cs05} alt='' className='cs' />
            <img src={cs06} alt='' className='cs' />
            <img src={cs07} alt='' className='cs' />
            <img src={cs08} alt='' className='cs' />
            <img src={cs09} alt='' className='cs' />
            <img src={cs010} alt='' className='cs' />
            <img src={cs011} alt='' className='cs' />
            <img src={cs013} alt='' className='cs' />
            <img src={cs014} alt='' className='cs' />
            <img src={cs015} alt='' className='cs' />
            </div>

        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12 text-center'>
           <img src={caseimg} alt='' className='caseimg' />
        </div>
    </div>
  </div>
</section>


<Footer_v1 />
</>
)
}

export default Marketingcasestudy

