import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from '../../images/circle.png';
import { Link } from 'react-router-dom';
import question from '../../images/question.png';
import Faqhome from '../section-components/faqhome';
import webport1 from '../../images/webport1.png';
import webport2 from '../../images/webport2.png';
import responsive02 from '../../images/responsive02.png';
import logist0 from '../../images/logist0.png';
import word01 from '../../images/word01.png';
import grportfolio1 from '../../images/grportfolio1.png';
import grportfolio2 from '../../images/grportfolio2.png';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import Ormverticaltabs from '../section-components/ormverticaltabs';
import Serivcecontactform from '../section-components/serivcecontactform';
import react from '../../images/react.png';
import javas from '../../images/javas.png';

import web1 from '../../images/web1.jpg';
import web2 from '../../images/web2.jpg';
import web3 from '../../images/web3.jpg';
import web4 from '../../images/web4.jpg';
import web5 from '../../images/web5.jpg';
import web6 from '../../images/web6.jpg';
import socialmedia from '../../images/socialmedia.png';
import email from '../../images/email.jpg';
import seo from '../../images/seo.webp';
import ppc from '../../images/ppc.webp';
import graphic from '../../images/graphic.jpg';
import logos from '../../images/logos.jpg';
import faqvideo from '../../images/faqvideo.mp4';


const Ourportfolio = () => {
    return (
        <>
            <NavbarV3 />
            {/* <section className='ourportfolio'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='seocontentbg1'>
                                <h3 className='Expert'>Our work</h3>
                                <h1>Creating Memorable Experiences <br />For Visionary Brands</h1>
                                <p className='bnrtext'>Take a journey through our amazing work that defines us.</p>
                                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='left-seoshape1'></div>
                <img src={circle} className='seocircle2' alt='circle' />
            </section> */}
            <section className='workportfoilo'>
                <div className='container' id='billing'>
                    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 '>
                        <div className='catagory-parent'>
                        <h3 class="mainsubheading">Our Work</h3>
                        <h2>Our captivating portfolio</h2>
                        <p>Check out some of our finest work that makes us stand out from the clutter.</p>
                        </div>
                        <div className='newport'>
                            <div className="ltn__tab-menu-list mb-50">
                                <div className="nav">
                                    <a className="active show our-work1" data-bs-toggle="tab" href="#ltn_tab_1_1">Web Designing </a>
                                    <a className="our-work1" data-bs-toggle="tab" href="#ltn_tab_1_5">SEO </a>
                                    <a className="our-work1" data-bs-toggle="tab" href="#ltn_tab_1_2">Graphic Designing</a>
                                    <a className="our-work1" data-bs-toggle="tab" href="#ltn_tab_1_3">SMM</a>
                                    <a className="our-work1" data-bs-toggle="tab" href="#ltn_tab_1_4">Email Marketing </a>
                                    <a className="our-work1" data-bs-toggle="tab" href="#ltn_tab_1_6">PPC</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 '>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="ltn_tab_1_1">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web1} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={react} className='responsive02' alt='' /><br />
                                                        <p>React</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web2} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={word01} className='responsive02' alt='' /><br />
                                                        <p>Wordpress</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web3} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={react} className='responsive02' alt='' /><br />
                                                        <p>React</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web4} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={react} className='responsive02' alt='' /><br />
                                                        <p>React</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web5} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={react} className='responsive02' alt='' /><br />
                                                        <p>React</p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <img src={web6} className='webport1' alt='' />
                                                <ul>
                                                    <li>
                                                        <img src={responsive02} className='responsive02' alt='' /><br />
                                                        <p>Responsive</p>
                                                    </li>
                                                    <li>
                                                        <img src={javas} className='responsive02' alt='' /><br />
                                                        <p>Javascipt</p>
                                                    </li>
                                                    <li>
                                                        <img src={word01} className='responsive02' alt='' /><br />
                                                        <p>Wordpress</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ltn_tab_1_2">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={graphic} className='webport1' alt='' />
                                            </div>
                                            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <img src={grportfolio2} className='webport1' alt='' />
                            </div> */}
                                        </div>
                                        <br /><br />

                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={logos} className='webport15' alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ltn_tab_1_3">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={socialmedia} className='webport1' alt='' />
                                                {/* <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ltn_tab_1_4">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={email} className='webport1' alt='' />
                                                {/* <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul> */}
                                            </div>
                                            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <img src={webport2} className='webport1' alt='' />
                            <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul>
                            </div> */}
                                        </div>

                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ltn_tab_1_5">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={seo} className='webport1' alt='' />
                                            </div>
                                            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <img src={webport2} className='webport1' alt='' />
                            <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul>
                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="ltn_tab_1_6">
                                    <div className="ltn__myaccount-tab-content-inner">
                                        <div className='row' id='wbport'>
                                            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                                                <img src={ppc} className='webport1' alt='' />
                                                {/* <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul> */}
                                            </div>
                                            {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <img src={webport2} className='webport1' alt='' />
                            <ul>
                                    <li> 
                                    <img src={responsive02} className='responsive02' alt='' /><br />
                                    <p>Custom Website</p>
                                    </li>
                                    <li> 
                                    <img src={logist0} className='responsive02' alt='' /><br />
                                    <p>Logistics</p>
                                    </li>
                                    <li> 
                                    <img src={word01} className='responsive02' alt='' /><br />
                                    <p>Wordpress</p>
                                    </li>
                                </ul>
                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className='homefaqs'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <video src={faqvideo} controls
                                muted
                                autoPlay={"autoplay"}
                                preLoad="auto"
                                loop id='faqvideo'>
                            </video>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <h3 className='mainsubheading'>FAQ</h3>
                            <h2>Frequently Asked Questions</h2>
                            <Faqhome />


                        </div>
                    </div>
                </div>
            </section>
            <Serivcecontactform />
            <Footer_v1 />
        </>
    )
}

export default Ourportfolio









// import React from 'react';
// import Footer_v1 from '../global-components/footer';
// import NavbarV3 from '../global-components/navbar-v3';
// import circle from  '../../images/circle.png';
// import { Link } from 'react-router-dom';
// import question from '../../images/question.png';
// import Faqhome from '../section-components/faqhome';
// import webport1 from '../../images/webport1.png';
// import webport2 from '../../images/webport2.png';
// import responsive02 from '../../images/responsive02.png';
// import logist0 from '../../images/logist0.png';
// import word01 from '../../images/word01.png';
// import grportfolio1 from '../../images/grportfolio1.png';
// import grportfolio2 from '../../images/grportfolio2.png';
// import KeywordResearch from '../../images/KeywordResearch.png';
// import Technical from '../../images/Technical.png';
// import localseo from '../../images/localseo.png';
// import EcommerceSEO from '../../images/EcommerceSEO.png';
// import Ormverticaltabs from '../section-components/ormverticaltabs';
// import Serivcecontactform from '../section-components/serivcecontactform';
// import react from '../../images/react.png';
// import javas from '../../images/javas.png';

// import web1 from '../../images/web1.jpg';
// import web2 from '../../images/web2.jpg';
// import web3 from '../../images/web3.jpg';
// import web4 from '../../images/web4.jpg';
// import web5 from '../../images/web5.jpg';
// import web6 from '../../images/web6.jpg';
// import socialmedia from '../../images/socialmedia.png';
// import email from '../../images/email.jpg';
// import seo from '../../images/seo.webp';
// import ppc from '../../images/ppc.webp';
// import graphic from '../../images/graphic.jpg';
// import logos from '../../images/logos.jpg';
// import faqvideo from '../../images/faqvideo.mp4';


// const Ourportfolio = () => {
    
//     return (
// <>
// <NavbarV3 />
// <section className='ourportfolio'>
//     <div className='container'>
//         <div className='row'>
//             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//             <div className='seocontentbg1'>
//             <h3 className='Expert'>Our work</h3>
//             <h1>Creating Memorable Experiences <br />For Visionary Brands</h1>
//                 <p className='bnrtext'>Take a journey through our amazing work that defines us.</p>
//                 <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
//             </div>
//             </div>
//         </div>
//           </div>
//     <div className='left-seoshape1'></div>
//     <img src={circle} className='seocircle2' alt='circle' />
// </section>
// <section className='workportfoilo'>
// <div className='container' id='billing'>
//     <div className='row' >
//         <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
//         <h3 class="mainsubheading">Categories</h3>
//              <div className='newport'>
//             <div className="ltn__tab-menu-list mb-50">
// 					  <div className="nav">                                            
// 						<a className="active show our-work" data-bs-toggle="tab" href="#ltn_tab_1_1">Web Design and Development</a>
// 						<a className="our-work" data-bs-toggle="tab" href="#ltn_tab_1_2">Graphic Designing</a>
// 						<a className="our-work" data-bs-toggle="tab" href="#ltn_tab_1_3">Social Media Marketing </a>
// 						<a className="our-work" data-bs-toggle="tab" href="#ltn_tab_1_4">Email Marketing </a>
// 						<a className="our-work" data-bs-toggle="tab" href="#ltn_tab_1_5">SEO </a>
// 						<a className="our-work" data-bs-toggle="tab" href="#ltn_tab_1_6">Pay Per Click</a>
// 											</div>
// 					</div>
//             </div>
//         </div>
//         <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-12'>
//         <h2>Our captivating portfolio</h2>
//         <p class="content">Check out some of our finest work that makes us stand out from the clutter.</p>
//         <div className="tab-content">
// 					  <div className="tab-pane fade active show" id="ltn_tab_1_1">
// 						<div className="ltn__myaccount-tab-content-inner">
// 						  <div className='row' id='wbport'>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                                 <img src={web1} className='webport1' alt='' />
//                                 <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={react} className='responsive02' alt='' /><br />
//                                     <p>React</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={web2} className='webport1' alt='' />
//                             <ul>
//                             <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                           </div>
//                           <div className='row' id='wbport'>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                                 <img src={web3} className='webport1' alt='' />
//                                 <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={react} className='responsive02' alt='' /><br />
//                                     <p>React</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={web4} className='webport1' alt='' />
//                             <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={react} className='responsive02' alt='' /><br />
//                                     <p>React</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                           </div>
//                           <div className='row' id='wbport'>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                                 <img src={web5} className='webport1' alt='' />
//                                 <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={react} className='responsive02' alt='' /><br />
//                                     <p>React</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                             <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={web6} className='webport1' alt='' />
//                             <ul>
//                             <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Responsive</p>
//                                     </li>
//                                     <li> 
//                                     <img src={javas} className='responsive02' alt='' /><br />
//                                     <p>Javascipt</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul>
//                             </div>
//                           </div>
// 						</div>
// 					  </div>
// 					  <div className="tab-pane fade" id="ltn_tab_1_2">
// 						<div className="ltn__myaccount-tab-content-inner">
//                         <div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={graphic} className='webport1' alt='' />
//                             </div>
//                             {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={grportfolio2} className='webport1' alt='' />
//                             </div> */}
//                         </div>
// <br/><br/>

//                         <div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={logos} className='webport1' alt='' />
//                             </div>
//                         </div>
// 						</div>
// 					  </div>
// 					  <div className="tab-pane fade" id="ltn_tab_1_3">
// 						<div className="ltn__myaccount-tab-content-inner">
//                         <div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={socialmedia} className='webport1' alt='' />
//                                 {/* <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul> */}
//                             </div>
//                           </div>
// 						</div>
// 					  </div>
// 					  <div className="tab-pane fade" id="ltn_tab_1_4">
// 						<div className="ltn__myaccount-tab-content-inner">
// 						<div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={email} className='webport1' alt='' />
//                                 {/* <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul> */}
//                             </div>
//                             {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={webport2} className='webport1' alt='' />
//                             <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul>
//                             </div> */}
//                           </div>
						 
// 						</div>
// 					  </div>
// 					  <div className="tab-pane fade" id="ltn_tab_1_5">
// 						<div className="ltn__myaccount-tab-content-inner">
//                         <div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={seo} className='webport1' alt='' />
//                             </div>
//                             {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={webport2} className='webport1' alt='' />
//                             <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul>
//                             </div> */}
//                           </div>
// 						</div>
// 					  </div>
// 					  <div className="tab-pane fade" id="ltn_tab_1_6">
// 						<div className="ltn__myaccount-tab-content-inner">
//                         <div className='row' id='wbport'>
//                             <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
//                                 <img src={ppc} className='webport1' alt='' />
//                                 {/* <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul> */}
//                             </div>
//                             {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//                             <img src={webport2} className='webport1' alt='' />
//                             <ul>
//                                     <li> 
//                                     <img src={responsive02} className='responsive02' alt='' /><br />
//                                     <p>Custom Website</p>
//                                     </li>
//                                     <li> 
//                                     <img src={logist0} className='responsive02' alt='' /><br />
//                                     <p>Logistics</p>
//                                     </li>
//                                     <li> 
//                                     <img src={word01} className='responsive02' alt='' /><br />
//                                     <p>Wordpress</p>
//                                     </li>
//                                 </ul>
//                             </div> */}
//                           </div>
// 						</div>
// 					  </div>
// 					</div>
//         </div>
//     </div>
// </div>
// </section>



//  <section className='homefaqs'>
//     <div className='container'>
//       <div className='row'>
//         <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//         <video src={faqvideo}   controls
//           muted
//           autoPlay={"autoplay"}
//           preLoad="auto"
//           loop id='faqvideo'> 
//           </video>
//         </div>
//         <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
//         <h3 className='mainsubheading'>FAQ</h3>
//           <h2>Frequently Asked Questions</h2>
//           <Faqhome />
        
        
//         </div>
//       </div>
//     </div>
//    </section>
// <Serivcecontactform />
// <Footer_v1 />
// </>
// )
// }

// export default Ourportfolio

