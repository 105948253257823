import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Sidebar extends Component {
	render() {
		let anchor = '#'
		let imagealt = 'image'
		let publicUrl = process.env.PUBLIC_URL + '/'
		return (
			<div className="col-lg-4 go-top">
				<aside className="sidebar-area blog-sidebar ltn__right-sidebar">
					{/* Popular Post Widget */}
					<div className="widget ltn__popular-post-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Latest Blog</h4>
						<ul>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/Top-10-Digital-Marketing-Companies-In-India">
											<img src={publicUrl + "assets/img/blog/blogimg2.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/Top-10-Digital-Marketing-Companies-In-India">Top 10 Digital Marketing Companies In India</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />July 19, 2024</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link><img src={publicUrl + "assets/img/blog/blogimg3.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/top-7-digital-marketing-companies-in-la-california">Top 7 Digital marketing Companies in La California</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />June 30, 2024</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link><img src={publicUrl + "assets/img/blog/1234.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/9-compelling-reasons-to-choose-wordpress-for-your-next-project">9 Compelling Reasons to Choose WordPress for Your Next Project</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />August 01, 2024</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li>
							{/* <li>
								<div className="popular-post-widget-item clearfix">
									<div className="popular-post-widget-img">
										<Link to="/top7-digital-marketing-companies-in-the-harris-county-area-to-fasten-growth">
											<img src={publicUrl + "assets/img/blog/dmm.jpg"} alt="#" /></Link>
									</div>
									<div className="popular-post-widget-brief">
										<h6><Link to="/top7-digital-marketing-companies-in-the-harris-county-area-to-fasten-growth">Top 7 Digital Marketing Companies in the Harris County Area To Fasten Growth</Link></h6>
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date">
													<Link to="#"><i className="far fa-calendar-alt" />August 06, 2024</Link>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</li> */}
						</ul>
					</div>
					{/* Tagcloud Widget */}
					<div className="widget ltn__tagcloud-widget go-top">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Popular Tags</h4>
						<ul>
							<li><Link to="#">UI/UX Designer</Link></li>
							<li><Link to="#">SEO</Link></li>
							<li><Link to="#">Web Development</Link></li>
							<li><Link to="#">Content Marketing</Link></li>
							<li><Link to="#">Graphic</Link></li>
							<li><Link to="#">Digital Marketing</Link></li>
							<li><Link to="#">Marketing</Link></li>
							<li><Link to="#">Web Designing</Link></li>
							<li><Link to="#">Email Marketing</Link></li>
							<li><Link to="#">PPC</Link></li>
							<li><Link to="#">Socal Media</Link></li>
							<li><Link to="#">Video Marketing</Link></li>
						</ul>
					</div>

					{/* Menu Widget (Category) */}
					<div className="widget ltn__menu-widget ltn__menu-widget-2--- ltn__menu-widget-2-color-2--- go-top">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Top Categories</h4>
						<ul>
							<li>Google Analytics </li>
							<li>Digital Marketing </li>
							<li>Web Design & Development </li>
							<li>SEO </li>
							<li>Graphic Designing</li>
						</ul>
					</div>
					{/* Social Media Widget */}
					<div className="widget ltn__social-media-widget">
						<h4 className="ltn__widget-title ltn__widget-title-border-2">Follow us</h4>
						<div className="ltn__social-media-2">
							<ul>
								<li><a href="https://www.facebook.com/WhizAmetServices/" title="Facebook"><i className="fab fa-facebook-f" /></a></li>
								<li><a href="https://twitter.com/Whizamet_" title="Twitter"><i className="fab fa-twitter" /></a></li>
								<li><a href="https://www.linkedin.com/company/whizamet" title="Linkedin"><i className="fab fa-linkedin" /></a></li>
								<li><a href="https://www.instagram.com/whizametservices/" title="Instagram"><i className="fab fa-instagram" /></a></li>
							</ul>
						</div>
					</div>

					{/* Banner Widget */}
					<div className="widget ltn__banner-widget d-none go-top">
						<Link to="/shop"><img src={publicUrl + "assets/img/banner/2.jpg"} alt="#" /></Link>
					</div>
				</aside>
			</div>
		)
	}
}

export default Sidebar;
