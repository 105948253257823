import React, { Component } from 'react';


class Faqppc extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Why should I go for Paid ads? Won’t SEO give the desired results?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>SEO is a crucial part of online marketing strategy. Also, it's one of the most cost-effective elements of marketing strategies. However, the Internet is continuously evolving, and depending on your industry, it can take much time to rank on Google. PPC ads are the best way to reach your target audience quickly. As your marketing partner, we aim to maximize your profits from Paid ads.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        Do you offer PPC services alone or integrate them with other services?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <div className="ltn__video-img alignleft">
                         
                            </div>
                            <p>Whizamet offers standalone PPC services as well as integrated services with other marketing strategies. Our holistic approach recognizes that PPC, SEO, web design and all other marketing services must be combined for unparalleled results. No doubt PPC amplifies your message, but its success depends on the functionality of your overall website.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How much should I spend on PPC advertising?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>There are various factors that decide the PPC ad budget, such as your budget, industry, competition and marketing goals. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How do you optimize PPC campaigns for better results?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We provide ongoing optimization for PPC campaigns to ensure they continue to perform at their best. We consistently monitor campaign performance, adjust bidding strategies, refine ad copy, test new keywords, and implement best practices to improve ROI for our clients. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        What makes you the best PPC services provider?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Whizamet provides top-notch PPC services. Having relevant expertise and experience, we help you generate leads and boost conversions with our PPC campaigns.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqppc