import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from  '../../images/circle.png';
import customerservice from "../../images/customerservice.png";
import obsupport from "../../images/obsupport.png";
import OManagement from "../../images/OManagement.png";
import leadgeneration from "../../images/leadgeneration.png";
import sale from "../../images/sale.png";
import livechat from "../../images/livechat.png";
import callingteam from '../../images/callingteam.webp';

import headset from '../../images/headset.png';
import Bpofaq from '../section-components/Bpofaq';
import mobilebnner from '../../images/mobilebnner.png';
import faqvideo from '../../images/faqvideo.mp4';

const BPOsolutions = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best BPO Solution Provider in India</title>
<meta name="description" content="Outsource with confidence! As a leading BPO solution provider, we offer tailored services for efficiency and growth. Elevate your business operations with us." />
</Helmet>

<section className='BPOmobile'>
<img src={mobilebnner} className='mobilebnner' alt='' />
</section>
<section className='BPOsolutions'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
            <div className='BPOsolutions'>
            <h1>Driving <span className='bpospan'>sales & growth</span> through customer engagement  </h1>
                <p className='bnrtext'>We excel in personalized customer interactions that lead to elevated satisfaction, boosted sales and enhanced profitability.  </p>
               <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
              </div>
              </div>
      
    </div>

    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='BPOtwo'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our core services</h3>
     <h2> Customized offshoring services that lead to increased sales & ROI </h2>
     <p>From efficient call handling to personalized assistance, our offshoring solutions are tailored to minimize operational costs and optimize sales potential.</p>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={customerservice} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Responsive Inbound support</h3>
      <p className="incoming">
      We transform inbound inquiries into thriving sales opportunities through accurate responses, ensuring potential business growth.  
      </p>
      </div> 
      </span> 
      </div>  
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={obsupport} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Outbound support </h3>
      <p className="incoming">
      Our outbound solutions help you increase sales, explore untapped markets and nurture positive relationships with your customers. 
      </p>
      </div> 
      </span> 
      </div>  
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={OManagement} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Operations management</h3>
      <p className="incoming">
      We offer support in managing dealer operations by strengthening your existing support structure at the time of product launches, rollouts and peak usage periods.
      </p>
      </div> 
      </span> 
      </div>  
  </div>

      
</div>
            <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={leadgeneration} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Lead generation </h3>
      <p className="incoming">
      Our lead generation services help you expand your reach and acquire convertible and high-value leads for your business. 
      </p>
      </div> 
      </span> 
      </div>  
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={sale} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Chat and email support </h3>
      <p className="incoming">
      We help boost your profitability by ensuring quick responses to your customer’s queries, issues and inquiries. 
      </p>
      </div> 
      </span> 
      </div>  
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
  <div className="bposerviceBox">
  <span className='bop-icon'>
    <img src={livechat} className='calls' alt='' />
   <div className='handling'>
      <h3 className='Support-ib'>Sales </h3>
      <p className="incoming">
      Our skilled professionals possess vast experience in effectively engaging with potential customers, resulting in increased conversions and sales. 
      </p>
      </div> 
      </span> 
      </div>  
  </div>

      
</div>
</div>
</section>

<section className='bpo-center'>
<div className='container'>
        <div className='row'>
        <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12'>
              <img src={callingteam} className='callingteam' alt='' />
                       </div>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12'>
            <div className='bnr-inbound'>
            <h3 className='into'>Our advantages </h3>
                <h2 className='smooth'>The benefits you experience through our exceptional services </h2>
               <div class="fddf">
          <ul className='bpobenefits'>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>
        A steady flow of highly convertible leads</li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>
        Increased customer loyalty and satisfaction</li>
      <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>
      Enhanced sales, revenue growth and unprecedented success</li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>
        Decreased operational expenses and savings of up to 70%
        </li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>
        Access to a skilled team of experienced professionals 
        </li>
      </ul>
        </div> 
        </div>
          </div>
            
                 </div>
                  </div>
     
   </section>

<section className='BPOfirst'>
<img src={headset} className='headset' alt='' />
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
        <div className='ideal'>
       <h2>The traits that help us scale your <span className='bpospan'>business</span> to new heights</h2>
     <p class="content">We use advanced technologies and personalized communications strategies to propel your business growth. </p>
     </div>
        </div>
            </div>
        <div className='row' id='goals-01'>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
         <div className='smoothing'>
         <h3>State-of-art infrastructure </h3>
            <p>Our robust, scalable and secure infrastructure enables us to provide exceptional services to our clients.</p>
         </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
         <div className='smoothing'>
         <h3>24/7 availability </h3>
            <p>We are available round the clock to serve your customers with utmost dedication.</p>
         </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
         <div className='smoothing'>
          <h3>Data security </h3>
            <p>We implement stringent data security measures to safeguard your sensitive data and maintain its confidentiality. </p>
         </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
         <div className='smoothing'>
         <h3>Experienced team</h3>
            <p>Our skilled and diverse talent pool specializes in providing top-notch services while ensuring language proficiency and cultural compatibility. </p>
         </div>
            </div>
           
        </div>
        
    </div>
</section>
  <section className='bottom-serivces'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
      <div className='srv-cta'> 
        <h2> Let us be your reliable <span className="slimtext">growth partners </span></h2>
        <p className='content'>Get in touch with us to discuss your goals so that we can help you increase customer satisfaction, unlock new growth opportunities, and enhance your business profitability.</p>
<div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contactus" tabindex="0">Contact Us</a></div>
      </div>
    </div>
  </div>
</div>
   </section>
   
   <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
          {/* <img src={question} alt='' className='question' /> */}
          <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
    <Bpofaq />
       </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default BPOsolutions

