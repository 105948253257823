import React, { Component } from 'react';
import question from '../../images/question.png';
import FaqAbout from './faqabout';
import faqvideo from '../../images/faqvideo.mp4';
import Faqhome from './faqhome';

class AboutN7 extends Component {

    render() {

       

        return (
        
    <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>FAQ</h3>
          <h2>Frequently Asked Questions</h2>
     <Faqhome />
          
        </div>
      </div>
    </div>
   </section>
   
        )
        }
}

export default AboutN7