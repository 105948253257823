import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from  '../../images/circle.png';
import shopii from '../../images/shopii.webp';
import shpi from '../../images/shpi.webp';
import Shopifyctools from '../section-components/shopifytools';
import shopifydevelopment0 from '../../images/shopifydevelopment0.webp';

const Shopifydevelopment = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Shopify Development Services | Shopify Development Agency</title>
<meta name="description" content="Whizamet is a reputable Shopify development agency providing custom Shopify development services to increase the sales of your e-commerce store. Connect with us now." />
</Helmet>
<section className='Shopifydevelopment'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Shopify Development</h1>
                <p className='bnrtext'>We excel in creating easy-to-manage and feature-rich online stores with our Shopify development services.  </p>
               
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={shpi} className='shopii' alt='' />
            </div>
            
        </div>
      
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='shopiseclast'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <img src={shopii} className='shpp' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h2>We create websites that grow your sales </h2>
                <p class="content">Launching your project online and managing sales of your business is a daunting task. We’re here to shoulder this burden and relieve you from the stress of building your online store and fostering sales growth. </p>
                <p>Whether you’re nurturing a brand new concept, planning to revamp your existing online store or considering migrating from another e-commerce platform, we are your one-stop shop for all your needs. </p>
                <ul class="ltn__list-item-half clearfix">
     <li><i class="flaticon-home-2"></i>High performance </li>
     <li><i class="flaticon-mountain"></i>Bug-free coding </li>
     <li><i class="flaticon-heart"></i>Secure & scalable</li>
     <li><i class="flaticon-secure"></i>Effortless management</li>
     </ul>
            </div>
        </div>
    </div>
</section>
<section className='shopi-expert'>
    <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our expertise </h3>
     <h2>Explore our tech stack </h2>
     <p class="content">Take a peek at the cutting-edge technologies we use to create robust, fully secure online stores. </p>
     </div>
        </div>
        <Shopifyctools />
            </div>
    </div>
</section>
<section className='shopi-serivces'>
<div className='container'>
        <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12' id='pc-first'>
             <div class="onlinerpu-left">
               <h3 class="mainsubheading">Services we offer </h3>
              <h2>Discover our comprehensive suite of Shopify development services </h2> 
              <p class="content">From making minor tweaks to your current theme to creating a customized theme from scratch and seamlessly migrating your online store from a different platform, our team is proficient in handling every aspect of Shopify development. </p>
              
               </div>
                </div> 
            <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12'>
            <div className='row'>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-4 col-12'>
                    <div className='ppcbox'>
                   <h4>Custom Shopify development </h4>
                   <p>Familiar with the nitty-gritty of Shopify, our team understands your business goals to create an online store that suits your requirements.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-4 col-12'>
                    <div className='ppcbox'>
                   <h4>Shopify integration</h4>
                   <p>Whether it's integrating different payment gateways, third-party apps or creating custom functionalities, we handle everything with ease. </p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-12 col-md-4 col-12'>
                    <div className='ppcbox'>
                   <h4>Shopify migration </h4>
                   <p>Our experts will effortlessly migrate your store from a legacy platform to Shopify, ensuring a stress-free migration experience.</p>
                    </div>
                </div>
               </div>
                </div> 
            </div>
                  </div>
   </section>

<section className='Shopify-Solutions'>
    <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Why us </h3>
     <h2>Reasons to trust us for Shopify<br /> development services</h2>
     <p class="content">At Whizamet, we deliver excellence in our services- we don't just claim, we prove it. </p>
     </div>
        </div>
            </div>
            <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
             <h4>Swift turnaround time  </h4>
            <p>Tight deadlines are no challenge for us. With years of experience, we effortlessly meet urgent delivery requirements without stress. 
</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
               <h4>Global client base </h4>
            <p>Having gained recognition as one of the best Shopify development agencies, we are fortunate to have served hundreds of clients globally. </p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
            <h4>Scalable solutions </h4>
            <p>Your business is dynamic, and so should be your e-commerce platform. We create scalable solutions that grow along with your business. </p>
           </div>  
            </div>
        </div>      
    </div>
</section>
<section className='shopi-focus'>
    <div className='container'>
    <div className='row'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <h2>Ready to unlock the potential of your online business? </h2>
    <p className='content'>Our Shopify development services are your pathway to achieving outstanding results for your online business. The ROI you’ll experience through higher sales, lower bounce rates or better customer retention will speak volumes about our partnership. </p>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
  <img src={shopifydevelopment0} className='shopifydevelopment0' alt='' />
  </div>
  </div>
    </div>
</section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Shopifydevelopment

