import React from "react";
import Slider from "react-slick";
import bglogo1 from '../../images/bglogo1.png';
import bglogo2 from '../../images/bglogo2.png';
import bglogo3 from '../../images/bglogo3.png';
import bglogo4 from '../../images/bglogo4.png';
import bglogo5 from '../../images/bglogo5.png';
import bglogo6 from '../../images/bglogo6.png';
import bglogo7 from '../../images/bglogo7.png';
import bglogo8 from '../../images/bglogo7.png';
import bglogo9 from '../../images/bglogo9.png';


export default function Bgflyinglogos() {
  var settings = {
    speed: 4000,
    autoplay: true,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: 'linear',
    slidesToShow: 5,
    slidesToScroll: 1,
    draggable: false,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    initialSlide: 1,
    arrows: false,
    buttons: false
  };
  return (
    <div className="client-logos">
    <Slider {...settings}>
      <div className="logoslider">
      <img src={bglogo1}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo2}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo3}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo4}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo5}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo6}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo7}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo8}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo9}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo1}  className="bglogo1" alt="" />
      </div>
      <div className="logoslider">
      <img src={bglogo5}  className="bglogo1" alt="" />
      </div>
    </Slider>
    </div>
  );
}