import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';

const PoshComplaince = () => {


  return (
    <>
      <NavbarV3 />
      <Helmet>
        <title>Posh Compliance - Whizamet</title>
      </Helmet>
      <section className='poshbanner'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <div className='poshcontentbg1'>
                <h1>POLICY ON PREVENTION OF SEXUAL< br /> HARASSMENT OF WOMEN (POSH)</h1>
                <h2>AT</h2>
                <p className='poshtext'>WHIZAMET “Whizamet Services Pvt. Ltd.”</p>

              </div>
            </div>
          </div>

        </div>
        <div className='left-seoshape1'></div>

      </section>
      <section className='posh1'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <h3 className='boldposh'>Sexual Harassment At The Workplace Results In Violation Of The Fundamental Rights Of A Woman</h3>
              <div className="elementor-widget-container">
                -To equality under Articles 14 and 15 of the Constitution of India
                <br />
                -To life and to live with dignity under article 21 of the Constitution
                <br />
                -To practice any profession or to carry on any occupation, trade or business
                which includes a right to a safe environment free from sexual harassment.{" "}
              </div>
              <div className="elementor-widget-container">
                Protection against sexual harassment and the right to work with dignity are
                universally recognized human rights by international conventions and
                instruments such as Convention on the Elimination of all Forms of
                Discrimination against Women, which has been ratified on 25 June 1993 by the
                Government of India. The WHIZAMET is an equal employment opportunity company
                and is committed to creating a healthy working environment that enables
                employees to work without fear of prejudice, gender bias and in a harassment
                free workplace to all employees without regard to race, caste, religion,
                color, ancestry, marital status, gender, age, nationality, ethnic origin or
                disability. The Company also believes that all employees of the Company have
                the right to be treated with dignity. Sexual harassment at the work place or
                other than work place if involving an employee or employees is a grave
                offence and is therefore, punishable This Policy extends to all employees of
                WHIZAMET and any other company which may be incorporated in future with
                applicability of WHIZAMET policies – hereinafter referred to as ‘Company’ /
                ‘Organization’ in this policy. It is deemed to be incorporated in the
                service conditions of all employees of the Company in India. Local country
                laws will take precedence over this policy, in other geographies, if
                applicable.
              </div>
              <h5 className="elementor-heading-title elementor-size-default">
                1. Introduction
              </h5>
              <div class="elementor-widget-container">
                <p className='stext'>At WHIZAMET, all employees are expected to uphold the highest standards of ethical conduct at the workplace and in all their interactions with business stakeholders. This means that employees have a responsibility to</p></div>
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-ea30a17 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="ea30a17"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      Sexual harassment at the workplace results in violation of the
                      fundamental rights of a woman
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-a9929f5 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="a9929f5"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    -to equality under Articles 14 and 15 of the Constitution of India
                    <br />
                    -to life and to live with dignity under article 21 of the Constitution
                    <br />
                    -to practice any profession or to carry on any occupation, trade or
                    business which includes a right to a safe environment free from sexual
                    harassment.{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3f33b97 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="3f33b97"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    Protection against sexual harassment and the right to work with dignity
                    are universally recognized human rights by international conventions and
                    instruments such as Convention on the Elimination of all Forms of
                    Discrimination against Women, which has been ratified on 25 June 1993 by
                    the Government of India. The WHIZAMET is an equal employment opportunity
                    company and is committed to creating a healthy working environment that
                    enables employees to work without fear of prejudice, gender bias and in
                    a harassment free workplace to all employees without regard to race,
                    caste, religion, color, ancestry, marital status, gender, age,
                    nationality, ethnic origin or disability. The Company also believes that
                    all employees of the Company have the right to be treated with dignity.
                    Sexual harassment at the work place or other than work place if
                    involving an employee or employees is a grave offence and is therefore,
                    punishable This Policy extends to all employees of WHIZAMET and any
                    other company which may be incorporated in future with applicability of
                    WHIZAMET policies – hereinafter referred to as ‘Company’ /
                    ‘Organization’ in this policy. It is deemed to be incorporated in the
                    service conditions of all employees of the Company in India. Local
                    country laws will take precedence over this policy, in other
                    geographies, if applicable.
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-023f788 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="023f788"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      This version of the policy is effective from 1st February 2023 and
                      supersedes all prior policies and communication on this matter.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-a3c7931 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="a3c7931"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      1. Introduction
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-33c1ce5 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="33c1ce5"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      At WHIZAMET, all employees are expected to uphold the highest
                      standards of ethical conduct at the workplace and in all their
                      interactions with business stakeholders. This means that employees
                      have a responsibility to
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-40c7b0a exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="40c7b0a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <ul>
                      <li>Treat each other with dignity and respect</li>
                      <li>Follow the letter and spirit of law</li>
                      <li>
                        Refrain from any unwelcome behavior that has sexual connotation (of
                        sexual nature)
                      </li>
                      <li>
                        Refrain from creating hostile atmosphere at workplace via sexual
                        harassment
                      </li>
                      <li>
                        Report sexual harassment experienced and/or witnessed to appropriate
                        authorities and abide by the complaint handling procedure of the
                        company.
                      </li>
                    </ul>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-504048b exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="504048b"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      This policy has been framed in accordance with the provisions of “The
                      Sexual Harassment of Women at Workplace (Prevention, Prohibition and
                      Redressal) Act, 2013” and rules framed there under (hereinafter “the
                      Act”). Accordingly, while the policy covers all the key aspects of the
                      Act, for any further clarification reference shall always be made to
                      the Act and the provisions of the Act shall prevail. If any aspect
                      relating to sexual harassment not explicitly covered in this policy is
                      provided for by the law, then the law will be applicable. In case of
                      any conflict between the policy and the law, the law will prevail.
                    </p>
                    <p className='stext'>
                      This policy provides protection against sexual harassment of women at
                      workplace and the prevention and redressal of complaints of sexual
                      harassment and matters related to it.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-d3e4ffc exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="d3e4ffc"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      2. Scope
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e436921 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="e436921"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      This Policy extends to all employees of the Company and is deemed to
                      be incorporated in the service conditions of all employees of the
                      Company in India. Local country laws will take precedence over this
                      policy, in other geographies, if applicable.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7660ba3 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="7660ba3"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      3. Definitions
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-30df16d exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="30df16d"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      <strong>
                        Sexual harassment may occur not only where a person uses sexual
                        behavior to control, influence or affect the career, salary or job
                        of another person, but also between co-workers. It may also occur
                        between an employee and someone that employee deals with in the
                        course of his/her work who is not employed by the Company.
                      </strong>
                    </p>
                    <ul>
                      <li>
                        “Sexual Harassment” includes any one or more of the following
                        unwelcome acts or behavior (whether directly or by implication), but
                        not limited to:
                      </li>
                    </ul>
                    <ol>
                      <li>
                        1<strong>. </strong>
                        <em>
                          Any unwelcome sexually determined behavior, or pattern of conduct,
                          that would cause discomfort and/or humiliate a person at whom the
                          behavior or conduct was directed namely:
                        </em>
                      </li>
                      <li>
                        Unwelcome sexual advances involving verbal, non-verbal, or physical
                        conduct, implicit or explicit
                      </li>
                      <li>
                        Physical contact and advances including (but not limited to)
                        touching, stalking, sounds which have explicit and /or implicit
                        sexual connotation/overtones, molestation
                      </li>
                      <li>
                        Teasing, Voyeurism, innuendos and taunts with implicit sexual
                        connotation, physical confinement and /or touching against one’s
                        will
                      </li>
                      <li>Demand or request for sexual favors</li>
                      <li>
                        Sexually colored remarks or remarks of a sexual nature about a
                        person’s clothing or body
                      </li>
                      <li>
                        Display of pictures, signs etc. with sexual nature/ connotation/
                        overtones in the work area and work-related areas
                      </li>
                      <li>
                        Showing pornography, making or posting vulgar / indecent / sexual
                        pranks, teasing, jokes, demeaning or offensive pictures, cartoons or
                        other materials through email, SMS, MMS, gestures etc.
                      </li>
                      <li>
                        Repeatedly asking to socialize during off-duty hours or continued
                        expressions of sexual interest against a person’s wishes
                      </li>
                      <li>Giving gifts or leaving objects that are sexually suggestive</li>
                      <li>
                        Eve teasing, innuendos and taunts, physical confinement against
                        one’s will or any such act likely to intrude upon one’s privacy;
                        Persistent watching, following, contacting of a person; and
                      </li>
                      <li>
                        Any other unwelcome physical, verbal or non-verbal conduct of sexual
                        nature
                      </li>
                      <li>
                        <em>
                          The following circumstances if it occurs or is present in relation
                          to any sexually determined act or behavior amount to sexual
                          harassment:
                        </em>
                      </li>
                      <li>
                        Implied or explicit promise of preferential treatment in employment;
                      </li>
                      <li>
                        Implied or explicit threat of detrimental treatment in employment;
                      </li>
                      <li>
                        Implied or explicit threat about the present or future employment
                        status;
                      </li>
                      <li>
                        Interference with the person’s work or creating an intimidating or
                        offensive or hostile work environment; or
                      </li>
                      <li>Humiliating treatment likely to affect health or safety.</li>
                    </ol>
                    <p className='stext'>
                      <em>&nbsp;</em>
                    </p>
                    <ol start={3}>
                      <li>
                        <em>
                          An alleged act of sexual harassment committed during or outside of
                          office hours falls under the purview of this policy. Further, it
                          is important to note that whether harassment has occurred or not
                          does not depend on the intention of the people but on the
                          experience of the aggrieved woman.
                        </em>
                      </li>
                    </ol>
                    <ul>
                      <li>
                        Aggrieved woman: In relation to a workplace, a woman, of any age,
                        whether employed or not, who alleges to have been subjected to any
                        act of sexual harassment by the respondent.
                      </li>
                      <li>
                        Complainant: Any aggrieved woman who makes a complaint alleging
                        sexual harassment under this policy
                      </li>
                      <li>
                        Respondent: A person against whom a complaint of sexual harassment
                        has been made by the aggrieved woman under this policy
                      </li>
                      <li>
                        Employee: A person employed at the workplace, for any work on
                        regular, temporary, ad-hoc or daily wage basis, either directly or
                        through an agent, including a contractor, with or without the
                        knowledge of the principal employer, whether for remuneration or
                        not, or working on a voluntary basis or otherwise, whether the terms
                        of employment are express or implied and includes a coworker, a
                        contract worker, probationer, trainee, apprentice or called by any
                        other such name.
                      </li>
                      <li>
                        Special Educator: A Special Educator means a person trained in
                        communication with people with special needs in a way that addresses
                        their individual differences and needs.
                      </li>
                    </ul>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-0eb0466 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="0eb0466"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      Workplace:
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-163427a exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="163427a"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <ul>
                      <li>
                        Premises, locations, establishments, enterprises, institutions,
                        offices, branches or units established, subsidiaries which are
                        controlled by the Company.
                      </li>
                      <li>
                        Places visited by the Employee arising out of or during the course
                        of employment including official events, accommodation and
                        transportation provided by the employer for undertaking a journey.
                      </li>
                      <li>
                        Employer: A person responsible for management, supervision and
                        control of the workplace
                      </li>
                    </ul>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-5eb6615 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="5eb6615"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      4. Roles and Responsibilities
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-3ac0514 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="3ac0514"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      <strong>
                        It is the responsibility of all to respect the rights of others and
                        to never encourage harassment. It can be done by:
                      </strong>
                    </p>
                    <ol>
                      <li>
                        Refusing to participate in any activity which constitutes harassment
                      </li>
                      <li>Supporting the person to reject unwelcome behavior</li>
                      <li>
                        Acting as a witness if the person being harassed decides to lodge a
                        complaint
                      </li>
                    </ol>
                    <ol start={5}>
                      <li>Redressal Mechanism – Formal Intervention</li>
                    </ol>
                    <p className='stext'>
                      In compliance with the Act, any complaint under this policy shall be
                      followed by a formal redressal mechanism as described in this Policy.
                    </p>
                    <p className='stext'>&nbsp;</p>
                    <ol>
                      <li>
                        Internal Complaints Committee (Henceforth known as ‘committee’)
                      </li>
                    </ol>
                    <p className='stext'>
                      To prevent instances of sexual harassment and to receive and
                      effectively deal with complaints pertaining to the same, Internal
                      Complaints Committees (IC) have been appointed for the company. The
                      detail of the committee is notified to all covered persons at the
                      location (workplace). The committee at each location comprises of:
                    </p>
                    <ol>
                      <li>
                        Presiding Officer: A woman employed at a senior level in the
                        organization or workplace
                      </li>
                      <li>
                        At least 2 members from amongst employees committed to the cause of
                        women or who have had experience of social work or have legal
                        knowledge
                      </li>
                      <li>
                        One external member from amongst non-governmental organizations or
                        associations committed to the cause of women or a person familiar
                        with the issues relating to sexual harassment
                      </li>
                      <li>At least one half of the total members nominated being women</li>
                    </ol>
                    <p className='stext'>The committee will be responsible for:</p>
                    <ol>
                      <li>Receiving complaints of sexual harassment at the workplace</li>
                      <li>
                        Initiating and conducting inquiry as per the established procedure
                      </li>
                      <li>Submitting findings and recommendations of inquiries</li>
                      <li>
                        Coordinating with the employer in implementing appropriate action
                      </li>
                      <li>
                        Maintaining strict confidentiality throughout the process as per
                        established guidelines
                      </li>
                      <li>Submitting annual reports in the prescribed format</li>
                    </ol>
                    <ol>
                      <li>Committee Members</li>
                    </ol>
                    <p className='stext'>
                      Internal Complaint Committees have been formulated the details about
                      the current members of the IC is enclosed in Annexure A.
                    </p>
                    <ol>
                      <li>Lodging a Complaint</li>
                    </ol>
                    <p className='stext'>
                      An aggrieved woman may make, in writing, a complaint of sexual
                      harassment at workplace to the Internal Committee within a period of 3
                      months from the date of incident/ last incident. The Internal
                      Committee can extend the timeline by another 3 months for reasons
                      recorded in writing, if satisfied that these reasons prevented the
                      lodging of the complaint within the period.
                    </p>
                    <p className='stext'>
                      Provided that where such a complaint cannot be made in writing, the
                      Presiding Officer or any Member of the Internal Complaint Committee
                      shall render all reasonable assistance to the women for making the
                      complaint in writing.
                    </p>
                    <ol>
                      <li>
                        If the Aggrieved Woman is unable to make a complaint on account of
                        her physical incapacity, a complaint may be filed by:
                      </li>
                      <li>her relative or friend; or</li>
                      <li>her co-worker; or</li>
                      <li>
                        an officer of the National Commission for Women or State Women’s
                        Commission; or
                      </li>
                      <li>
                        any person who has knowledge of the incident, with the written
                        consent of the Aggrieved Woman
                      </li>
                    </ol>
                    <ol start={2}>
                      <li>
                        If the Aggrieved Woman is unable to make a complaint on account of
                        her mental incapacity, a complaint may be filed by:
                      </li>
                      <li>her relative or friend; or</li>
                      <li>a special educator; or</li>
                      <li>a qualified psychiatrist or psychologist; or</li>
                      <li>
                        the guardian or authority under whose care she is receiving
                        treatment or care; or
                      </li>
                      <li>
                        any person who has knowledge of the incident jointly with the
                        Aggrieved Woman’s relative or friend or a special educator or
                        qualified psychiatrist or psychologist, or guardian or authority
                        under whose care she is receiving treatment or care
                      </li>
                    </ol>
                    <ol start={3}>
                      <li>
                        If the Aggrieved Woman for any other reason is unable to make a
                        complaint, a complaint may be filed by any person who has knowledge
                        of the incident, with her written consent.
                      </li>
                      <li>
                        If the Aggrieved Woman is deceased, a complaint may be filed by any
                        person who has knowledge of the incident, with the written consent
                        of her legal heir.
                      </li>
                    </ol>
                    <p className='stext'>
                      The complaint shall be submitted by the complainant to the IC in
                      writing or shall be submitted to the IC electronically at{" "}
                      <a href="mailto:internalcommitte@whizamet.com">
                        internalcommitte@whizamet.com,
                      </a>{" "}
                      The complaint can also be physically submitted to any IC member.
                    </p>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-93063dc exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="93063dc"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <ol>
                      <li>Receiving a Complaint (guidelines)</li>
                    </ol>
                    <p className='stext'>
                      Dealing with incidents of harassment is not like any other type of
                      dispute. Complainants may be embarrassed and distressed and it
                      requires tact and discretion while receiving the complaint.
                    </p>
                    <p className='stext'>
                      The following points are to be kept in mind by the receiver of the
                      complaint:
                    </p>
                    <p className='stext'>
                      –&nbsp; Complaints are listened to and the complainant informed that
                      the Company takes the concerns seriously. Complainant is informed that
                      these concerns will be reported to the appropriate committee and
                      follow up will be done speedily.
                    </p>
                    <p className='stext'>
                      –&nbsp; Situations are not be pre-judged. Written notes are to be
                      taken while listening to the person. When taking notes, complainants
                      own words, where possible, are to be used accurately. Clear
                      description of the incident in simple and direct terms is prepared and
                      details are confirmed with the complainant.
                    </p>
                    <p className='stext'>
                      –&nbsp; &nbsp;All notes are kept strictly confidential. Complainant’s
                      agreement is taken to allow proceeding with the matter, which involves
                      a formal investigation.
                    </p>
                    <p className='stext'>
                      –&nbsp; The complainant is advised that although the process is
                      confidential, the respondent needs to be informed and any witnesses
                      and persons directly involved in the complaint process will also learn
                      of the complainant’s identity
                    </p>
                    <p className='stext'>
                      Care is taken to prevent any disadvantage to or victimization of
                      either the complainant or the respondent.
                    </p>
                    <ol>
                      <li>Resolution procedure through conciliation</li>
                    </ol>
                    <p className='stext'>
                      Once the complaint is received, before initiating the inquiry, the
                      committee may take steps to conciliate the complaint between the
                      complainant and the respondent. This is only if requested by the
                      aggrieved woman.
                    </p>
                    <p className='stext'>No monetary settlement can be made as a basis of conciliation.</p>
                    <p className='stext'>
                      In case a settlement is arrived at, the committee records &amp;
                      reports the same to the employer for taking appropriate action.
                      Resolution through conciliation is to happen within 2 weeks of receipt
                      of complaint.
                    </p>
                    <p className='stext'>
                      &nbsp;The committee shall provide copies of the settlement to
                      complainant &amp; respondent. Where a settlement is arrived at, no
                      further inquiry is to be conducted by the committee.
                    </p>
                    <ol>
                      <li>Resolution procedure through formal inquiry</li>
                    </ol>
                    <p className='stext'>The committee will initiate inquiry in the following cases:</p>
                    <ul>
                      <li>No conciliation is requested by aggrieved woman</li>
                      <li>Conciliation has not resulted in any settlement</li>
                      <li>
                        Complainant informs the committee that any term or condition of the
                        settlement arrived through conciliation, has not been complied with
                        by respondent
                      </li>
                    </ul>
                    <ol>
                      <li>Manner and Procedure of inquiry into complaint:</li>
                    </ol>
                    <ul>
                      <li>
                        Complainant should submit the complaint along with supporting
                        documents and the names of the witnesses, if any.
                      </li>
                      <li>
                        The Committee will hold a meeting with the Complainant within seven
                        days of receipt of the complaint, but no later than a week in any
                        case.
                      </li>
                      <li>
                        At the first meeting, the Committee members shall hear the
                        Complainant and record her allegations. The Complainant can also
                        submit any corroborative material with a documentary proof, oral or
                        written material, etc., to substantiate her complaint.
                      </li>
                      <li>
                        The Committee shall proceed with the enquiry and communicate the
                        same to the Complainant and Respondent.
                      </li>
                      <li>
                        Upon receipt of the complaint, the committee will send 1 copy of the
                        complaint to Respondent within 7 working days of receiving the
                        complaint.
                      </li>
                      <li>
                        Respondent shall reply with all supporting documents within 10
                        working days of receiving the copy of the complaint.
                      </li>
                      <li>
                        Thereafter, the Respondent may be called for a deposition before the
                        Committee and an opportunity will be given to him to give an
                        explanation, where after, an inquiry shall be conducted and
                        concluded.
                      </li>
                      <li>
                        The Complainant shall be provided with a copy of the written
                        explanation submitted by the Respondent.
                      </li>
                      <li>
                        If the Complainant or the respondent desires any witness/es to be
                        called, they shall communicate in writing to the Committee the names
                        of witness/es that they propose to call.
                      </li>
                      <li>
                        The Committee shall call upon all witnesses mentioned by both the
                        parties.
                      </li>
                      <li>
                        The Committee shall provide every reasonable opportunity to the
                        Complainant and the Respondent for putting forward and defending
                        their respective case.
                      </li>
                      <li>
                        If either party desires to tender any documents by way of evidence
                        before the Committee, the same is to be supplied as original copies
                        of such documents. Signatures should be affixed on the respective
                        documents to certify these to be original copies.
                      </li>
                      <li>
                        No legal practitioner can represent any party at any stage of the
                        inquiry procedure
                      </li>
                      <li>
                        The Complaints Committee is to make inquiry into the complaint in
                        accordance with the principles of natural justice
                      </li>
                      <li>
                        In conducting the inquiry, a minimum of three committee members
                        including the Presiding Officer are to be present
                      </li>
                      <li>
                        The employer shall provide all necessary assistance for the purpose
                        of ensuring full, effective and speedy implementation of this policy
                      </li>
                      <li>
                        Where sexual harassment occurs as a result of an act or omission by
                        any third party or outsider, the company shall take all steps
                        necessary and reasonable to assist the affected person in terms of
                        support and preventive action.
                      </li>
                      <li>
                        In the event, the complaint does not fall under the purview of
                        Sexual Harassment or the complaint does not mean an offence of
                        Sexual Harassment, the same would be dropped after recording the
                        reasons thereof.
                      </li>
                    </ul>
                    <p className='stext'>
                      If the complainant or respondent desires to cross examine any
                      witnesses, the Committee facilitates the same and records the
                      statements. In case complainant or respondent seeks to ask questions
                      to the other party, they may give them to the Committee which asks
                      them and records the statement of the other party.
                    </p>
                    <p className='stext'>
                      Any such inquiry is completed, including the submission of the Inquiry
                      Report, within 90 days from the date on which the inquiry is
                      commenced. The inquiry procedure should ensure absolute fairness to
                      all parties.
                    </p>
                    <ol start={2}>
                      <li>Interim relief</li>
                    </ol>
                    <p className='stext'>
                      During pendency of the inquiry, on a written request made by the
                      complainant, the committee may recommend to the employer to:
                    </p>
                    <ul>
                      <li>
                        Transfer the complainant or the respondent to any other workplace
                      </li>
                      <li>
                        Grant leave to the aggrieved woman of maximum 3 months, in addition
                        to the leave she would be otherwise entitled
                      </li>
                      <li>
                        Prevent the respondent from assessing complainant’s work performance
                      </li>
                      <li>Grant such other relief as may be appropriate</li>
                    </ul>
                    <p className='stext'>
                      Once the recommendations of interim relief are implemented, the same
                      is informed to the committee.
                    </p>
                    <ol start={3}>
                      <li>Termination of Inquiry</li>
                    </ol>
                    <p className='stext'>
                      Committee may terminate the inquiry or give ex-parte decision, if
                      complainant or respondent respectively is absent for 3 consecutive
                      hearings, without sufficient reason. 15 day written notice is to be
                      given to the party, before termination of enquiry or ex-parte order.
                    </p>
                    <ol start={4}>
                      <li>Action to be taken after inquiry</li>
                    </ol>
                    <p className='stext'>
                      Post the inquiry, the committee submits its report containing the
                      findings and recommendations to the employer, within 10 days of
                      completion of the inquiry.
                    </p>
                    <ol start={5}>
                      <li>Complaint unsubstantiated</li>
                    </ol>
                    <p className='stext'>
                      Where the committee arrives at the conclusion that the allegation
                      against the respondent has not been proved, it recommends to the
                      employer that no action is required to be taken in this matter.
                      Further, the committee ensures that both parties understand that the
                      matter has been fully investigated, that the matter is now concluded,
                      and neither will be disadvantaged within the Company.
                    </p>
                    <ol start={6}>
                      <li>Complaint substantiated</li>
                    </ol>
                    <p className='stext'>
                      Where the committee arrives at the conclusion that the allegation
                      against the respondent has been proved, it recommends to the employer
                      to take necessary action for sexual harassment as misconduct, in
                      accordance with the applicable service rules and policies, and this
                      may include:
                    </p>
                    <ol>
                      <li>Counseling</li>
                      <li>Censure or reprimand</li>
                    </ol>
                    <p className='stext'>iii.&nbsp; Apology to be tendered by respondent</p>
                    <ol>
                      <li>Written warning</li>
                      <li>Withholding promotion and/or increments</li>
                      <li>Suspension</li>
                    </ol>
                    <p className='stext'>vii.&nbsp; Termination</p>
                    <p className='stext'>viii.&nbsp; Or any other action that the employer may deem fit.</p>
                    <p className='stext'>
                      The Company is required to act upon the recommendations within 60 days
                      and confirm to the committee. Post implementation of the actions,
                      follow up with the complainant should also occur to ascertain whether
                      the behavior has in fact stopped, the solution is working
                      satisfactorily and if no victimization of either party is occurring.
                    </p>
                    <ol start={7}>
                      <li>Penal Consequences of Sexual Harassment</li>
                    </ol>
                    <p className='stext'>
                      In case the Committee finds the degree of offence coverable under the Bharatiya Nyaya Sanhita, then this fact shall be mentioned in its report and appropriate action shall be initiated by the employer, for making a Police Complaint. Under the Bharatiya Nyaya Sanhita, (BNS), Section 76 deals with Sexual Harassment has made this a ‘cognizable offense’ i.e. a person charged with Sexual Harassment may be arrested without a warrant.
                    </p>
                    <ol start={8}>
                      <li>Malicious Allegations</li>
                    </ol>
                    <p className='stext'>
                      Where the committee arrives at the conclusion that the allegation
                      against the respondent is malicious or the aggrieved woman or any
                      other person making the complaint has made the complaint knowing it to
                      be false or the aggrieved woman or any other person making the
                      complaint has produced any forged or misleading document, it may
                      recommend to the employer to take action against the woman or the
                      person making the complaint.
                    </p>
                    <p className='stext'>
                      While deciding malicious intent, the committee should consider that
                      mere inability to substantiate a complaint need not mean malicious
                      intent. Malicious intent must be clearly established through a
                      separate inquiry.
                    </p>
                    <ol start={9}>
                      <li>
                        <strong>Confidentiality</strong>
                      </li>
                    </ol>
                    <p className='stext'>
                      The identity of the complainant, respondent, witnesses, statements and
                      other evidence obtained in the course of inquiry process,
                      recommendations of the committees, action taken by the employer is
                      considered as confidential materials, and not published or made known
                      to public or media.
                    </p>
                    <p className='stext'>
                      Any person contravening the confidentiality clauses is subject to
                      disciplinary action as prescribed in the act.
                    </p>
                    <ol start={10}>
                      <li>
                        <strong>Appeal</strong>
                      </li>
                    </ol>
                    <p className='stext'>
                      Any party not satisfied or further aggrieved by the implementation or
                      non-implementation of recommendations made, may appeal to the
                      appellate authority in accordance with the Act, within 90 days of the
                      recommendations being communicated.
                    </p>
                    <ol start={11}>
                      <li>
                        <strong>Awareness</strong>
                      </li>
                    </ol>
                    <p className='stext'>Awareness sessions are to be organized to:</p>
                    <ul>
                      <li>
                        Formulate and widely disseminate an internal policy or charter or
                        resolution or declaration for prohibition, prevention and redressal
                        of sexual harassment at the workplace intended to promote gender
                        sensitive safe spaces and remove underlying factors that contribute
                        towards a hostile work environment against women.
                      </li>
                      <li>
                        Carry out orientation programs and seminars for the Members of the
                        IC.
                      </li>
                      <li>
                        Conduct capacity building and skill building programs for the
                        Members of the IC.
                      </li>
                      <li>
                        Declare the names and contact details of all the Members of the IC.
                      </li>
                      <li>
                        Use modules developed by the State Governments to conduct workshops
                        and awareness programs for sensitizing the employees with the
                        provisions of the Act.
                      </li>
                    </ul>
                    <ol start={12}>
                      <li>
                        <strong>Legal Compliance</strong>
                      </li>
                    </ol>
                    <p className='stext'>
                      The IC shall in each calendar year prepare, is such format as may be
                      prescribed, an annual report and submit the same to the employer and
                      the District Officer (as defined in the Act). The report shall have
                      the following details:
                    </p>
                    <ul>
                      <li>
                        Number of complaints of Sexual harassment received in the year
                      </li>
                      <li>Number of complaints disposed of during the year</li>
                      <li>Number of cases pending for more than 90 days</li>
                      <li>
                        Number of workshops or awareness program against Sexual Harassment
                        carried out
                      </li>
                      <li>Nature of action taken by the employer or District Officer</li>
                    </ul>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-4e6f91d exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading"
                  data-id="4e6f91d"
                  data-element_type="widget"
                  data-widget_type="heading.default"
                >
                  <div className="elementor-widget-container">
                    <h5 className="elementor-heading-title elementor-size-default">
                      ANNEXURE A
                    </h5>{" "}
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-9e4d8db exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor"
                  data-id="9e4d8db"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container">
                    <p className='stext'>
                      <strong>
                        A. Constitution of Internal Complaints Committee (IC):
                      </strong>
                    </p>
                    <ol>
                      <li>
                        Presiding Officer – Angel Mahendru, ,{" "}
                        <a href="mailto:angel@whizamet.com">angel@whizamet.com</a>,
                        9915446949
                      </li>
                      <li>
                        External Member – Ms Bindu,{" "}
                        <a href="mailto:aserviceproviderbindu@gmail.com">
                          serviceproviderbindu@gmail.com
                        </a>
                        , 9872271768
                      </li>
                      <li>Member – Dharamjit Singh, 7837746949</li>
                      <li>
                        Member – Anjali Bhardwaj, 9779772610,{" "}
                        <a href="mailto:aanjali.hr@whizamet.com">anjali.hr@whizamet.com</a>
                      </li>
                    </ol>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      <Footer_v1 />
    </>
  )
}

export default PoshComplaince

