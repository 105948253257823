import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import advertise from '../../images/advertise.png';
import bloging from '../../images/bloging.png';
import landingpage from '../../images/landingpage.png';
import ppc3 from '../../images/ppc3.png';
import target from '../../images/target.png';
import campaign from '../../images/campaign.png';
import goshopping from '../../images/goshopping.webp';
import googleclick from '../../images/googleclick.webp';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';

import googleadvid from '../../images/googleadvid.mp4';


const GoogleAds = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Google Ads Agency | Google Ads Consultant</title>
<meta name="description" content="Whizamet is the best Google Ads agency committed to improving your online presence. Our consultants are experts in creating data-driven campaigns that drive conversions. " />
</Helmet>
<section className='googleadsbner'>
    <div className='container'>
        <div className='row just'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Google Ads Agency</h1>
                <p className='bnrtext'>Don’t get stuck with the wrong marketing agency. As a leading Google ads agency, we excel at creating high-performing ad campaigns that will grow your business.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            {/* <img src={goshopping} className='goshoing' alt='circle' /> */}
            <video src={googleadvid}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='seovid'> </video>
            </div>
           
        </div>
    
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='googleadsserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">What we offer</h3>
     <h2> Google Ads services that get  <br />real business traffic</h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google search ads</h3>
      <p className="description">
      Our Google search ads focus on specific keywords that can attract relevant traffic and boost ROI.
      </p>
          <div className="service-icon">
          <img src={target} alt='' className='target' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google shopping ads</h3>
      <p className="description">
      With Google shopping ads, we display your products and services in Google search results so that users can shop directly from the ad.
      </p>
          <div className="service-icon">
          <img src={campaign} alt='' className='campaign' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google local service ads</h3>
      <p className="description">
      Want to target your local customers? Our Google local service ads can help you advertise your offerings to a local audience.
      </p>
          <div className="service-icon">
          <img src={advertise} alt='' className='advertise' />
           </div>
    </div>
  </div>
 </div>
           <div className="row" id='adipisicing'>
           <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google Remarketing ads </h3>
      <p className="description">
      Our remarketing ads are designed to engage you with your past visitors based on their previous interactions with your website.
      </p>
          <div className="service-icon">
          <img src={bloging} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google display ads</h3>
      <p className="description">
      We design visually appealing ads that are displayed across a vast network of websites and reach your audience when they browse.
      </p>
          <div className="service-icon">
          <img src={landingpage} alt='' className='landingpage' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Google video ads</h3>
      <p className="description">
      Looking to promote your business on YouTube and Google partner sites? We can do it for you by creating engaging videos.
      </p>
          <div className="service-icon">
          <img src={ppc3} alt='' className='localseo' />
           </div>
    </div>
  </div>
 
</div>
     
</div>
</section>
<section className='googlecontent'>
   <div className='container'>
  <div className='row'>
  <h2>Get 2x more leads, conversions and <span className="slimtext">ROI with Google Ads</span></h2>
<div className='col-xxl6 col-xl-6 col-lg-6 col-md-12 col-12'id='text50'>
<p className='content'> Do you want to attract more leads and generate higher ROI for your business? Google ads are the most powerful tool that can help you enhance your online presence. With Google ads, your business can reach millions of customers across the globe, precisely target potential audiences and significantly increase your website traffic. Also, Google ads empower our clients by providing them with full control over their advertising budgets. They can adjust their spending based on their individual needs and goals.</p>
  </div>
    <div className='col-xxl6 col-xl-6 col-lg-6 col-md-12 col-12'id='text50'>
    <p className='content'>With our Google ads services, businesses can expand their reach into new markets. Our team combines the latest marketing strategies, consistent optimization and passionate commitment to performance to ensure that you get maximum results from your ad spend.We also provide comprehensive reports that outline the performance of your KPIs. By collaborating with us, you’ll get increased brand visibility, better CTR and higher conversions.</p>
 
    </div>
  </div>
</div>
</section>
<section className='googleadsvalue'>
    <div className='container'>
        <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12' id='pc-first'>
                  <h2 className='thrive'>A hands-on agency that builds <br />your brand with Google</h2> 
              <p class="content-wh">Our Google ad services takes the burden off your shoulders right from the initial research to constant improvement.</p>
              </div>
               </div>
               
 
           <div className='row'>
           <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                   <h4 className='chat-wh'>Customized solutions</h4>
                   <p>Our every campaign is tailored to individual objectives and budgets.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                    <h4 className='chat-wh'>Expert team</h4>
                   <p>We have years of experience in running successful campaigns across varied industries.</p>
                    </div>
                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
                    <div className='gadsbox'>
                    <h4 className='chat-wh'>Regular campaign monitoring</h4>
                   <p>You get a team that monitors your marketing campaigns daily.</p>
                    </div>
                </div>
               </div>
               </div>  
             </section>
 <section className='googleclick'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
      <div className='gd-cta'>
        <h2>Get maximum clicks with <span className="slimtext">minimum effort</span></h2>
        <p className='content'>Our experts will handle your workload, so you can focus on what matters the most. We have the expertise to take your business to new heights. You don’t have to waste time searching for relevant keywords or designing ad campaigns- we will take care of everything.</p>
         <img src={googleclick} className='googleclick' alt='circle' />
      </div>
    </div>
  </div>
</div>
   </section>
   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default GoogleAds

