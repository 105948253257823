import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import grtool2 from '../../images/grtool2.png';
import AffordableServices from '../../images/AffordableServices.png';
import BespokeServices from '../../images/BespokeServices.png';
import ExceptionalDesigns from '../../images/ExceptionalDesigns.png';
import ImpactfulVisuals from '../../images/ImpactfulVisuals.png';
import innovative from '../../images/innovative.png';
import faqvideo from '../../images/faqvideo.mp4';
import QuickTurnaround from '../../images/QuickTurnaround.png';
import SimplifiedGraphics from '../../images/SimplifiedGraphics.png';
import UnlimitedRevisions from '../../images/UnlimitedRevisions.png';
import question from '../../images/question.png';
import Faqgraphic from '../section-components/faqgraphic';

import grtool4 from '../../images/grtool4.png';
import grtool3 from '../../images/grtool3.png';
import grtool5 from '../../images/grtool5.png';
import grtool1 from '../../images/grtool1.png';
import grtool6 from '../../images/grtool6.png';
import grtool7 from '../../images/grtool7.png';
import grtool8 from '../../images/grtool8.png';
import grtool9 from '../../images/grtool9.png';
import grtool10 from '../../images/grtool10.png';
import grtool11 from '../../images/grtool11.png';
import grtool12 from '../../images/grtool12.png';
import logodesign from '../../images/logodesign.png';
import viscard from '../../images/viscard.png';
import smpost from '../../images/smpost.jpg';

import Graphictools from '../section-components/graphictools';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';
import Graphicslider from '../section-components/graphicslider';

const Graphicdesigning = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Graphic Design Services | Graphic Design Agency</title>
<meta name="description" content="Looking for the best graphic design agency? Whizamet offers a broad range of graphic design services to improve your business's visual identity. Contact us now." />
</Helmet>
<section className='seobner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
            <div className='seocontentbg1'>
            <h1>Graphic Designing</h1>
                <p className='bnrtext'>Make your branding hit the right spot with our creative graphic design solutions.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <Graphictools />
        </div>
     
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>


<section className='graphic'>
<div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className=''>
        <h3 class="mainsubheading">What we do</h3>
     <h2>Services that make your brand<br /> presence unique</h2>
     </div>
        </div>
            </div>
            <Graphicslider />
</div>
</section>

{/* <section className='graphic'>
<div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">What we do</h3>
     <h2>Services that make your brand<br /> presence unique</h2>
     </div>
        </div>
            </div>
 <div className='row'>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Logo design</h3>
            <p> Our logo designs reflect the hidden qualities of your brand- its essence, personality and emotions.</p>
            <img src={graphic1} className='graphic1' alt='' />
        </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Brochure design</h3>
            <p>We offer a comprehensive range of brochure design services that effectively communicate your business to your customers.</p>
            <img src={graphic2} className='graphic1' alt='' />
        </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Social media creatives design</h3>
            <p>Get unique designs for your social media channels that distinguish you from your competitors.</p>
            <img src={graphic3} className='graphic1' alt='' />
        </div>
    </div>
 </div>
 <div className='row'>
 <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Packaging design</h3>
            <p>Our designers design impactful and meticulously designed packaging that creates an everlasting impression.</p>
            <img src={graphic4} className='graphic1' alt='' />
        </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Banner design</h3>
            <p>Banner designs from Whizamet will convey your brand message and ensure a positive interaction.</p>
            <img src={graphic5} className='graphic1' alt='' />
        </div>
    </div>
    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='graphic-box'>
            <h3>Business card design</h3>
            <p>We believe in making your first impression count. Our expert designers craft customized cards that reflect your business essence.</p>
            <img src={graphic6} className='graphic1' alt='' />
        </div>
    </div>
 </div>
</div>
</section> */}

<section className='grphictools'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-4 col-md-6 col-12'>
             <div class="seo2-left">
             <div class="seo2-left-inner"> 
             <div>
             <h3 class="mainsubheading">Technologies we use</h3>
              <h2>Our weapons for creating <br />killer designs</h2> 
              <p class="content">Technology leads to the best results when used effectively. By harnessing the power of cutting-edge technologies, we turn visions into realities.</p>
              </div>
               </div>
                </div> 
                </div>
                <div className='col-xxl-7 col-xl-7 col-lg-8 col-md-6 col-12' id='gtool'>
                <div className='row' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={grtool2} alt='' className='localseo' />
                        <h4 className='grtool'>Adobe Illustrator</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={grtool4} alt='' className='localseo' />
                        <h4 className='grtool'>Adobe Photoshop</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={grtool3} alt='' className='localseo' />
                        <h4 className='grtool'>Photoshop Lightroom</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={grtool5} alt='' className='localseo' />
                    <h4 className='grtool'>Corel Draw</h4>
                        </div>
                    </div>
                </div>
                <div className='row' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={grtool1} alt='' className='localseo' />
                        <h4 className='grtool'>Adobe Indesign</h4>
                       
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={grtool6} alt='' className='localseo' />
                        <h4 className='grtool'>Adobe XD</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={grtool7} alt='' className='localseo' />
                        <h4 className='grtool'>Media Encoder</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={grtool8} alt='' className='localseo' />
                    <h4 className='grtool'>Adobe Audition</h4>
                        </div>
                    </div>
                </div>
                <div className='row last' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={grtool9} alt='' className='localseo' />
                        <h4 className='grtool'>Lightroom Classic</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={grtool10} alt='' className='localseo' />
                        <h4 className='grtool'>Adobe Stock</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={grtool11} alt='' className='localseo' />
                        <h4 className='grtool'>Acrobat</h4>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={grtool12} alt='' className='localseo' />
                    <h4 className='grtool'>Canva</h4>
                        </div>
                    </div>
                </div>
                </div> 
            </div>
          
        </div>
  
 </section>
 
 <section className='grakeywords'>
  <div className='container'>
    <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">Our benefits</h3>
     <h2>The features that set us apart from the rest</h2>
      </div>
    </div>
    <br />
  <div className="row ltn__custom-gutter">
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<img src={ExceptionalDesigns} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Exceptional designs</h4>
					<p>Our team of graphic designers don't sweat until they give you exceptional designs.</p>
					</div>
				</div>
				</div>
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={QuickTurnaround} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Quick turnaround</h4>
					<p>We pride ourselves on crafting great designs in minimal time.</p>
					</div>
				</div>
				</div>
				<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={UnlimitedRevisions} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Unlimited revisions</h4>
					<p>Want any edits in your design? Just let us know, and we will revise it for you.</p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={innovative} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Innovative graphics</h4>
					<p>Each design is as unique as your brand, capturing its essence and vision.</p>
					</div>
				</div>
				</div>
			</div>
            <div className="row ltn__custom-gutter">
			<div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
					<img src={SimplifiedGraphics} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Simplified graphics </h4>
					<p>We don’t make graphics that are hard to understand. Instead, we deliver messages in a straightforward manner.</p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={ImpactfulVisuals} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Impactful visuals</h4>
					<p>We create graphics that leave a lasting impression on the minds of your target audience.</p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={AffordableServices} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Affordable services</h4>
					<p>We align our growth with yours. Our services are affordable enough for you to hire.</p>
					</div>
				</div>
				</div>
                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12">
				<div className="ltn__feature-item ltn__feature-item-6">
                <img src={BespokeServices} className='ppc1' alt='' />
					<div className="ltn__feature-info">
					<h4>Bespoke services</h4>
					<p>Our services are not one-size-fits-all. We tailor our solutions to meet your unique needs.</p>
					</div>
				</div>
				</div>
			</div>    
  
    </div>
</section>
<section className='workgraphic'>
<div className="container">
<div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">Our work</h3>
     <h2>Explore our creative expertise</h2>
      </div>
    </div>

    <br></br>
  <div className="row">
    <div className="col-md-4">
      <div className="content">
             <a href="/ourportfolio">
          <div className="content-overlay" />
          <img
            className="content-image"
            src={logodesign}
          />
          <div className="content-details fadeIn-bottom">
            <h3 className="content-title">Logo Designing</h3>
                    </div>
        </a>
      </div>
    </div>
    <div className="col-md-4">
      <div className="content">
        <a href="/ourportfolio">
          <div className="content-overlay" />
          <img
            className="content-image"
            src={viscard}
          />
          <div className="content-details fadeIn-bottom">
            <h3 className="content-title">Visiting Cards</h3>
                  </div>
        </a>
      </div>
    </div>
    <div className="col-md-4">
      <div className="content">
           <a href="/ourportfolio">
          <div className="content-overlay" />
          <img
            className="content-image"
            src={smpost}
          />
          <div className="content-details fadeIn-bottom">
            <h3 className="content-title">Social Media Post</h3>
                     </div>
        </a>
      </div>
    </div>
  </div>
  <div class="fqbtn"><a class="theme-btn btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
</div>

</section>
 <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>FAQ</h3>
          <h2>Frequently Asked Questions</h2>
          <Faqgraphic />
       
        </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Graphicdesigning

