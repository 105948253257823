import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import faqvideo from '../../images/faqvideo.mp4';
import question from '../../images/question.png';
import Faqhome from '../section-components/faqhome';
import ReducedOperationalCosts from '../../images/ReducedOperationalCosts.png';
import ProactiveOutreach from '../../images/ProactiveOutreach.png';
import EnhancedCustomerSatisfaction from '../../images/EnhancedCustomerSatisfaction.png';
import Scalability from '../../images/Scalability.png';
import guestposting from '../../images/guestposting.png';
import bloging from '../../images/bloging.png';
import techsupport from '../../images/techsupport.webp';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import telephone from '../../images/telephone.png';
import tele from '../../images/tele.png';
import DispatchSupport from '../../images/DispatchSupport.png';
import InboundCustomerSupport from '../../images/InboundCustomerSupport.png';
import MultichannelSupport from '../../images/MultichannelSupport.png';
import OutboundSales from '../../images/OutboundSales.png';
import TechnicalSupport from '../../images/TechnicalSupport.png';
import tech1 from '../../images/tech1.png';
import tech2 from '../../images/tech2.png';
import tech3 from '../../images/tech3.png';
import tech4 from '../../images/tech4.png';
import Contactcenterfaq from '../section-components/Contactcenterfaq';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';

const Contactcenter = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best Contact Center Solution Provider in India</title>
<meta name="description" content="Transform customer engagement with our contact center solution. Scalable, efficient, and tailored to your business needs. Elevate your customer service experience today" />
</Helmet>
<section className='contactcenter'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Contact Center Solutions</h1>
                <p className='bnrtext'>Leverage our contact center expertise to maximize your business growth and ROI.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>

    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='contcircle' alt='circle' />
    <img src={circle} className='contcircle2' alt='circle' />
    <img src={telephone} className='telephone' alt='telephone' />
    <img src={tele} className='tele' alt='tele' />
</section>
<section className='technical contcenter'>
<div className='container'>
<div className='row'>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           <div className='emailbox'>
            <img src={ReducedOperationalCosts} alt='' className='etarget' />
            <h4 className='chat'>Cost Savings  </h4>
            <p className='cont-para'> Outsourcing contact center services can help you reduce your operational costs by 40% compared to having an in-house team. </p>
           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           <div className='emailbox pad-top1'>
            <img src={EnhancedCustomerSatisfaction} alt='' className='etarget' />
            <h4 className='chat'>Scalability </h4>
            <p className='cont-para'>Contact center services allow businesses to scale their business, leading to a 30% cost reduction related to overstaffing. </p>

           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           <div className='emailbox pad-top'>
            <img src={ProactiveOutreach} alt='' className='etarget' />
            <h4 className='chat'>Access to Expertise </h4>
            <p className='cont-para'> You get specialized expertise to handle your contact center operations, including cutting-edge infrastructure and best practices. </p>

           </div>
        </div>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
           <div className='emailbox pad-top'>
            <img src={Scalability} alt='' className='etarget' />
            <h4 className='chat'>Increased Profitability  </h4>
            <p className='cont-para'>By outsourcing your contact center operations, you can focus on core business activities and grow your business to new heights. </p>

           </div>
        </div>
       </div>
    <div className='row' id="servic">  
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
           <h3 class="mainsubheading">Our services</h3>
            <h2>Contact center solutions that improve operational efficiency </h2>
        </div>
        </div>
    </div>


<div className='row gy-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center'>
  <div className="col">
     <div class="tech-item">
     <div class="tech-inner">
     <div class="tech-img">
        <img src={InboundCustomerSupport} alt='' className='' />
     </div>
     <div class="tech-content">
        <h4 className='chat'>IT solutions </h4>
        <p className='inbound'>From Infrastructure management to web development, we offer end-to-end IT support to improve efficiency. </p>
     </div>
     </div>
     </div>
  </div>

  <div className="col">
     <div class="tech-item">
     <div class="tech-inner">
     <div class="tech-img">
        <img src={OutboundSales} alt='' className='' />
     </div>
     <div class="tech-content">
        <h4 className='chat'>Dispatch support </h4>
        <p className='inbound'>We provide reliable truck dispatch support services to manage your back office operations, saving you time and money.  </p>
     </div>
     </div>
     </div>
  </div>
  <div className="col">
     <div class="tech-item">
     <div class="tech-inner">
     <div class="tech-img">
        <img src={TechnicalSupport} alt='' className='' />
     </div>
     <div class="tech-content">
       <h4 className='chat'>Debt collection  </h4>
        <p className='inbound'>Our debt collection services optimize your debt recovery methods and help reduce the burden of bad debts. </p>
     </div>
     </div>
     </div>
  </div>
  <div className="col">
     <div class="tech-item">
     <div class="tech-inner">
     <div class="tech-img">
        <img src={MultichannelSupport} alt='' className='' />
     </div>
     <div class="tech-content">
       <h4 className='chat'>Finance & accounting</h4>
        <p className='inbound'>From bookkeeping to financial analysis, we provide comprehensive services to streamline your accounting and finance tasks. </p>
     </div>
     </div>
     </div>
  </div>
  <div className="col">
     <div class="tech-item">
     <div class="tech-inner">
     <div class="tech-img">
        <img src={DispatchSupport} alt='' className='' />
     </div>
     <div class="tech-content">
       <h4 className='chat'>Customer support </h4>
        <p className='inbound'>We handle your customer interactions with personalized responses to drive qualified leads and ensure enhanced satisfaction. </p>
     </div>
     </div>
     </div>
  </div>
 </div>

     
</div>
</section>

<section className='techsupport'>
    <div className='container'>
        <div className='row'>
        <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={techsupport} className='techspport' alt='' />
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <h3 class="mainsubheading">Boost ROI </h3>
     <h2>Unlock opportunities for growth and transformation </h2>
     <p class="content-width">Our customized contact center solutions help you concentrate on your core business activities while seizing new opportunities.
</p>
     <p class="content-width">Backed by a team of skilled agents, state-of-the-art infrastructure and cutting-edge technologies, we aim to boost your business productivity and save costs.  </p>
            </div>
          
        </div>
    </div>
</section>

<section className='pcc'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12' id='pc-first'>
             <div class="tech-left">
             <div class="seo2-left-inner"> 
             <div>
             <h3 class="mainsubheading-wh">Why choose us </h3>
              <h2 className='thrive'>We turn contact center challenges into winning opportunities </h2> 
              <p class="content-wh">At Whizamet, we provide a robust foundation for your business to excel and innovate by freeing you from mundane operational tasks. </p>
              </div>
               </div>
                </div> 
            </div>
                <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
               <div className='row gy-5 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1 justify-content-center'>
                <div className='col'>
                    <div className='ppcbox'>
                   <h4>Scalable and flexible </h4>
                   <p>Our contact center solutions are flexible to accommodate your growing needs.</p>
                    </div>
                </div>
                <div className='col'>
                    <div className='ppcbox'>
                    <h4>24/7 availability</h4>
                   <p>We offer uninterrupted support to ensure that your customers have assistance anytime,anywhere. </p>
                    </div>
                </div>
                <div className='col'>
                    <div className='ppcbox'>
                    <h4>Cutting edge technology </h4>
                   <p>We leverage advanced technology to ensure smooth operations & improve efficiency. </p>
                    </div>
                </div>
                <div className='col'>
                    <div className='ppcbox'>
                    <h4>Customized solutions </h4>
                   <p>We offer customized solutions that meet your unique goals. </p>
                    </div>
                </div>
                <div className=''>
                    <div className='ppcbox'>
                    <h4>Cost-effective</h4>
                   <p>We provide top-notch services for every business size without straining your budget. </p>
                    </div>
                </div>
               </div>

                </div> 
            </div>
          
        </div>
  
 </section>
 <section className='email-process'>
  <div className='container'>
  <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 techcenter'>
      <h3 class="mainsubheading">Our Process</h3>
     <h2>Our approach to delivering optimal solutions </h2>
      </div>
    </div>
    <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 techcenter'>
      <ul className="process">
  <li className="process__item1">
    <img src={tech1} alt='' className='' />
    <span className="process__title">Assess goals </span>
    <p className="process__subtitle1">
    We assess your current setup, goals and challenges. 
    </p>
  </li>
  <li className="process__item1">
    <img src={tech2} alt='' className='' />
    <span className="process__title">Customization  </span>
    <p className="process__subtitle1">
    We tailor our solutions to meet your unique requirements.
    </p>
  </li>
  <li className="process__item1">
    <img src={tech3} alt='' className='' />
    <span className="process__title">Implementation </span>
    <p className="process__subtitle1">
    We deploy the required infrastructure to ensure seamless operations. 
    </p>
  </li>
  <li className="process__item1">
    <img src={tech4} alt='' className='' />
    <span className="process__title">Go-live </span>
    <p className="process__subtitle1">
Then, we go live with the setup and monitor the performance consistently. 

    </p>
  </li>
</ul>
      </div>
    </div>
  </div>
 </section>
 <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
		  
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
        <Contactcenterfaq />
        
        </div>
      </div>
    </div>
   </section>
   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Contactcenter

