import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import bannnerpi from '../../images/bannnerpi.webp';
import webdesigning from '../../images/webdesigning.png';
import emailm from '../../images/emailm.png';
import speaker from '../../images/speaker.png';
import leadgen from '../../images/leadgen.png';
import logis from '../../images/logis.png';
import socail from '../../images/socail.png';
import callcenter from '../../images/callcenter.png';
import CeoVideo from '../section-components/ceo-video';
import branding from '../../images/branding.png';
import Industriesserve from '../section-components/clients/client1';
import smm from '../../images/smm.webp';
import smm2 from '../../images/smm2.webp';
import smm3 from '../../images/smm3.webp';
import smm4 from '../../images/smm4.webp';
import Faqhome from '../section-components/faqhome';
import Serivcecontactform from '../section-components/serivcecontactform';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import { Helmet } from 'react-helmet';
import Callcenter from '../section-components/Callcenter';
import Bnrdupeslider from '../section-components/bnrdupeslider';
import faqvideo from '../../images/faqvideo.mp4';


const Myhome = () => {
    return (
<>
{/* <Centerlogomenu /> */}
<NavbarV3 />
{/* <NavbarV5 /> */}


<Helmet>
<title>No. 1 Digital Marketing | SEO | B2B | PPC Agency - Whizamet</title>
<meta name="description" content="Elevate your brand with Whizamet, a top-tier digital marketing agency. Results-driven strategies, creative solutions, and unparalleled expertise for online success." />
</Helmet>

    <section className='mybanner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
            <div className='bnr-content'>
              <h3 class="mainsubheading">Online marketing experts </h3>
                <h1 className='Integrated'>We’re your Business Growth Partners </h1>
                <p className='value-driven'>Let us help you grow your brand substantially through innovative online experiences. </p>
                <div className='bnrbtn'>
                <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Get in Touch</a>
                </div>
    
                </div>
              </div>
              <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
         
              <Bnrdupeslider />
                   </div>  
                 </div>
                </div>
    <img src={bannnerpi} className='Bannnerpi' alt='' />
    </section>
    
   <section className="hsndsec" >
  <div className='container'>
  <div className='row'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 story'>
  <CeoVideo />
    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <h3 class="mainsubheading">Who we are </h3>
     <h2 className='B2B'>A bunch of digital nerds, tech wizards & growth strategists making brands thrive
</h2>
     <p className='contentright'>Whizamet is your dedicated virtual business partner, committed to growing your business through cutting-edge IT, digital marketing, and contact center solutions. We are a team of growth strategists and marketing wizards who partner with ambitious brands to elevate their digital presence.</p>
     <p className='contentright'>Our strategies are known to drive traffic, boost conversions, increase ROI and reduce costs for our clients.</p>
     <div className='ceolist'>
     <ul class="ltn__list-item-half clearfix">
     <li><i class="fa fa-line-chart"></i>Comprehensive services  </li>
     <li><i class="fa fa-bullhorn"></i>Result driven approach </li>
     <li><i class="fa fa-pie-chart"></i>Customized strategies  </li>
     <li><i class="fa fa-bar-chart"></i>Adaptability to the latest trends </li>
     </ul>
     </div>
    </div>
    
  </div>

</div>
<div className='ceo-left'>
  <h2>Our Story</h2>
</div>
   </section>
   <section className='allserivceshome'>
<div className='container'>
  <div className='row'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center mx-auto'>
        <div className='deliver'>
        <h3 className='mainsubheading'>What we do </h3>
          <h2>Solutions for every digital <br/>marketing need</h2>
          <p className='contenthome'>Our services are the catalyst for your growth in the online world. With our full-funnel solutions, we’re here to take care of your digital needs.​</p>
        </div>
        </div>
        {/* <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
       
        </div> */}
      </div>
      <div className='row' id='business'>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={speaker} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/digital-marketing">Digital Marketing</a></h4>
    <p>Be it SEO, online reputation management, social media marketing, or influencer marketing- we ace it all. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={webdesigning} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/web-development">Web Designing</a></h4>
    <p>We have expertise in cutting-edge web technologies, including Shopify, WordPress, Reach Js and many more.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={leadgen} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/ppc">Marketing ads </a></h4>
    <p>We can boost your presence through PPC, google ads, LinkedIn ads, and Facebook ads both organically & promotionally. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={logis} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/dispatch">Truck Dispatch</a></h4>
    <p>We can save you from the hassles of finding loads, negotiating rates, and paperwork so that you can focus on your business growth. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='row'>
      
    </div>
      </div>
      <div className='row' id='business2'>
      <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={callcenter} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/Contact-center-solutions">Contact Center</a></h4>
    <p>Streamline operations, drive sales, increase ROI and enhance customer satisfaction with our contact center solutions.</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={socail} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/social-media-marketing">Social Media Marketing</a></h4>
    <p>Our experts can manage your social presence across all platforms, helping you expand your reach and boost engagement.  </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={branding} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/graphic-designing">Graphic Designing</a></h4>
    <p>Whether you want a new logo, engaging videos, creative social posts or anything in between, we have you covered. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='allserhome'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={emailm} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/email-marketing">Email Marketing</a></h4>
    <p>Our email marketing experts specialize in generating qualified leads for your business with targeted email campaigns. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='row'>
      
    </div>
      </div>
    </div>
  </div>
</div>
   </section>
    {/* Callcenter section */}
 <Callcenter />
 {/* Callcenter section */}
 <div className='brand-01'>
   <section className='bottom-serivces'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
      <div className='srv-cta'> 
        <h2> Let’s talk <span className="slimtext">about your brand goals </span></h2>
        <p className='contenthome'>No matter what business goals you have, we have creative solutions for all your challenges. <br />Together, let’s shape the future of your brand. </p>
<div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
      </div>
    </div>
  </div>
</div>
   </section>
</div>


   {/* <section className='Investment'>
<div className='container'>
  <div className='row'>
  <div className='col-xxl6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <h2>No fluff marketing. Only performance-driven results </h2>
  </div>
    <div className='col-xxl6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <p className='contentright'>We deliver results that matter. We don't serve our clients with the same old “out of the box” strategies. But we do create impactful campaigns that lead to impressive results. Our every strategy, effort and campaign is designed to deliver unwavering, performance-driven results that make a real impact.  </p>
 
    </div>

    <video src={graphvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='homegraphvid'>
</video>

  </div>
</div>
   </section> */}
   {/* <section className='result'>
<div className='container'>
  <div className='row'>
<div className='circles'>
<div className="increase_area drdt-ignore-dark">
  <div
    className="increase_item wow fadeInUp drdt-ignore-dark"
    data-wow-delay="0.4s"
    style={{
      visibility: "visible",
      animationDelay: "0.4s",
      animationName: "fadeInUp"
    }}
  >
  <h5 className="mycounter">
        <CountUp
  start={1}
  end={20}
  duration={15.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  suffix="%"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
<h3 className='Revenue'>Cost Decrease </h3>
  </div>
  <div
    className="increase_item wow fadeInUp drdt-ignore-dark"
    data-wow-delay="0.5s"
    style={{
      visibility: "visible",
      animationDelay: "0.4s",
      animationName: "fadeInUp"
    }}
  >
  <h5 className="mycounter">
        <CountUp
  start={1}
  end={85}
  duration={15.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  suffix="%"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
<h3 className='Revenue'>Sales Increase</h3>
  </div>
  <div
    className="increase_item wow fadeInUp drdt-ignore-dark"
    data-wow-delay="0.6s"
    style={{
      visibility: "visible",
      animationDelay: "0.4s",
      animationName: "fadeInUp"
    }}
  > <h5 className="mycounter">
        <CountUp
  start={1}
  end={82}
  duration={15.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  suffix="%"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
<h3 className='Revenue'>Traffic Increase</h3>
  </div>
  <div
    className="increase_item wow fadeInUp drdt-ignore-dark"
    data-wow-delay="0.7s"
    style={{
      visibility: "visible",
      animationDelay: "0.4s",
      animationName: "fadeInUp"
    }}
  >
    <h5 className="mycounter">
        <CountUp
  start={1}
  end={90}
  duration={15.75}
  separator=" "
  decimals={0}
  decimal=","
  // prefix="EUR "
  suffix="%"
  onEnd={() => console.log('Ended! 👏')}
  onStart={() => console.log('Started! 💨')}
></CountUp>
</h5>
<h3 className='Revenue'>Revenue Increase</h3>
  </div>
</div>

</div>
  </div>
</div>
   </section> */}

{/* <section>
  <div className='container'>
    <div className='row'>
    <video src={graphvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='pseudo-partial-border'>
</video>
    </div>
  </div>
</section> */}


   <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
  {/* <section className='B2B-Marketing'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h2>Our Approach To <span class="colororange">B2B Marketing</span></h2>
      <p className='contenthome'>We integrate the best tactics with our marketing experience for the best outcomes.</p>
          
             </div>
    <div className='row'>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='arrow'>
         </div>
           <div className='arowcontent1'>
           <img src={writer} className='writer' alt='writer' />
              <h4>Quality Content</h4>
          </div>
           <p className='require'>Great results require great content. We work with the best writers to create first-class content.</p>
      </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12' >
      <div className='fouusamintion'>
       <div className="mover-outer">
  <div className="path-placeholder" />
  <div className="mover mover1" />
  <img src={hands} className='hands' alt='hands' />
   <h4 className='Focus'>Deep Focus</h4>
</div>
 <p className='require2'>We completely get engrossed in what we do to create a profound impact on your bottom line.</p>  
    </div>
    </div>
      <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
      <div className='arrow2'>
         </div>
              <div className='arowcontent2'>
              <img src={analysis} className='analysis' alt='analysis' />
          <h4>Data-Driven <br />Strategies</h4>
         </div>
    <p className='require3'>Latest trends, tools, & data lead the way to our work. Our award-winning strategies are fueled by data.</p>
         </div>
        
    </div>
  </div>
</div>
   </section> */}
   <section className='homeportfolio'>
   <div className='container'>
   <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <h3 class="mainsubheading animated fadeIn">Portfolio</h3>
    <h2>View our portfolio </h2>
    <p class="contenthome">We only do the best for our best clients. Catch a glimpse of how we transform ideas into action through our extensive portfolio. </p>
       </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div class="portfoliobtn">
    <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
    </div>
    </div>
     </div>
     <section className='portfolio-data'>
      <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
          <div className='port-box'>
            <div className='port-left'>
            <h3><a href="/our-portfolio">Social Media Marketing</a></h3>
            <p>Check out our impactful social media campaigns. </p>
            <div class="portobtn">
    <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
            </div>
            <div className='port-right'>
              <img src={smm} alt='' className='smm' />
            </div>
          </div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
          <div className='port-box'>
            <div className='port-left'>
            <h3><a href="/our-portfolio">PPC</a></h3>
            <p>Discover our stories of successful PPC campaigns. </p>
            <div class="portobtn">
    <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
            </div>
            <div className='port-right'>
              <img src={smm2} alt='' className='smm' />
            </div>
          </div>
        </div>
        </div>
        <div className='row' id='eiusmod'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
          <div className='port-box'>
            <div className='port-left'>
            <h3><a href="/our-portfolio">SEO </a></h3>
            <p>Have a sneak peek into our SEO success stories. </p>
            <div class="portobtn">
    <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
            </div>
            <div className='port-right'>
              <img src={smm3} alt='' className='smm' />
            </div>
          </div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
          <div className='port-box'>
            <div className='port-left'>
            <h3><a href="/our-portfolio">Web Designing</a></h3>
            <p> Explore the curation of our best design works.  </p>
            <div class="portobtn">
    <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/our-portfolio" tabindex="0">View All</a></div>
            </div>
            <div className='port-right'>
              <img src={smm4} alt='' className='smm' />
            </div>
          </div>
        </div>
        </div>
      </div>
     </section>
   </section>
   {/* <section className='client-review-video'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-12'>
        <div className='require'>
        <h3 class="mainsubheading animated fadeIn">Testimonials</h3>
    <h2>Words of appreciation </h2>
       <p>Listen to a few words of joy from our delighted clients. </p>
        </div>
        </div>
        <div className='col-xxl-8 col-xl-8 col-lg-6 col-md-6 col-12'>
        <Clicentvideoreview />
        </div>
      </div>
    </div>
   </section> */}
   <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
          {/* <img src={question1} alt='' className='question' /> */}
          <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
          <Faqhome />
          <div className='fqbtn'>
          <a class="theme-btn btn btn-effect-1 text-uppercase" href="/faqs" tabindex="0">View All</a>
          </div>
        </div>
      </div>
    </div>
   </section>
   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Myhome

