import React, { Component } from 'react';


class Webfaq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        How do your web designs lead to better user engagement and conversions?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our web development and designing team create conversion-focused websites using cutting-edge technologies that lead to enhanced user engagement and conversions. We focus on intuitive layouts, responsive and mobile-friendly design, easy navigation, clear calls to action and visually appealing interfaces to keep your website visitors engaged with your websites. We strategically position compelling CTAs throughout the entire website to propel users to take the desired action. Moreover, we provide 24/7 support to ensure that your website remains functional, secure and optimized at all times. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        Can you redesign my existing website?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                           
                            <p>Yes absolutely. We offer website redesign services to enhance the look, functionality, and user experience of your existing site. Whether it's a visual makeover or a complete revamp of your website, we can do it all. </p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Do you provide content for websites?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We provide 24/7 support, security updates, and maintenance services to ensure that your website remains secure, up-to-date, and performs at its optimum level. We can tailor our maintenance services to your specific requirements.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        What sort of ongoing support and maintenance do you offer for websites?

                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p> We provide ongoing support, security updates, and maintenance services to ensure that your website remains secure, up-to-date, and performs at its optimum level. We can tailor our maintenance services to your specific requirements.  </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How are your website designing & development services different from others?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our website development services stand out due to our commitment to customized solutions, transparent communication, quick delivery, exceptional design, and technical expertise. We focus on understanding your business and ensuring your website becomes a powerful tool for driving your online success.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Webfaq