import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from  '../../images/circle.png';
import word1 from '../../images/word1.png';
import word2 from '../../images/word2.png';
import word3 from '../../images/word3.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import guestposting from '../../images/guestposting.png';
import BackLinkBuilding from '../../images/BackLinkBuilding.png';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';
import wordsolu from '../../images/wordsolu.webp';
import wordpressvide from '../../images/wordpressvide.mp4';

const Wordpressdevelopment = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>WordPress Development Company | WordPress Development Services</title>
<meta name="description" content="As a leading WordPress development company, Whizamet provides a broad range of WordPress development services to enhance your brand identity. Contact us now." />
</Helmet>
<section className='Wordpressdevelopment'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>WordPress Web Development</h1>
                <p className='bnrtext'>Your online presence deserves nothing less than agile, intuitive and functional websites. That’s exactly what we offer. </p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>
        <div className='row' id='emailbnrrow'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={word1} alt='' className='word1' />
            <h4>5+ years of experience </h4>
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={word2} alt='' className='word1' />
            <h4>100+ WordPress project completed </h4>
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='emailbox'>
            <img src={word3} alt='' className='word1' />
            <h4>10+ countries with happy clients </h4>
           </div>
        </div>
       </div>
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='WordPress-Solutions'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <h2>Dynamic, feature-rich WordPress websites that provide enhanced user experience </h2>
        <p class="content">Whizamet is the go-to choice for businesses looking for WordPress development services. With extensive experience under our belt, we have empowered our clients by delivering rich, customized and scalable websites that have helped them scale their business. </p>
        <p class="content">We understand that creating heart-winning websites and user-friendly interfaces is no easy feat. Our dedicated team of expert WordPress developers combine their expertise with innovative technologies to craft cutting-edge solutions that meet your business requirements. </p>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <video src={wordpressvide} controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
         ></video>
        </div>
       
        </div>
    </div>
</section>
   <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients Who Trust Us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br/> Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>

<section className='wordbottom'>
    <div className='container'>
     <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our services </h3>
     <h2>Diverse WordPress development services- all under one roof </h2>
     </div>
        </div>
       </div>
       <div className="row">
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Custom WordPress development </h3>
      <p className="description">
      Whether you want a unique theme crafted from scratch or want to refine your existing theme, you have our back.
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">PSD to WordPress conversion </h3>
      <p className="description">
      We transform your creative work into a scalable, high-performing website, ensuring a stunning digital presence of your designs. 

      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">WordPress migration & upgradation </h3>
      <p className="description">
      Want to migrate your website from a different CMS or upgrade it for data protection? You can count on us. 

      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">WordPress Integration </h3>
      <p className="description">
      Let us help you enhance your website’s functionality by seamlessly integrating it with diverse third-party plugins and applications. 
      </p>
          <div className="service-icon">
          <img src={BackLinkBuilding} alt='' className='localseo' />
           </div>
    </div>
  </div>
</div>  
    </div>
</section>
<section className='wordseclast'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={wordsolu} className='wordsolu' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <h2>Discover why we’re your ideal choice for WordPress development</h2>
                <p class="content"> As a WordPress development company, we are not just another option but an exceptional choice to bring your vision to life. Here are a few compelling reasons to choose us:</p>
              <ul>
                <li>Our squad of seasoned WordPress developers brings their wealth of experience and deep-rooted passion for innovation to every project. </li>
                <li>Our seamless and transparent process ensures that you're kept informed about every step of the process. </li>
                <li>We specialize in crafting fully customized web applications tailored to your specific requirements.</li>
                <li>We create websites that attract audiences and rank high on search engines. </li>
                <li>With us, you can rest easy knowing that your project is in capable hands. We’ll deliver your project right on time and within your budget.  </li>
              </ul>
            </div>
        </div>
    </div>
</section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Wordpressdevelopment

