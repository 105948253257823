import React from "react";
import SEOrankings from "../../images/SEOrankings.png";
import revenue from "../../images/revenue.png";
import conver from "../../images/conver.png";
import conver2 from "../../images/conver2.png";
import visibility1 from '../../images/visibility1.png';
import visibility2 from '../../images/visibility2.png';
import visibility3 from '../../images/visibility3.png';
import visibility4 from '../../images/visibility4.png';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


const Ecommtools = () => {
  return (
    <>
      <div className="ecommtools">
            <div className="container-fluid">
            <div className="ecommtools-wrapper align-items-center">
         <div className="ecommtools-slider">
          <div className="swiper-ecommtools">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1440: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
          <img src={visibility1} className='visibility1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={visibility2} className='visibility1' alt='' />
          </SwiperSlide>  
          <SwiperSlide>
          <img src={visibility3} className='visibility1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={visibility4} className='visibility1' alt='' />
          </SwiperSlide>
    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Ecommtools;