import React, { Component } from 'react';


class Faqorm extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        How can Whizamet help in improving my online reputation?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>With years of experience in online reputation management, we excel in crafting positive brand images. Our expert team employs diverse strategies such as strict monitoring of online platforms, customer reviews management, and active engagement to uplift your brand reputation.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        What are the social media platforms that you monitor for online reputation management?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            {/* <div className="ltn__video-img alignleft">
                            <img src={publicUrl+"assets/img/bg/17.jpg"} alt="video popup bg image" />
                            <a className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----" href="https://www.youtube.com/embed/LjCzPp-MK48?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
                                <i className="fa fa-play" />
                            </a>
                            </div> */}
                            <p>We monitor a wide range of platforms, including social media networks, customer review sites, online forums and SERPs. With our comprehensive monitoring, we make sure that we stay up to date with the conversations about your brand on different channels.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do you take care of negative reviews about my brand?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Negative reviews or comments can spoil your online reputation. We approach negative feedback on a one-to-one basis by responding professionally. Our goal is to provide solutions that will help transform negative experiences into positive ones.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        Do you offer solutions for crisis management?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Absolutely! We know that online crises can emerge unexpectedly, posing a severe threat to your brand. Our team is expert in handling such crises. We craft customised crisis management strategies involving stakeholder identification, devising communication plans, and monitoring online sentiment. Through quick action, we reduce damage, thus safeguarding your reputation in adverse situations.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How do you analyse the effectiveness of your ORM efforts?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We deploy several metrics to gauge the effectiveness of our ORM efforts. These metrics include monitoring online sentiment, evaluating customer reviews, and examining changes in search engines. Through constant monitoring of these metrics, we tweak our strategies to ensure the best results.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqorm