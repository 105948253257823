import React from 'react';
import about13 from '../../images/about13.webp';
import circle from  '../../images/circle.png';
const AboutB = () => {
    return (
<>


<section className="aboutbanner1">
<div className="container">
  <div className="row">
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
      <div className="casesty-content2">
        <h3 className="Expert animated fadeIn">whizamet</h3>
        <h1 className="banner-title">About Us</h1>
        <p className="bnrtext">
        Discover our story, core values, work culture and people behind our success.
        </p>
        <div className="bnrbtn pt-btn">
          <a
            className="theme-btn-1 btn btn-effect-1 text-uppercase"
            href="/contactus"
            tabIndex={0}
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 ellipse5555">
    <img
          className="img-fluid rounded"
          src={about13}
          alt=""
          loading="aboutb"
        />
      
    </div>
  </div>
</div>
<div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>
 </>
 )
 }
 
 export default AboutB
 