import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from '../../images/circle.png';

import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import campaineinstagram from '../../images/campaineinstagram.webp';

import InfluencerIdentification from '../../images/InfluencerIdentification.png';
import SocialMediaManagement from '../../images/SocialMediaManagement.png';
import CampaignManagement from '../../images/CampaignManagement.png';
import ContentCreation from '../../images/ContentCreation.png';
import InfluencerRelations from '../../images/InfluencerRelations.png';
import PerformanceTracking from '../../images/PerformanceTracking.png';
import instainflvideo from '../../images/instainflvideo.mp4';


const Instagrameinfluencer = () => {
  return (
    <>
      <NavbarV3 />
      <Helmet>
        <title>Top Instagram Influencer Marketing Services Agency in India</title>
        <meta name="description" content="Amplify your brand with our Instagram Influencer Marketing Services. Connect with influencers, drive engagement, and elevate your social presence. Let's boost your influence." />
      </Helmet>
      <section className='Shopifydevelopment'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <div className='seocontentbg1'>
                <h1>Instagram Influencer Marketing</h1>
                <p className='bnrtext'>We empower your brand story to be communicated by the most popular influencers.</p>

                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
              </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              {/* <img src={influencer1} className='influencer1' alt='' /> */}
              <video src={instainflvideo}
                autoPlay={"autoplay"}
                controls={"muted"}
                preLoad="auto"
                loop id='pseudo-partial-border-instainflvid'>
              </video>
            </div>

          </div>

        </div>
        <div className='left-seoshape1'></div>
        <img src={circle} className='seocircle2' alt='circle' />
      </section>
      <section className='instaiseclast'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
              <div className='Personalized'>
                <h3 class="mainsubheading">What we do</h3>
                <h2>Redefining brands through the power of influencers</h2>
                <p class="content">Our influencer marketing services are not just collaborations. They are strategic <br />partnerships that help brands fulfil their marketing goals.</p>
              </div>
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Influencer identification</h3>
                    <p className="description">
                      We help brands find and reach the right influencers for their campaign.
                    </p>
                    <div className="service-icon">
                      <img src={InfluencerIdentification} alt='' className='localseo' />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Social media management</h3>
                    <p className="description">
                      We make our client’s social media channels buzz with live campaign updates.
                    </p>
                    <div className="service-icon">
                      <img src={SocialMediaManagement} alt='' className='localseo' />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Campaign management</h3>
                    <p className="description">
                      We manage every aspect of the campaign from collateral creation to execution and optimization.
                    </p>
                    <div className="service-icon">
                      <img src={CampaignManagement} alt='' className='localseo' />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" id='adipisicing'>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Content creation</h3>
                    <p className="description">
                      Our content creation team is proficient in crafting engaging content for all social media platforms.
                    </p>
                    <div className="service-icon">
                      <img src={ContentCreation} alt='' className='localseo' />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Influencer relations</h3>
                    <p className="description">
                      We specialize in assisting brands in creating successful connections with influencers for long-lasting partnerships.
                    </p>
                    <div className="service-icon">
                      <img src={InfluencerRelations} alt='' className='localseo' />
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
                  <div className="serviceBox">
                    <h3 className="title">Performance tracking</h3>
                    <p className="description">
                      We track every phase and result of the influencer marketing campaigns through advanced analytics.
                    </p>
                    <div className="service-icon">
                      <img src={PerformanceTracking} alt='' className='localseo' />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>
      <section className='digi-logos'>
        <div className='container'>
          <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
          <h2 className='Minimize'>Clients who trust us </h2>
          <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
          <div className='mobile-client'>
            <Industriesserve />
          </div>
        </div>
        <div className='logos-reviws'>
          <br />
          <Bgflyinglogos />
          <br />
          <br />
          <Bgflyinglogos2 />
          <br />
          <br />
          <Bgflyinglogos />
          <br />
          <br />
          <Bgflyinglogos2 />
          <br />
          <br />
          <Bgflyinglogos />
          <br />
        </div>
      </section>
      <section className='ingrme'>
        <div className='container'>
          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
              <img src={campaineinstagram} className='campaineinstagram' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
              <h2>What makes us stand out from the crowd</h2>
              <div className='inslist'>
                <h3>Genuine fans, not just followers</h3>
                <p>While every other Instagram influencer agency is busy boasting their followers count, we focus on helping you create loyal fans who will only cheer for you.</p>
              </div>
              <div className='inslist'>
                <h3>Tailored collaborations</h3>
                <p>Every partnership is different. Therefore, we tailor our influencer collaborations according to your brand identity.</p>
              </div>
              <div className='inslist'>
                <h3>Data-driven decisions</h3>
                <p>Our decisions are backed by analytics. We measure influencer’s performance to help you find the right influencer for your campaign.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='react-step'>
        <div className='container'>
          <div className='row' >
            <h3 class="mainsubheading">No canned packages. Only bespoke solutions</h3>
            <h2>Have a look into <br />our process</h2>
          </div>
          <div className='row'>
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="reactprocess">
                <div
                  className="item item-1 animate wow fadeInRight"
                  data-wow-delay="0.1s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.1s",
                    animationName: "fadeInRight"
                  }}
                >
                  <div className="line" />
                  <div className="circle-wrap">
                    <div className="mask">
                      <div className="wave-pulse wave-pulse-1" />
                      <div className="wave-pulse wave-pulse-2" />
                      <div className="wave-pulse wave-pulse-3" />
                    </div>
                    <h6 className="circle">1</h6>
                  </div>
                  <div className="content-wrap">
                    <h3>Discover & strategize</h3>
                    <div className="process-text">
                      We work with you to understand your brand objectives, target audience and campaign goals to create a tailored strategy.
                    </div>
                  </div>
                </div>
                <div
                  className="item item-1 animate wow fadeInRight"
                  data-wow-delay="0.15s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.15s",
                    animationName: "fadeInRight"
                  }}
                >
                  <div className="line" />
                  <div className="circle-wrap">
                    <div className="mask">
                      <div className="wave-pulse wave-pulse-1" />
                      <div className="wave-pulse wave-pulse-2" />
                      <div className="wave-pulse wave-pulse-3" />
                    </div>
                    <h6 className="circle">2</h6>
                  </div>
                  <div className="content-wrap">
                    <h3>Influencer research</h3>
                    <div className="process-text">
                      Our team conducts extensive research to find you the right influencer whose audience aligns with your values.
                    </div>
                  </div>
                </div>
                <div
                  className="item item-1 animate wow fadeInRight"
                  data-wow-delay="0.20s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInRight"
                  }}
                >
                  <div className="line" />
                  <div className="circle-wrap">
                    <div className="mask">
                      <div className="wave-pulse wave-pulse-1" />
                      <div className="wave-pulse wave-pulse-2" />
                      <div className="wave-pulse wave-pulse-3" />
                    </div>
                    <h6 className="circle">3</h6>
                  </div>
                  <div className="content-wrap">
                    <h3>Creative campaign development</h3>
                    <div className="process-text">
                      From ideation to execution, our campaigns are a perfect blend of creativity and strategy.
                    </div>
                  </div>
                </div>
                <div
                  className="item item-1 animate wow fadeInRight"
                  data-wow-delay="0.25s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.25s",
                    animationName: "fadeInRight"
                  }}
                >
                  <div className="line" />
                  <div className="circle-wrap">
                    <div className="mask">
                      <div className="wave-pulse wave-pulse-1" />
                      <div className="wave-pulse wave-pulse-2" />
                      <div className="wave-pulse wave-pulse-3" />
                    </div>
                    <h6 className="circle">4</h6>
                  </div>
                  <div className="content-wrap">
                    <h3>Best-in-class reporting </h3>
                    <div className="process-text">
                      Our comprehensive reports provide insights into the key metrics such as EMV and conversion tracking.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <Serivcecontactform />
      <Footer_v1 />
    </>
  )
}

export default Instagrameinfluencer

