
import React, { useRef, useState } from "react";
// import abtfeature from "../../images/abtfeature.webp";
import abtfeature from "../../images/abtfeature.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";



// import required modules
import { EffectCoverflow, Pagination } from "swiper";

export default function AboutN3() {
  return (
    <section className="about-card" >
  <div className='container'>
  <div className='row'>
<div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='textrighttwo'>
    <h3 class="mainsubheading">Our promise</h3>
     <h2 className='B2B'>Upholding our core values with honesty </h2>
     <p className='content'>Staying true to our core values, we go the extra mile to get the greatest results for our clients. Based on trust and mutual respect, we have partnered with a diverse set of customers worldwide to help them achieve their marketing goals. Our core values of integrity, honesty and transparency have been our guiding principles for every project and partnership. Collaborate with us to begin your success journey. </p>

  </div>

  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='card-left'>
  <img src={abtfeature} className="abtfeature" alt="" />
    <>
    {/* <div
    className="tp-rank__rank-card wow tpfadeRight"
    data-wow-duration=".9s"
    data-wow-delay=".7s"
    style={{
      visibility: "visible",
      animationDuration: "0.9s",
      animationDelay: "0.7s",
      animationName: "tpfadeRight"
    }}
  >
    <div className="tp-rank__item active p-relative z-index">
      <div className="tp-rank__cup">
        <span>
          <img src="assets/img/rank/rank-cup.png" alt="" />
        </span>
      </div>
      <div className="tp-rank__number d-flex align-items-center justify-content-center">
        <i>#</i>
        <span>1</span>
      </div>
      <div className="tp-rank__company">
        <span>
          <img src="assets/img/rank/amazon.png" alt="" />
        </span>
      </div>
      <div className="tp-rank__company-info">
        <a href="#">Integrity Honesty</a>
     </div>
    </div>
    <div className="tp-rank__item p-relative">
      <div className="tp-rank__number d-flex align-items-center justify-content-center">
        <i>#</i>
        <span>2</span>
      </div>
      <div className="tp-rank__company">
        <span>
          <img src="assets/img/rank/ebay.png" alt="" />
        </span>
      </div>
      <div className="tp-rank__company-info">
        <a href="#">ebay.com</a>
        <span>700.2M Visits</span>
      </div>
    </div>
    <div className="tp-rank__item p-relative z-index">
      <div className="tp-rank__number d-flex align-items-center justify-content-center">
        <i>#</i>
        <span>3</span>
      </div>
      <div className="tp-rank__company">
        <span>
          <img src="assets/img/rank/walmart.png" alt="" />
        </span>
      </div>
      <div className="tp-rank__company-info">
        <a href="#">walmart.com</a>
        <span>489.6M Visits</span>
      </div>
    </div>
    <div className="tp-rank__item p-relative">
      <div className="tp-rank__number d-flex align-items-center justify-content-center">
        <i>#</i>
        <span>4</span>
      </div>
      <div className="tp-rank__company">
        <span>
          <img src="assets/img/rank/shopify.png" alt="" />
        </span>
      </div>
      <div className="tp-rank__company-info">
        <a href="#">shopify.com</a>
        <span>386.6M Visits</span>
      </div>
    </div>
  </div> */}
    </>
    </div>
    </div>
    </div>
    </section>
  );
}


