import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BlogGrid extends Component {
	render() {
		let publicUrl = process.env.PUBLIC_URL + '/'
		let imagealt = 'image'
		return (
			<div className="ltn__blog-area ltn__blog-item-3-normal  go-top">
				<div className="container">
					<div className="row">

						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/7-way-fantasy-sports-can-benefit-from-internet-marketing"><img src={publicUrl + "assets/img/blog/123456.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />INTERNET MARKETING</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/7-way-fantasy-sports-can-benefit-from-internet-marketing">7 Ways Fantasy Sports can benefit from Internet Marketing</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 23, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/7-way-fantasy-sports-can-benefit-from-internet-marketing">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>



						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/the-ultimate-guide-to-seo-for-fantasy-sports-platforms"><img src={publicUrl + "assets/img/blog/npmm.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />SEO </p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/the-ultimate-guide-to-seo-for-fantasy-sports-platforms">The Ultimate Guide to SEO for Fantasy Sports Platforms</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 21, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/the-ultimate-guide-to-seo-for-fantasy-sports-platforms">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>




						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/best-guide-to-online-reputation-management-for-logistics"><img src={publicUrl + "assets/img/blog/orm.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />ORM </p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/best-guide-to-online-reputation-management-for-logistics">Best Guide to Online Reputation Management for Logistics</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 19, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/best-guide-to-online-reputation-management-for-logistics">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>


						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/the-ultimate-guide-to-using-ppc-for-real-estate-businesses"><img src={publicUrl + "assets/img/blog/ppc.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />PPC </p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/the-ultimate-guide-to-using-ppc-for-real-estate-businesses">The Ultimate Guide to Using PPC for Real Estate Businesses </Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 16, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/the-ultimate-guide-to-using-ppc-for-real-estate-businesses">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>





						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/8-compelling-reasons-why-you-should-outsource-your-digital-marketing-requirements"><img src={publicUrl + "assets/img/blog/dmmm.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Digital Marketing </p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/8-compelling-reasons-why-you-should-outsource-your-digital-marketing-requirements">8 Compelling Reasons Why You Should Outsource Your Digital Marketing Requirements</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 14, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/8-compelling-reasons-why-you-should-outsource-your-digital-marketing-requirements">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>





						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/7-best-reasons-to-use-shopify-for-your-e-commerce-website"><img src={publicUrl + "assets/img/blog/sb1.jpg"} alt="#" className='nb-pic' /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Shopify </p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/7-best-reasons-to-use-shopify-for-your-e-commerce-website">7 Best Reasons To Use Shopify For Your E-Commerce Website</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 12, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/7-best-reasons-to-use-shopify-for-your-e-commerce-website">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/6-best-ai-tools-for-seo-in-2024"><img src={publicUrl + "assets/img/blog/seo2.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Seo</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/6-best-ai-tools-for-seo-in-2024">6 Best AI Tools for SEO in 2024 </Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 08, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/6-best-ai-tools-for-seo-in-2024">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/top7-digital-marketing-companies-in-the-harris-county-area-to-fasten-growth"><img src={publicUrl + "assets/img/blog/dmm.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Digital Marketing</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/top7-digital-marketing-companies-in-the-harris-county-area-to-fasten-growth">Top 7 Digital Marketing Companies in the Harris County Area To Fasten Growth</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 06, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/top7-digital-marketing-companies-in-the-harris-county-area-to-fasten-growth">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/9-compelling-reasons-to-choose-wordpress-for-your-next-project"><img src={publicUrl + "assets/img/blog/1234.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />WordPress</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/9-compelling-reasons-to-choose-wordpress-for-your-next-project">9 Compelling Reasons to Choose WordPress for Your Next Project</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />August 01, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/9-compelling-reasons-to-choose-wordpress-for-your-next-project">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>


						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/top-7-digital-marketing-companies-in-la-california"><img src={publicUrl + "assets/img/blog/blogimg3.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Marketing</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title"><Link to="/top-7-digital-marketing-companies-in-la-california">Top 7 Digital Marketing Companies In LA California</Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />July 30, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/top-7-digital-marketing-companies-in-la-california">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* Blog Item */}
						<div className="col-lg-4 col-sm-6 col-12">
							<div className="ltn__blog-item ltn__blog-item-3">
								<div className="ltn__blog-img">
									<Link to="/top-10-digital-marketing-companies-in-india"><img src={publicUrl + "assets/img/blog/blogimg2.jpg"} alt="#" /></Link>
								</div>
								<div className="ltn__blog-brief">
									<div className="ltn__blog-meta">
										<ul>
											<li className="ltn__blog-author go-top">
												<p><i className="far fa-user" />by: admin</p>
											</li>
											<li className="ltn__blog-tags go-top">
												<p><i className="fas fa-tags" />Digital Marketing</p>
											</li>
										</ul>
									</div>
									<h3 className="ltn__blog-title">
										<Link to="/top-10-digital-marketing-companies-in-india">Top 10 Digital Marketing Companies In India </Link></h3>
									<div className="ltn__blog-meta-btn">
										<div className="ltn__blog-meta">
											<ul>
												<li className="ltn__blog-date"><i className="far fa-calendar-alt" />July 19, 2024</li>
											</ul>
										</div>
										<div className="ltn__blog-btn">
											<Link to="/top-10-digital-marketing-companies-in-india">Read more</Link>
										</div>
									</div>
								</div>
							</div>
						</div>







						{/* Blog Item */}
						{/* <div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/BindingEnergyInDesignandContentMarketing"><img src={publicUrl+"assets/img/blog/4.webp"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<p><i className="far fa-user" />by: Admin</p>
						</li>
						<li className="ltn__blog-tags go-top">
						<p><i className="fas fa-tags" />Marketing</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/BindingEnergyInDesignandContentMarketing">Binding Energy In Design & Content Marketing</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />June 24, 2020</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/BindingEnergyInDesignandContentMarketing">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div> */}
						{/* Blog Item */}
						{/* <div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/5.webp"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<p><i className="far fa-user" />by: Admin</p>
						</li>
						<li className="ltn__blog-tags go-top">
							<p><i className="fas fa-tags" />Marketing</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/EasyWayToManagingMultipleSitesAtOnce">Easy Way To Managing Multiple Sites At Once</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />Oct 24, 2022</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/EasyWayToManagingMultipleSitesAtOnce">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div> */}
						{/* Blog Item */}
						{/* <div className="col-lg-4 col-sm-6 col-12">
				<div className="ltn__blog-item ltn__blog-item-3">
					<div className="ltn__blog-img">
					<Link to="/blog-details"><img src={publicUrl+"assets/img/blog/6.jpg"} alt="#" /></Link>
					</div>
					<div className="ltn__blog-brief">
					<div className="ltn__blog-meta">
						<ul>
						<li className="ltn__blog-author go-top">
							<Link to="/team-details"><i className="far fa-user" />by: Admin</Link>
						</li>
						<li className="ltn__blog-tags go-top">
						<p><i className="fas fa-tags" />Marketing</p>
						</li>
						</ul>
					</div>
					<h3 className="ltn__blog-title"><Link to="/blog-details">Common Engine Oil Problems and Solutions</Link></h3>
					<div className="ltn__blog-meta-btn">
						<div className="ltn__blog-meta">
						<ul>
							<li className="ltn__blog-date"><i className="far fa-calendar-alt" />June 24, 2020</li>
						</ul>
						</div>
						<div className="ltn__blog-btn">
						<Link to="/blog-details">Read more</Link>
						</div>
					</div>
					</div>
				</div>
				</div> */}

					</div>

					{/* <div className="row">
				<div className="col-lg-12">
				<div className="ltn__pagination-area text-center">
					<div className="ltn__pagination">
					<ul>
						<li><Link to="#"><i className="fas fa-angle-double-left" /></Link></li>
						<li><Link to="#">1</Link></li>
						<li className="active"><Link to="#">2</Link></li>
						<li><Link to="#">2</Link></li>
						<li><Link to="#">...</Link></li>
						<li><Link to="#">4</Link></li>
						<li><Link to="#"><i className="fas fa-angle-double-right" /></Link></li>
					</ul>
					</div>
				</div>
				</div>
			</div> */}

				</div>
			</div>

		)
	}
}

export default BlogGrid;
