import React from 'react';
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaRegEnvelope,
} from "react-icons/fa";
import checkpick from '../../images/checkpick.png';

const Serivcecontactform = () => {
    return (
<>
<section className='Serivcecontactform'>
    <div className='container'>
    <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <h2>Have a project in your mind? </h2>
    <p className='challenges'>We’d love to discuss your project needs. Get in touch with us today for a personalized strategy that will elevate your business digitally. </p>
<h3 className='ring'>Reach out to us:</h3>
{/* <h4 className='exceeded'><a href='tel:+91 9876769096'>+91 9876769096</a></h4> */}
<div className="contactdetails">
           <h6>Contact Number:</h6>
          <div className='media'>
                    <div className='icon'>
                      <FaPhoneAlt />
                    </div>
                    <div className='media-body'>
                      <p><a href='tel:+91 9876769096'>+91 9876769096</a></p>
                      </div>
                  </div>
                  <h6>Email Address:</h6>
                  <div className='media'>
                    <div className='icon'>
                      <FaRegEnvelope />
                    </div>
                    <div className='media-body'>
                      <p><a href="mailto:reach@whizamet.com">reach@whizamet.com</a></p>
                                     </div>
                  </div>
                  <h6>Office Location:</h6>
                  <div className='media' id="lstbox">
                    <div className='icon'>
                      <FaMapMarkerAlt />
                    </div>
                    <div className='media-body'>
                      <p>IT-C-7, Netsmartz Center, 6th Floor, Sector 67 SAS Nagar, Punjab-160062</p>
                    </div>
                  </div>
        </div>

    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div className='outerform'>
        <h3 className='Grow'>Start your digital journey with us </h3>
        <p className='their'>We look forward to assisting you with any queries or requests that you may have. Just fill in your details below, and we’ll get back to you right away. </p>
      <form action="footerform.php"
                     method="post"
                    encType="multipart/form-data"
                    id="jobdetil">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12">
                    <input type="text" name="name" placeholder="Your Name" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12">
                    <input type="text" name="phone" placeholder="Your Phone" className="reg-input" required />
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12">
                  <input type="email" name="email" placeholder="Your Email" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-12 col-lg-6 col-12">
                    <input type="text" name="location" placeholder="Your Location" className="reg-input" required />
                      </div>
                     </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <select name="serivce" className='serivce-query'>
                   
                    <option value="Digital Marketing">Digital Marketing</option>
                    <option value="orm">Online Reputation Management</option>
					<option value="smm">Social Media Marketing</option>
					<option value="ppc">Pay Per Click </option>
					<option value="seo">Search Engine Optimization</option>
					<option value="Graphic Designing">Graphic Designing</option>
					<option value="Web Designing">Web Designing</option>
					<option value="Email Marketing">Email Marketing</option>
                    <option value="Content Writing">Content Writing</option>
                    </select>
                     </div>
                    </div>
                 <button className="lab-btn" type="submit"><span>Submit</span></button>
                 </form>
    </div>
    </div>
   
    </div>
   
    </div>
</section>
</>
)
}

export default Serivcecontactform
