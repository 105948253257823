import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import AboutN1 from '../section-components/about-n1';
import AboutN2 from '../section-components/about-n2';
import AboutN3 from '../section-components/about-n3';
import Industriesserve from '../section-components/clients/client1';
import AboutN5 from '../section-components/about-n5';
import AboutN6 from '../section-components/about-n6';
import AboutN7 from '../section-components/about-n7';
import AboutN8 from '../section-components/about-n8';
import AboutGallery from '../section-components/aboutgallery';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import AboutB from '../section-components/about-b';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <>

      <NavbarV3 />

      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us - Whizamet</title>
        <meta name="description" content="A team of about marketing professionals and growth hacking experts obsessed with helping businesses grow. We are here to help you expand with smarter marketing." />
        <link rel="canonical" href="https://whizamet.com/about" />
      </Helmet>
      <AboutB />
      <AboutN1 />
      <AboutN2 />
      <AboutN3 />
      <section className='digi-logos'>
        <div className='container'>
          <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
          <h2 className='Minimize'>Clients who trust us </h2>
          <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
          <div className='mobile-client'>
            <Industriesserve />
          </div>
        </div>
        <div className='logos-reviws'>
          <br />
          <Bgflyinglogos />
          <br />
          <br />
          <Bgflyinglogos2 />
          <br />
          <br />
          <Bgflyinglogos />
          <br />
          <br />
          <Bgflyinglogos2 />
          <br />
          <br />
          <Bgflyinglogos />
          <br />
        </div>
      </section>
      <AboutN5 />
      <AboutN6 />
      <AboutGallery />
      <AboutN7 />
      <AboutN8 />
      <Footer_v1 />
    </>
  )
}

export default AboutUs

