import React, { Component } from 'react';


class Faqseo extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        How can small businesses benefit from SEO?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>SEO boosts the online visibility of small businesses, making them visible to potential customers in SERPs. Additionally, SEO offers a cost-effective way to reach their target audience and convert them into customers.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        Why is SEO Audit important?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            {/* <div className="ltn__video-img alignleft">
                            <img src={publicUrl+"assets/img/bg/17.jpg"} alt="video popup bg image" />
                            <a className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----" href="https://www.youtube.com/embed/LjCzPp-MK48?autoplay=1&showinfo=0" data-rel="lightcase:myCollection">
                                <i className="fa fa-play" />
                            </a>
                            </div> */}
                            <p>SEO Audit is an evaluation of the websites that helps to determine their ability to appear in Search Engine Results Pages. This assessment helps to identify areas for improvement, ultimately helping in improved visibility on search engine pages.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        Why is content quality crucial for SEO results?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Content creation is a crucial factor for the best results. Search engines analyze content quality for uniqueness and trustworthiness. High-quality content engages users, thus helping brands establish themselves as market leader in their niche.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        Why should I hire Whizamet for SEO services?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Whizamet is the best SEO agency, having the expertise to optimize your website for increased visibility on search engines. From keyword research to content creation, strategic link building and comprehensive website optimization, we offer a wide range of services to our clients. When you hire Whizamet, you are guaranteed to get customized services tailored to your individual needs.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How much time does it take to see desired results from SEO services?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>The timeframe for getting the desired outcome from SEO services depends on different factors like industry competitiveness, website quality and the resources invested. It may take a few months to see desirable improvement in your website ranking. However, through continuous optimization, you can see significant results over time.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqseo