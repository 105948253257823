import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import  ecommbnr from '../../images/ecommbnr.webp';
import Edevelopment from '../../images/Edevelopment.webp';
import B2Bsolutions from '../../images/B2Bsolutions.webp';
import Websitesupport from '../../images/Websitesupport.webp';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import guestposting from '../../images/guestposting.png';
import BackLinkBuilding from '../../images/BackLinkBuilding.png';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import Ecommtools from '../section-components/ecommtools';


const EcommerceServices = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Ecommerce Website Development | Ecommerce Development Services</title>
<meta name="description" content="Whizamet is a premier Ecommerce website development company, providing top-tier Ecommerce development services to drive more sales and ROI for your business." />
</Helmet>

<section className='ecomm-web'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">E-commerce web development </h3>
     <h2>Get bespoke, feature-packed e-commerce solutions that elevate online shopping experiences </h2>
          </div>
         </div>
        </div>
       <div className='row'>
       <Ecommtools />
       </div>
    </div>
</section>
<section className='ecombnrbotm'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <h2>All-inclusive E-commerce services to make your online store thrive </h2>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <p class="content">At Whizamet, we provide a comprehensive range of solutions to make your online business journey smooth and effortless. From user-friendly interface to secure payment gateways, our solutions cover every facet of e-commerce. </p>
        </div>   
    </div>
    <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={Edevelopment} className='Edevelopment' alt='' />
            </span>
            <h4>Custom e-commerce web development </h4>
            <p>Whether you want a complete revamp of your website or just a few tweaks to your perfect online presence, our experts are here to help. </p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={B2Bsolutions} className='Edevelopment' alt='' />
            </span>
            <h4>E-commerce migration</h4>
            <p>Experience quick business growth with our e-commerce migration services. We can seamlessly migrate your data from any virtual platform. </p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12'>
           <div className='E-Commerce-box'>
            <span>
                <img src={Websitesupport} className='Edevelopment' alt='' />
            </span>
            <h4>E-commerce integration </h4>
            <p>With our integration services, you can smoothly run your online store by utilizing the power of third-party applications. </p>
           </div>  
            </div>
        </div>
</div>
</section>
<section className='ecomsolutions'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">How we do it </h3>
     <h2>Going the extra mile at every step </h2>
     </div>
        </div>
            </div>
            <div className="row">
            <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
               <div className="serviceBox">
      <h3 className="title">Assessment </h3>
      <p className="description">
      We understand the features and layout you would like to have for your website. 
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Development </h3>
      <p className="description">
      We bring your ideas to reality, harnessing the power of codes to create your website’s backend. 

      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Test </h3>
      <p className="description">
      After development, our next crucial step is to put the coded systems to rigorous testing to assess their feasibility.

      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Live </h3>
      <p className="description">
      We initiate the launch phase by integrating the backend with the design element. Your website is ready to deliver exceptional user experiences. 
      </p>
          <div className="service-icon">
          <img src={BackLinkBuilding} alt='' className='localseo' />
           </div>
    </div>
  </div>
</div>
       
     </div>
</section>
<section className='Ecommerceservce'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12' id='algorithms'>
            <h2>Why choose us as your partner in the journey from “bricks to clicks.”</h2>
        <ul className='Visitors'>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Our vast experience provides us with a competitive edge. We understand what works and what doesn’t in the digital world.</li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>When you choose us, you’ll get a powerhouse of experienced professionals, skilled developers, and cutting-edge technology, all at the price of one.</li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We are proficient in a wide array of technologies, including Magento, Shopify, BigCommerce, Drupal Commerce, Ubercart, Laravel, HTML5 and more. </li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Our dedicated team conducts rigorous testing before your website goes live. </li>
            <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We possess SEO & SMM capabilities to boost traffic and conversions on online stores.</li>
        </ul>
            </div>
       
       
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
        <img src={ecommbnr} className='ecommbnr' alt='ecommbnr' /> 
        </div>
        </div>
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default EcommerceServices

