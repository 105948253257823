import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import Careeropenings from '../section-components/careeropenings';



const Career = () => {
    return (
<>
<NavbarV3 />
<section className='Career'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12'>
        <h3 className='Expert'>Career</h3>
            <h2>Interested in joining our team?</h2>
            <p class="value-driven">Our team is our most valuable asset and we prioritize accelerating their career growth in the evolving digital landscape. If you have a strong  passion for growing brands digitally, we’d love to have you in our team.</p>
            <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Join Us</a></div>
                    </div>
    </div>
</div>
</section>
<section className='career-opening'>
    <div className='container'>
<div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <div className='careerbox'>
            <h2>Our current job openings</h2>
            <Careeropenings />
        </div>
    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div className="seekermodal">
     {/* <h3>Find out a place that <span className='mycolor'>feels good </span></h3>
     <p>Fill out this form with your details and one of our experts will contact you.</p> */}
     <form action="career.php"
                     method="post"
                    encType="multipart/form-data"
                    id="jobdetil">
                  <div className="row">
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="name" placeholder="Your Name" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12" >
                    <input type="text" name="jobprofile" placeholder="Job Profile" className="reg-input" required />
                                  </div>
                  </div>
                  <div className="row">
                  <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                  <input type="email" name="email" placeholder="Your Email" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="phone" placeholder="Your Phone" className="reg-input" required />
                         </div>
                     </div>
                    <div className='row'>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="salary" placeholder="Salary Expectation" className="reg-input" required />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-md-6 col-lg-6 col-12">
                    <input type="text" name="noticeperiod" placeholder="Notice Period" className="reg-input" required />
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <select name="experience" id="experience">
                     <option value="fresher" name="fresher">Fresher</option>
                    <option value="experienced" name="experienced">Experienced</option>
                    </select>
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <textarea id="message" title="Message" name="message" maxlength="500" placeholder="Enter Message" required=""></textarea>
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xxl-12 col-xl-12 col-md-12 col-lg-12 col-12" >
                    <div className='Resume'>
                   <label class="fieldlabels">Upload Resume *</label><br />
                <input type="file" className='formfile' name="file" multiple="" />
                </div>
                    </div>
                    </div>
                     <button className="lab-btn" type="submit"><span>Submit</span></button>
                 </form>
                 </div>
    </div>
</div>
    </div>
</section>
 
<Footer_v1 />
</>
)
}

export default Career

