import React from "react";
import reac1 from "../../images/reac1.png";
import reac2 from "../../images/reac2.png";
import reac3 from "../../images/reac3.png";
import reac4 from '../../images/reac4.png';
import reac5 from '../../images/reac5.png';
import reac6 from '../../images/reac6.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";


const Reactools = () => {
  return (
    <>
      <div className="Reactools">
            <div className="container-fluid">
            <div className="Reactools-wrapper align-items-center">
         <div className="Reactools-slider">
          <div className="swiper-Reactools">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 4,
                        spaceBetween: 10,
                           },
                           1440: {
                    slidesPerView: 5,
                        spaceBetween: 10,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
          <img src={reac1} className='reac1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={reac2} className='reac1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={reac3} className='reac1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={reac4} className='reac1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={reac5} className='reac1' alt='' />
          </SwiperSlide>
          <SwiperSlide>
          <img src={reac6} className='reac6' alt='' />
          </SwiperSlide>
        
          
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Reactools;