import React, { Component } from 'react';


class Californiafaq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Why should I choose Whizamet as my digital marketing service agency in California?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            We stand out due to our innovative approach that combines innovation and data-driven strategies. We ensure your brand keeps up with the latest trends, ensuring its robust presence in the digital landscape. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        Do you redesign existing websites?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <div className="ltn__video-img alignleft">
                            </div>
                            <p>
                            Yes. We can give your old website a refreshing look, user experience and seamless functionality. We understand your business goals and add unique features to your website that will help you meet those goals. 
                            </p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do you measure the success of your digital marketing services?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            We consistently track and monitor campaigns to evaluate the effectiveness of our digital marketing strategies. We analyze key performance indicators, including website traffic, conversion rates, engagement metrics and return on investment, and consistently refine the campaigns for better results. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How long will it take for my website to rank higher on search engines?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            It may take approximately 2-3 months for your website to generate sustainable traffic and see significant improvement in rankings. Before starting your campaign, we’ll provide an estimated timeline within which you can expect results from our SEO services. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How do you stay ahead with the latest trends and changes in digital marketing?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>
                            We continuously track the latest trends, algorithm updates, and best practices in digital marketing and improve our strategies to ensure a strong online presence for your brand. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Californiafaq