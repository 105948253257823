import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import fb from '../../images/fb.webp';
import fbbnr from '../../images/fbbnr.webp';
import creatopy from "../../images/creatopy.webp";
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';


const FaceBook = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Facebook Ads Expert | Facebook Ad Agency</title>
<meta name="description" content="Whizamet is a prominent Facebook ad agency specializing in creating compelling and cost-effective campaigns. Connect with our experts to schedule a consultation. " />
</Helmet>
<section className='googleadsbner'>
    <div className='container'>
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Facebook Ad Services</h1>
            <p className='bnrtext'>For every second that you’re missing to advertise on facebook, you’re losing customers.<br />Our facebook marketing agency can help you reach your target customers.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={fbbnr} className='fbbnr' alt='circle' />
    </div>
            </div>
    
    </div>
    <div className='left-seoshape1'></div>

    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className="Engement">
  <div className='container'>
  <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
<div className='Personalized'>
     <h3 class="mainsubheading">Maximize Engagement</h3>
     <h2> Connect with your target audience <br></br>through Facebook ads</h2>
     </div>
     </div>
     </div>
<div className="row">
<div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
<div className="fourbox">
          <h2>
            01
          </h2>
          <h3 >
            Target specific audience
          </h3>
          <p className="whom">
          You can reach a highly specific audience, tap into new markets globally and expand your reach. 
          </p>
        </div>
      </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
  <div className="fourbox">
          <h2>02</h2>
          <h3> Increase brand awareness</h3>
          <p className="whom">
            With over 2 billion users across the globe, you can connect with a vast audience on the world’s largest social platform.
          </p>
        </div>
      </div>


  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
   <div className="fourbox">
         <h2>03</h2>
         <h3>Boost click-through rate</h3>
         <p className="whom">
            With your ad reaching millions of Facebook users, there are high chances of getting more clicks.
          </p>
        </div>
      </div>

  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12">
  <div className="fourbox">
  <h2>04</h2>
       <h3>  Increase sales</h3>
    
       <p className="whom">
            Features like Facebook shops and dynamic product ads allow you to provide a seamless shopping experience to your customers.
          </p>
        </div>
      </div>
      </div>
      </div>
 </section>
<section
  className="theme-2 creatopy-content"
  style={{ paddingTop: 96, paddingBottom: 96 }}
>
  <div className="container">
    <div className="content-wrapper">
      <div className="content content-right-media">
        <div className="row align-items-center">
          <div className="col-lg-5 order-lg-2 text-center text-lg-left content-md-bottom">
             <h2>Facebook ads- The quick way to achieve positive ROI</h2>
            <p className="description">
            Facebook's ultra-specific targeting allows precise audience targeting by showing your ads to people who are likely to purchase your products, thus minimizing wasted ad spend and increased costs. At Whizamet, we can help you maximize the impact of every ad dollar spent with stunning ads that convert and campaign strategies that boost your conversions.
            </p>
            
          </div>
          <div
            className="col-lg-7 order-lg-1 text-center text-lg-left"
            style={{ direction: "rtl" }}
          >
          <img src={creatopy} className='creatopy' alt='' />
           
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section className='facebook-two'>
<div className="container">
<div className="row">
<div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
<div className='Personalized'>
       <h3 class="mainsubheading">Our services</h3>
       <h2> Ads That Deliver Real Results, Not Just Likes</h2>
     <p className='fbtext'>While Facebook is the perfect platform to grow your brand, you need the right strategies for maximum reach. <br></br> Our online marketing agency can help you with it.</p>
     </div>
     </div>
     </div>
  <div className="row">
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
             <div className="service-content">
           <h3 className="title">Facebook ads targeting</h3>
          <p className="description">
          We have the expertise to put you directly in front of your target audience based on their likes and interests through targeted ads.
          </p>
        </div>
        </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
      <div className="service-content">
         <h3 className="title">Facebook remarketing ads</h3>
          <p className="description">
          We will create an advanced remarketing strategy to bring your past website visitors back and turn them into loyal customers.
          </p>
        </div>
     
    </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
      <div className="service-content">
          <h3 className="title">Facebook lead ads</h3>
          <p className="description">
          We can set up campaigns to help you collect new information from fresh prospects via Facebook ads.
          </p>
        </div>
    </div>
  </div>
  <div className="row">
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <div className="service-content">
           <h3 className="title">Facebook geo-fencing ads</h3>
          <p className="description">
          Our geo-fencing ads allow businesses to show their ads to users located in specific geographic areas.
          </p>
        </div>
    
    </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <div className="service-content">
          <h3 className="title">Facebook demographic targeting</h3>
          <p className="description">
          Want your ads to target a specific age group? Our experts will ensure that your ads appear in front of your relevant audience.
          </p>
             </div>
    </div>
    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12">
         <div className="service-content">
             <h3 className="title"> Dynamic product ads</h3>
          <p className="description">
          With this campaign, we strive to increase the sales of your e-commerce store by showing products based on their behaviour.
          </p>
        </div>
      </div>
    </div>
  </div>

</section>
<section className='facebook-three'>
    <div className='container'>
        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <h3 class="mainsubheading">Our difference </h3>
     <h2>We specialize in creating revenue-generating campaigns </h2>
     <p class="content-width22">We create campaigns that generate maximum clicks to grow your website traffic and build your brand. We do this by: </p>
     <ul className='creates'>
      <li>Understanding your business goals</li>
      <li>Examining your past campaigns and performance</li>
      <li>Targeting the right audience with the best strategies</li>
      <li>Constantly monitoring the ad campaign performance </li>
      <li>Tweaking low-performing campaigns</li>
     </ul>
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='fbimge'>
                <img src={fb} className='fbresults' alt='' />
            </div>
        </div>
    </div>
</section>


<Serivcecontactform />

<Footer_v1 />
</>
)
}

export default FaceBook





