
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sales from '../../images/Sales.png';
import Strategy from '../../images/Strategy.png';
import Digital from '../../images/Digital.png';
import campaign from '../../images/campaign.png';
import writing from '../../images/writing.png';
import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'slick-carousel';

const Bnrdupeslider = () => {

  useEffect(() => {
    var imh = $("#animatedHeading");
    var titleSubs = imh.find("slick-active");
    
    if (imh.length) {
      imh.slick({
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: 2,
        centerPadding: "5px",
        draggable: false,
        infinite: true,
        pauseOnHover: false,
        swipe: false,
        touchMove: false,
        vertical: true,
        speed: 1000,
        autoplaySpeed: 2000,
        useTransform: true,
        cssEase: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
        adaptiveHeight: false
      });
    
      // On init
      $(".slick-dupe").each(function (index, el) {
        $("#animatedHeading").slick("slickAdd", "<div>" + el.innerHTML + "</div>");
      });
    
      // Manually refresh positioning of slick
      imh.slick("slickPlay");
    }
    
  }, []);
    return (
<>
<div class="container">
<div className="outer-slider">
<div class='carousel-banner-vertical'>
<div class="hero__title" id="animatedHeading">
<div class="slick-dupe">
  <div className="imh">
 <span class="hero__title-misc  |  animate">
{/* <span className="one">
<img src={Digital} alt="" className="Sales" />
</span> */}
Web Development  <br />
<p>Innovate your web presence with cutting-edge technologies and trends.</p>
   </span>
    </div>
    </div>
  
    <div class="slick-dupe">
  <div className="imh">
 <span class="hero__title-misc  |  animate">
{/* <span className="one">
<img src={Sales} alt="" className="Sales" />
</span> */}
Branding Services<br />
<p>Build impactful brand presence and drive increased ROI for your business.</p>
   </span>
    </div>
    </div>
 
    <div class="slick-dupe">
  <div className="imh">
 <span class="hero__title-misc  |  animate">
{/* <span className="one">
<img src={Strategy} alt="" className="Sales" />
</span> */}
Performance Marketing  <br />
<p>Boost your conversion rates with our strategic organic and paid ads expertise.</p>
   </span>
    </div>
    </div>

    <div class="slick-dupe">
  <div className="imh">
 <span class="hero__title-misc  |  animate">
{/* <span className="one">
<img src={Sales} alt="" className="Sales" />
</span> */}
BPO/KPO <br />
<p>Optimize sales and ROI and reduce operational costs with our robust BPO/KPO services. </p>
   </span>
    </div>
    </div>

</div>
</div>
</div>
</div>
</>
)
}

export default Bnrdupeslider

