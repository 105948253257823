import React from 'react';
import Footer_v1 from '../global-components/footer';

import Faqhome from '../section-components/faqhome';
import question from '../../images/question.png';
import mission from '../../images/mission.webp';


const AboutN1 = () => {
    return (
<>

   
   <section className="about" >
  <div className='container'>
  <div className='row'>
   <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <h3 class="mainsubheading">Our story </h3>
     <h2 className='B2B'>The Whizamet journey </h2>
     <p className='content'>Founded by two friends Poonam and Dharam, who recognized the need for brands to usher in the digital era, Whizamet had humble beginnings in a small cabin in the year 2016. With a shared dream and unwavering commitment, they set out to provide holistic marketing solutions, thus redefining the way businesses connect with their audiences. Over the years, Whizamet has evolved with its clients, broadening the spectrum of its services​​ to empower brands to thrive in the digital age. </p>
     <p className='content'>As Whizamet grew, its culture transformed into a relentless determination to exceed expectations. With every project, we strive to turn obstacles into opportunities for our success. We have the brightest minds in technology who leave no stone unturned to transform ordinary ideas into extraordinary realities.  </p>
    
     
    </div>
     <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
     <img src={mission} alt='' className='mission' />
    </div>
  </div>
 </div>
</section>
 </>
)
}

export default AboutN1

