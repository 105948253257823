import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FaqDispatch extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Are your dispatch services suitable for small businesses with a limited number of trucks?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Absolutely! Our truck dispatch services can be tailored to businesses of all sizes. Whether you have a small fleet with few trucks or a large one, our services can be customized to meet your specific needs and budget.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How do your dispatch services reduce the burden of owner-operators? 
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                         
                            <p>Our truck dispatch services help owner-operators by managing their backend operations. We help them find top-paying loads, negotiate with brokers for better rates, handle paperwork, ensure timely payments and provide them endless opportunities to maximize their earnings. </p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do your dispatchers handle unexpected issues such as breakdowns or road closures?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our dispatch services are equipped to handle emergencies. Our dedicated dispatchers will provide immediate assistance in case of breakdowns, connecting you with nearby repair services. Furthermore, they will reroute you efficiently if there are road closures or unexpected obstacles, minimizing delays.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How can your truck dispatching company help me find the highest-paying load?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our dispatchers have access to multiple load boards that allow them to find the highest-paying loads that match your route preferences. Also, they are in constant touch with brokers to find suitable loads for you. .</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        What kind of support is available while I'm on the road? 
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our dispatch services provide continuous support to drivers, providing regular route updates, assisting with breakdowns or unexpected situations and giving necessary information for successful deliveries.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default FaqDispatch