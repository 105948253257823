import React, { Component } from 'react';


class Faqseocity extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        How do you adapt your SEO strategies to algorithm updates from search engines like Google?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We closely monitor algorithm updates and industry trends to adapt our strategies accordingly. Our agile approach includes continuous analysis, testing, and optimization to maintain and improve rankings amidst algorithm changes.
</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        What strategies do you employ to improve local SEO and attract customers in specific geographic areas?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <div className="ltn__video-img alignleft">
                            </div>
                            <p>Our local SEO strategies focus on optimizing Google My Business profiles, acquiring local citations, managing online reviews, and targeting geo-specific keywords. We also implement location-based landing pages and local content marketing to enhance visibility in target regions.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How quickly can I expect to see results from your ORM services.?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>The timeline for results varies based on your current online reputation and the strategies implemented. Typically, you can start noticing improvements within a few weeks of initiating our ORM strategies.                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How do you handle a reputation crisis?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>In the event of a reputation crisis, our team implements a predefined crisis management plan, swiftly addressing issues, communicating transparently with stakeholders, and mitigating the impact on your reputation.                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How do you create effective email campaigns that drive results?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We create effective email campaigns by crafting compelling subject lines and content, personalizing messages based on recipient data, designing visually appealing templates, optimizing for mobile responsiveness, and including clear CTAs. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqseocity