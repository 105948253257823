import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import cooperation from  '../../images/cooperation.png';
import faqvideo from '../../images/faqvideo.mp4';
import whitehat from '../../images/whitehat.png';
import Resultseo from  '../../images/Resultseo.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import guestposting from '../../images/guestposting.png';
import BackLinkBuilding from '../../images/BackLinkBuilding.png';
import bloging from '../../images/bloging.png';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import OnPage from '../../images/OnPage.png';
import dg from '../../images/dg.png'
import serc from '../../images/serc.png'
import marketing1 from  '../../images/marketing1.png';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';
import Faqseo from '../section-components/faqseo';
import Serivcecontactform from '../section-components/serivcecontactform';
import { Helmet } from 'react-helmet';

import seovideo from '../../images/seovideo.mp4';


import cs01 from '../../images/cs01.webp';
import scs01 from '../../images/scs01.png';
import scs02 from '../../images/scs02.png';
import scs03 from '../../images/scs03.png';
import scs04 from '../../images/scs04.png';
import scs05 from '../../images/scs05.png';
import scs06 from '../../images/scs06.png';

import cs04 from '../../images/cs04.webp';



const Seo = () => {
  
    return (
<>
<NavbarV3 />
<Helmet>
<title> Best SEO Agency in India | Best SEO Services in India</title>
<meta name="description" content="Whizamet is the best SEO agency in India to grow your online visibility, boost website traffic and increase conversions. Contact us for the best SEO services." />
</Helmet>

<section className='seobner'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
            <div className='seocontentbg1'>
            <h1>Search Engine Optimization</h1>
                <p className='bnrtext'>Missing out on leads and conversions? <br />Hire our SEO experts today!</p>
                {/* <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div> */}
            </div>
            <video src={seovideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='seovid'> </video>
            </div>

        </div>

        
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>


<section className='seoserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our organic tactics</h3>
     <h2>SEO tactics we deploy to improve<br /> SEO rankings</h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">In-depth research</h3>
      <p className="description">
        Our SEO team conducts comprehensive research to gain vital insights about your business, competitors & customers.
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Website audit</h3>
      <p className="description">
        We perform your website’s comprehensive analysis to identify technical issues, broken links & improvement areas.
      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Link building</h3>
      <p className="description">
      Search Engine Optimization drives 1000% more traffic compared to organic social media.
      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Keyword research</h3>
      <p className="description">
      We conduct extensive research to identify relevant, high-performing keywords that can easily rank on search engines.
      </p>
          <div className="service-icon">
          <img src={BackLinkBuilding} alt='' className='localseo' />
           </div>
    </div>
  </div>
</div>
           <div className="row" id='adipisicing'>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">URL optimization</h3>
      <p className="description">
        Our team employs technical and creative tactics like optimizing URLs, and meta tags of your website to improve its visibility.
      </p>
          <div className="service-icon">
          <img src={bloging} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">

      <h3 className="title">Content creation</h3>
      <p className="description">
        We create engaging SEO-friendly content by embedding the right keywords to attract your target audience.
      </p>
          <div className="service-icon">
          <img src={KeywordResearch} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Testing</h3>
      <p className="description">
        Through consistent testing, we track your SEO performance to ensure that your website is driving organic traffic. 
      </p>
          <div className="service-icon">
          <img src={Technical} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-3 col-xl-3 col-md-6 col-12">
    <div className="serviceBox">
      <h3 className="title">Reporting</h3>
      <p className="description">
        We produce monthly reports to gauge our performance and to figure out areas that might require improvement.
      </p>
          <div className="service-icon">
          <img src={OnPage} alt='' className='localseo' />
           </div>
    </div>
  </div>
</div>
     
</div>
</section>

<section className='seoresult'>
    <div className='container'>
        <div className='row'>
            <div className='col- xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 text-center'>
            <div className='reslt'>
            <h3 class="mainsubheading">Our results</h3>
     <h2>Driving conversions, <br />not just clicks</h2>
     <p class="content">Whizamet ensures that every website visit makes an impact, thus helping you build meaningful connections by converting your website traffic into leads and leads into sales.Over time, our clients have reaped the benefits of better traffic, increased leads, higher sales and remarkable ROI with us.</p>
            </div>
            </div>
        </div>

        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <picture>
  <source srcset={cs01} media="(max-width: 767px)" />
  <source srcset={scs01} />
  <img src={scs01} alt="" className='ban' />
</picture>

                {/* <img src={scs01} className='seoresults' alt='' /> */}
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <picture>
  <source srcset={cs04} media="(max-width: 767px)" />
  <source srcset={scs02} />
  <img src={scs02} alt="" className='ban' />
</picture>
                {/* <img src={scs04} className='seoresults' alt='' /> */}
            </div>
        </div>


        
        <div className='row paddingtp'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={scs03} className='seoresults' alt='' />
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={scs04} className='seoresults' alt='' />
            </div>
        </div>


        <div className='row paddingtp'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={scs05} className='seoresults' alt='' />
            </div>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={scs06} className='seoresults' alt='' />
            </div>
        </div>


    </div>
</section>


{/* <section className='seo-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>

   <video src={seo1video}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='googlevid'> </video>
      </div>
</section> */}


<section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>


<section className='topkeywords'>
  <div className='container'>
    <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">Our areas of expertise</h3>
     <h2>Mastering SEO across <br />various verticals</h2>
      </div>
    </div>
    <div className='row' id='toprow'>
    <div className='col-xxl-5 col-xl-6 col-lg-9 col-md-8 col-12'>
<ul className='keywordlist'>
  <li>Local SEO</li>
  <li>E-Commerce SEO</li>
  <li>International SEO</li>
  <li>Site migrations</li>
  <li>Pinterest SEO</li>
  <li>YouTube SEO</li>
  <li>Voice Search SEO</li>
  <li>Mobile SEO</li>
</ul>

</div>
    <div className='col-xxl-7 col-xl-6 col-lg-3 col-md-4 col-12' id='rking'>
<div className='keywrd'>
  <div className='keycircle'>
  <img src={serc} className='serc' alt='' />
  <h4>Keywords<br />
Ranking</h4>
  </div>
</div>
<div className='ranking-list'>
      <ul>
        <li>2</li>
        <li>2</li>
        <li>5</li>
        <li>7</li>
        <li>7</li>
        <li>9</li>
        <li>9</li>
        <li>10</li>
        <li>11</li>
      </ul>
    </div>
  <img src={dg} className='dg' alt='' />
    </div>
        </div>
    <div className='row'>
    <div className='scroe text-center'>
      <div className="row justify-content-center">
    <div className="col-xxl-8 col-xl-8 col-md-8 col-12 text-center p-0 mt-3 mb-2">
      <div className="form-card px-0 pt-4 pb-0 mt-3 mb-3">
        <h2 id="heading">Check your website’s performance</h2>
         <form
          id="msform"
          action="seoscroe.php"
          method="post"
          encType="multipart/form-data"
        >
          {/* progressbar */}
          <ul id="progressbar" >
            <li className="active" id="account">
              <strong>Step-1</strong>
            </li>
            <li id="payment">
              <strong>Image</strong>
            </li>
           
          </ul>
          
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>{" "}
          <p className='page-target'>Identify your website’s SEO problems at no cost. Please enter your URL<br /> to know how your website is performing.</p>
       {/* fieldsets */}
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-12">
                  <h2 className="fs-title">URL</h2>
                </div>
                  </div>
                <input type="text" id="fname" name="website" placeholder="www.sample.com" required />
            </div>{" "}
            <input
              type="button"
              name="next"
              className="next action-button"
              defaultValue="Next"
            />
          </fieldset>
          <fieldset>
            <div className="form-card">
              <div className="row">
                <div className="col-12">
                <input type="text" id="fname" name="name" placeholder="Name" required />
                <input type="tel" name="phone" placeholder="Contact" required />
                <input type="email" name="email" placeholder="Your Email" required />
                <input type="text" id="fname" name="website" placeholder="www.sample.com" required />
                </div>
                
              </div>
            
            </div>
       
            <input
              type="submit"
              value="Submit"
               className="next action-button"
              defaultValue="Submit"
            />{" "}
            <input
              type="button"
              name="previous"
              className="previous action-button-previous"
              defaultValue="Previous"
            />
          </fieldset>
        
        </form>
      </div>
    </div>
  </div>
</div>

    </div>
    </div>
</section>
<section className='seochoose'>
  <div className='container'>
    <div className='row text-center'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <h3 class="mainsubheading">Our benefits</h3>
     <h2>Discover “The Whizamet” Advantage</h2>
      </div>
    </div>
    <div className='row'>
      <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
      <div className='seofeatures'>
        <img src={whitehat} className='network' alt='' />
        <p>We strictly use white-hat SEO  <br />practices to produce the best results.</p>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
      <div className='seofeatures'>
        <img src={cooperation} className='network' alt='' />
        <p>We have experience in handling  <br />projects for businesses across various niches.</p>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
      <div className='seofeatures'>
        <img src={Resultseo} className='network' alt='' />
        <p>We have a quick turnaround  <br />time. You can count on us to get exceptional results in minimum time.</p>
        </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-12'>
      <div className='seofeatures'>
        <img src={marketing1} className='network' alt='' />
        <p>Under one roof, you get access <br />to a wide gamut of digital marketing services.</p>
        </div>
      </div>
    </div>
  </div>
</section>
 <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your questions answered</h3>
          <h2>Frequently asked questions</h2>
          <Faqseo />
         
         
        </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Seo

