import React from 'react';
import question from '../../src/images/question.png';
import NavbarV3 from './global-components/navbar-v3';
import Footer_v1 from './global-components/footer';
import Page_header from './global-components/page-header';
import Faqhome from './section-components/faqhome';


const faqs = () => {

    return (
<>
<NavbarV3 />
<Page_header headertitle="Frequently Asked Questions" subheader="FAQ" />
<section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
          <img src={question} alt='' className='question' />
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>FAQ</h3>
          <h2>Frequently Asked Questions</h2>
          <Faqhome />
         
        </div>
      </div>
    </div>
   </section>

<Footer_v1 />
</>
)
}

export default faqs

