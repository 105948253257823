import React, { useRef, useState } from "react";
import shop1 from  "../../images/shop1.png";
import shop2 from "../../images/shop2.png";
import shop3 from "../../images/shop3.png";
import shop4 from "../../images/shop4.png";
import shop5 from "../../images/shop5.png";
import shop6 from "../../images/shop6.png";
import shop7 from "../../images/shop7.png";
import shop8 from "../../images/shop8.png";



// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules


const Shopifyctools = () => {
  return (
    <>
      <div className="Shopifyctools">
            <div className="container-fluid">
            <div className="Shopifyctools-wrapper align-items-center">
         <div className="Shopifyctools-slider">
          <div className="swiper-Shopifyctools">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 0,
                          },
                          1024: {
                    slidesPerView: 4,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                           1440: {
                    slidesPerView: 5,
                        spaceBetween: 20,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={shop1} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop2} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop3} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={shop4} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop5} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop6} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop7} alt="" className="shop1" />
          </SwiperSlide>
          <SwiperSlide>
          <img src={shop8} alt="" className="shop1" />
          </SwiperSlide>
         
                                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Shopifyctools;