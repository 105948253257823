import React from 'react';
import dharam from '../../images/dharam.webp';
import poonam from '../../images/Poonam.png';
import harpreet from '../../images/harpreet.png';
import gagan from '../../images/gagan.png';
import khush from '../../images/khush.png';
import sahil from '../../images/sahil.png';


const AboutN2 = () => {
    return (
<>

   
   <section className="about-two" >
  <div className='container'>
  <div className='row'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
     <img src={dharam} alt='' className='dharam' />
    </div>
   <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='textleft'>
    <h3 class="mainsubheading">Founder/Director</h3>
     <h2 className='B2B'>Dharamjeet Singh</h2>
     <p className='content'>July 2016 – Our founder poured his 15 years of experience in International
trade & marketing into one brand that he believed will uplift all other
businesses in reaching the masses through a professional brand
reputation in the virtual world. ‘Singh is King’ must be the phrase written
on his cabin wall as he has developed respectful and admirable
relationships with his clients as well as employees. With the stardust of
curiosity, he also keeps sprinkling work ethics on his crew members.</p>
<p className='content'>At Whizamet, we feel great leaders build a greater environment that
delivers the greatest results. </p>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='textright'>
    <h3 class="mainsubheading">Co-Founder</h3>
     <h2 className='B2B'>Poonam Rai</h2>
     <p className='content'>Our co-founder carries the purse of positivity like the moonlight
of a full moon. She has done wonders in Performance marketing,
in simple words ‘She’s great with numbers!’ She recently got selected
for “Goldman Sachs 10,000 Women” an IIM Bangalore initiative for
exuberant Women entrepreneurs. </p>
<p className='content'>Normal bosses “How’s the work going?” meanwhile our co-founder,
“How are you feeling today?” That’s what makes her different. She
keeps on pushing her team to do better and believes in them even
when they all are a little doubtful. Our favorite quality of hers is that
she always does what her heart says but still does it all right! </p>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
     <img src={poonam} alt='' className='poonam' />
    </div>
    <div className='ceo2-left'>
  <h2>Our Leader</h2>
</div>
  </div>
 </div>
</section>
<section className='bottom22-serivces'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
      <div className='srv-cta'>
      <h3 class="mainsubheading animated fadeIn"> Meet our team</h3>
      <h2> <span class="colororange"> The faces </span> behind our success</h2>
   
  <section id="cards">
    <div className="container py-2">
      <div className="row pb-4">
       
      </div>
      {/* cards */}
      <div className="row">
        <div className="col-lg-3 col-md-6 mb-4 pt-5">
          <div className="card shadow-sm border-0">
            <div className="card-body">
              <div className="user-picture">
                <img src={harpreet} className="shadow-sm rounded-circle"  height={130}
                  width={130} alt="" />
              </div>
              <div className="user-content">
                <h5 className="text-capitalize user-name">Harpreet Kaur</h5>
                <h4 className='icon-text'><a href="/">  Sr. Web Designer</a>
                </h4>
                <hr/>
                <p className="small text-muted mb-0">
                Our passionate web designer with 7 years of experience in cutting-edge web technologies. 
               </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 pt-5">
          <div className="card shadow-sm border-0">
            <div className="card-body">
              <div className="user-picture">
              <img src={sahil} className="shadow-sm rounded-circle"  height={130}
                  width={130} alt="" />
               
              </div>
              <div className="user-content">
                <h5 className="text-capitalize user-name">Sahil Madan</h5>
                <h4 className='icon-text'><a href="/">Sr. Graphic Designer/ video Editor</a></h4>
                <hr/>
                <p className="small text-muted mb-0">
                Our talented graphic designer and the creative mind behind our eye-catching graphics. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 pt-5">
          <div className="card shadow-sm border-0">
            <div className="card-body">
              <div className="user-picture">
              <img src={khush} className="shadow-sm rounded-circle"  height={130}
                  width={130} alt="" />
                           </div>
              <div className="user-content">
                <h5 className="text-capitalize user-name">Khushwant Singh</h5>
                <h4 className='icon-text'><a href="/">Sr. SEO Manager</a></h4>
                <hr/>
                <p className="small text-muted mb-0">
                Our seasoned SEO expert and the driving force behind our clients' increased website rankings. 
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4 pt-5">
          <div className="card shadow-sm border-0">
            <div className="card-body">
              <div className="user-picture">
              <img src={gagan} className="shadow-sm rounded-circle"  height={130}
                  width={130} alt="" />
                           </div>
              <div className="user-content">
                <h5 className="text-capitalize user-name">Gagandeep Singh</h5>
                <h4 className='icon-text'><a href="/">Sr. PPC executive

</a></h4>
              <hr/>
                <p className="small text-muted mb-0">
                Our PPC expert who excels in creating impactful paid ad strategies that fuel growth for our diverse clientele. 
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
    {/* /cards */}
  </section>


       
      </div>
    </div>
  </div>
</div>
   </section>
 </>
)
}

export default AboutN2

