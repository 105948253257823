import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Faqsmm extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        What social media channels do you have expertise in?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We specialize in offering social media marketing services for a wide range of platforms, including but not limited to Facebook, Instagram, Twitter, LinkedIn, and YouTube. We offer different strategies for every channel to meet your business goals.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        Why should I choose Whizamet for social media marketing services?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We are your one-stop destination for social media services due to our expertise in social media marketing, customized approach, and commitment to delivering results. We focus on building meaningful connections with your audience, driving engagement, and achieving your business objectives through strategic social media tactics.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do you tailor your social media marketing strategies to align with the unique goals and services of my brand? 
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We conduct a thorough analysis of your website's goals, target audience, and unique value propositions and then create a customized social media marketing strategy by incorporating platforms and content that align with the interests and needs of your audience. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How do you analyze the success of your social media campaigns?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We gauge the success of social media campaigns through metrics like click-through rates, conversion rates, engagement rates and ROI. Furthermore, we use advanced analytical tools to measure the effectiveness of our campaigns.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        How long does social media marketing take to deliver results?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>The results of social media marketing campaigns vary according to your goals, industry and channels. Usually, it takes a few weeks to see increased engagement and brand awareness. However, crucial results such as website traffic and conversions may take a few months.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqsmm