import React, { Component } from 'react';
import blogimg from '../../images/blogimg.png';
import NavbarV3 from '../global-components/navbar-v3';
import Footer_v1 from '../global-components/footer';
import Page_header from '../global-components/page-header';
import BlogGrid from '../blog-components/blog-grid';
import { Helmet } from 'react-helmet';


class Blog extends Component {
  render() {
    let HeaderTitle = this.props.headertitle;
    let publicUrl = process.env.PUBLIC_URL+'/'
    let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle
    let CustomClass = this.props.customclass ? this.props.customclass : ''


    return (
      <div>
        <NavbarV3 />
          <Helmet>
             <title>Blog - Whizamet Services</title>
         </Helmet>
        <div className="dlab-bnr-inr overlay-primary bg-pt" style={{ backgroundImage: "url(" + blogimg + ")" }}>
           <h2>Blog</h2>
        </div>
          <BlogGrid />
          <Footer_v1 />
      </div>
		
    )
  }
}

export default Blog;
