
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import facebook2 from  '../../images/Facebook2.png';
import youtube2 from  '../../images/youtube2.png';
import Insta4 from '../../images/Insta4.png';
import Twtter3 from '../../images/Twtter3.png';
import tiktok5 from  '../../images/tiktok5.png';
import linkedin6 from '../../images/linkedin6.png';
import smm1 from '../../images/smm1.png';
import faqvideo from '../../images/faqvideo.mp4';
import smm2 from '../../images/smm2.png';
import Faqsmm from '../section-components/faqsmm';
import question from '../../images/question.png';
import phones from '../../images/phones.png';
import circle from  '../../images/circle.png';
import archi from '../../images/archi.png';
import smmbnr from '../../images/smmbnr.webp';
import Industriesserve from '../section-components/clients/client1';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Helmet } from 'react-helmet';

import smmimg from '../../images/smmimg.png';
import Serivcecontactform from '../section-components/serivcecontactform';


const Socialmediamarketing = () => {
  let i = 2;

  $(document).ready(function () {
    var radius = 200;
    var fields = $(".itemDot");
    var container = $(".dotCircle");
    var width = container.width();
    radius = width / 2.5;
  
    var height = container.height();
    var angle = 0,
      step = (2 * Math.PI) / fields.length;
    fields.each(function () {
      var x = Math.round(
        width / 2 + radius * Math.cos(angle) - $(this).width() / 2
      );
      var y = Math.round(
        height / 2 + radius * Math.sin(angle) - $(this).height() / 2
      );
      if (window.console) {
        console.log($(this).text(), x, y);
      }
  
      $(this).css({
        left: x + "px",
        top: y + "px"
      });
      angle += step;
    });
  
    $(".itemDot").click(function () {
      var dataTab = $(this).data("tab");
      $(".itemDot").removeClass("active");
      $(this).addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + dataTab).addClass("active");
      i = dataTab;
  
      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 1) * 36) + "deg)",
        transition: "2s"
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 1) * 36 + "deg)",
        transition: "1s"
      });
    });
  
    setInterval(function () {
      var dataTab = $(".itemDot.active").data("tab");
      if (dataTab > 6 || i > 6) {
        dataTab = 1;
        i = 1;
      }
      $(".itemDot").removeClass("active");
      $('[data-tab="' + i + '"]').addClass("active");
      $(".CirItem").removeClass("active");
      $(".CirItem" + i).addClass("active");
      i++;
  
      $(".dotCircle").css({
        transform: "rotate(" + (360 - (i - 2) * 36) + "deg)",
        transition: "2s"
      });
      $(".itemDot").css({
        transform: "rotate(" + (i - 2) * 36 + "deg)",
        transition: "1s"
      });
    }, 5000);
  });
  
    return (
<>
<NavbarV3 />
<Helmet>
<title>Social Media Marketing Agency Service in India</title>
<meta name="description" content="Searching for the best Social media Marketing agency in India? Whizamet provides top-notch SMM services to help you boost brand awareness and grow your online presence." />
</Helmet>


<section className='smmbner'>
    <div className='container'>
        <div className='row just'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Social Media Marketing</h1>
                <p className='bnrtext'>Social media marketing services that delve deep into engagement, interaction, and meaningful connections to grow your online presence.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={smmimg} className='goshoing' alt='circle' />
            
            </div>        
        </div>
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>

{/* 
<section className='smmbner'>
    <div className='container'>
        <div className='row'>
<div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Social Media Marketing</h1>
                <p className='bnrtext'>Social media marketing services that delve deep into engagement, interaction, and <br /> meaningful connections to grow your online presence.</p>
                <img src={smmbnr} className='smmbnr' alt='smmbnr' />
               
            </div>
            </div>
        </div>
        
    </div>
    <div className='left-seoshape1'></div>

  <img src={circle} className='seocircle2' alt='circle' />
    
</section> */}

<section className='smmserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our services</h3>
     <h2>Solutions designed to maximize your <br />social media presence</h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={facebook2} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">Facebook advertising</h3>
      <p className="descriptions">
      Boost your brand awareness and drive conversions through impactful Facebook advertising campaigns.
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
  <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={youtube2} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">Youtube advertising</h3>
      <p className="descriptions">
      We'll boost your brand on the second-largest search engine with captivating videos and strategic ads.
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
  <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={Insta4} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">Instagram advertising</h3>
      <p className="descriptions">
        Let’s create a buzz around your brand and turn your Instagram followers into loyal customers with our stunning campaigns.
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
  <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={Twtter3} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">Twitter advertising</h3>
      <p className="descriptions">
        From catchy tweets to visually appealing graphics, we craft compelling campaigns to make your brand trend.
      </p>
      </div>
      </div>
      <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
  <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={tiktok5} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">Tik tok advertising</h3>
      <p className="descriptions">
        We specialize in creating captivating videos, trendy challenges, and catchy tunes that will make your brand the talk of the platform.
      </p>
      </div>    
      </div>
      <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
  <div className="serviceBoxs2">
    <div className="services-icons">
          <img src={linkedin6} className='smmicon' alt='' />
           </div>
   
      <h3 className="titles">LinkedIn advertising</h3>
      <p className="descriptions">
        We create thought-provoking articles, engaging content, and visually appealing infographics to help you stand out on LinkedIn.
      </p>
      </div>    
      </div>
      
</div>
</div>
</section>
<section className='smmresult'>
    <div className='container'>
        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
            <div className='reslt'>
            <h3 class="mainsubheading">Our results</h3>
     <h2>Tailored campaigns, <br />real results</h2>
     <p class="content">Our social media campaigns are tailored to meet your objectives. From enhancing brand visibility to boosting engagement and driving sales, we deliver real, measurable results for our clients.</p>
     <div class="custom-ol">
<ol>
<li className='smmlist'>3x customer engagement</li>
<li className='smmlist'>50% increase in traffic </li>
<li className='smmlist'>60% higher conversion rate</li></ol></div>
<p className='strong'>Let's drive tangible results<span class="colororange"> for your brand</span></p>

</div>
</div>

            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id='loader-img'>
          <img src={phones} className='phones' alt=''/>
 
<div class="book">
<div class="gap"></div>
<div class="pages">
<div class="page"></div>
<div class="page"></div>
<div class="page"></div>
<div class="page"></div>
<div class="page"></div>
<div class="page"></div>

</div>
<div class="flips">
<div class="flip flip1">
<div class="flip flip2">
<div class="flip flip3">
<div class="flip flip4">
<div class="flip flip5">
<div class="flip flip6">
<div class="flip flip7"></div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

            </div>
        </div>
    </div>
</section>
  <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
{/* <section className='seotools'>
    <div className='container'>
        <div className='row'>
           
        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12">
    <div className="seo2-left">
      <div className="seo2-left-inner">
        <div>
          <h3 className="mainsubheading animated fadeIn">Tools we use</h3>
          <h2>
          Our suite of <br /> cutting-edge tools
          </h2>
          <p className="content">
          From advanced analytics to engagement boosters, we use the latest innovations to deliver the best outcomes for our clients.{" "}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12' id='newseo'>
                <div className='row' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                    <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                </div>
                <div className='row' id='fully'>
                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                    <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                </div>
                <div className='row' id='fully'>
                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                        <img src={archi} alt='' className='localseo' />
                        </div>
                    </div>
                      <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                    <div className='stoll'>
                    <img src={gg} alt='' className='localseo' />
                        </div>
                    </div>
                </div>
                </div> 
        </div>
    </div>
    
</section> */}
<section className="iq-features">

    <div className="container">
    <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our benefits</h3>
     <h2>Why choose Whizamet for social media marketing services?</h2>
     <span>At Whizamet, we believe social media marketing is not about posting content, it’s about building meaningful connections.Our < br/> approach is about YOU—your brand, goals, and unique voice.
</span>
     </div>
        </div>
       <div className="row align-items-center">
        <div className="col-lg-3 col-md-12" />
        <div className="col-lg-6 col-md-12">
          <div className="holderCircle">
            <div className="round" />
            <div className="dotCircle">
              <span className="itemDot active itemDot1" data-tab={1}>
                <i className="fa fa-clock-o" />
                <span className="forActive" />
              </span>
              <span className="itemDot itemDot2" data-tab={2}>
                <i className="fa fa-comments" />
                <span className="forActive" />
              </span>
              <span className="itemDot itemDot3" data-tab={3}>
                <i className="fa fa-user" />
                <span className="forActive" />
              </span>
              <span className="itemDot itemDot4" data-tab={4}>
                <i className="fa fa-tags" />
                <span className="forActive" />
              </span>
              <span className="itemDot itemDot5" data-tab={5}>
                <i className="fa fa-upload" />
                <span className="forActive" />
              </span>
              <span className="itemDot itemDot6" data-tab={6}>
                <i className="fa fa-briefcase" />
                <span className="forActive" />
              </span>
            </div>
            <div className="contentCircle">
              <div className="CirItem title-box active CirItem1">
              <p className='whyslide'>
              We have tailored solutions for unique challenges.
                </p>
                <i className="fa fa-clock-o" />
              </div>
              <div className="CirItem title-box CirItem2">
              <p className='whyslide'>
                Get bespoke social media strategies designed for you.
              </p>
                <i className="fa fa-comments" />
              </div>
              <div className="CirItem title-box CirItem3">
                
              <p className='whyslide'>
              We have diverse marketing solutions under a single roof. 
                </p>
                <i className="fa fa-user" />
              </div>
              <div className="CirItem title-box CirItem4">

              <p className='whyslide'>
              Stay informed about campaign performance at every step.
                </p>
                <i className="fa fa-tags" />
              </div>
              <div className="CirItem title-box CirItem5">
                
              <p className='whyslide'>
              Our clients are at the forefront of every decision we make.
                </p>
                <i className="fa fa-upload" />
              </div>
              <div className="CirItem title-box CirItem6">
               
                <p className='whyslide'>
                Get high-quality services that won’t break your bank. 
                </p>
                <i className="fa fa-briefcase" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-12" />
      </div>
    </div>
  </section>

{/* <section className='why'>
<div className="container">
<div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Smo Services</h3>
     <h2>Why choose Us</h2>
     <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy < br/>text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
</span>
     </div>
      
    </div>
    <div className="row align-items-center">
      <div className="col-sm-6 col-lg-4 mb-2-9 mb-sm-0">
        <div className="pr-md-3">
          <div className="text-center text-sm-right mb-2-9">
            <div className="mb-4">
            <i className="fa fa-clock-o"id='mbicons' />
            </div>
            <h4 className="sub-info">Residential Cleaning</h4>
            <p className="display-30 mb-0">
              Roin gravida nibh vel velit auctor aliquetenean sollicitudin,
              lorem qui bibendum auctor.
            </p>
          </div>
          <div className="text-center text-sm-right">
            <div className="mb-4">
            <i className="fa fa-tags" id='mbicons'/>
            </div>
            <h4 className="sub-info">Commercial Cleaning</h4>
            <p className="display-30 mb-0">
              Gravida roin nibh vel velit auctor aliquetenean sollicitudin,
              lorem qui bibendum auctor.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 d-none d-lg-block">
        <div className="why-choose-center-image">
        <i className="fa fa-user" id='mbicons'/>
        </div>
      </div>
      <div className="col-sm-6 col-lg-4">
        <div className="pl-md-3">
          <div className="text-center text-sm-left mb-2-9">
            <div className="mb-4">
            <i className="fa fa-upload" id='mbicons'/>
            </div>
            <h4 className="sub-info">Washing services</h4>
            <p className="display-30 mb-0">
              Nibh roin gravida vel velit auctor aliquetenean sollicitudin,
              lorem qui bibendum auctor.
            </p>
          </div>
          <div className="text-center text-sm-left">
            <div className="mb-4">
            <i className="fa fa-briefcase" id='mbicons'/>
            </div>
            <h4 className="sub-info">Carpet cleaning</h4>
            <p className="display-30 mb-0">
              Vel proin gravida nibh velit auctor aliquetenean sollicitudin,
              lorem qui bibendum auctor.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


</section> */}
<section className='smm-digital'>
    <div className='container'>
        <div className='row'>
            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='reslt'>
            <h3 class="mainsubheading">Make your business thrive</h3>
            <h2 className="boldheading"> Drive brand awareness. Expand your reach.  <br></br>Increase conversions.</h2>

</div>
</div>

            <div className='col- xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <img src={smm1} className='smmcircul' alt='' />
            <img src={smm2} className='smmcircul2' alt='' />

         </div>
            
        </div>
    </div>
</section>

<section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered</h3>
          <h2>Frequently asked questions</h2>
          <Faqsmm />
         
         
        </div>
      </div>
    </div>
   </section>

   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Socialmediamarketing

