import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import ContactD from '../section-components/contactd';
import ContForm from '../section-components/contform';
import ContMap from '../section-components/contmap';
import phone from '../../images/phone.png';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import faemail from '../../images/famail.png';
import faphone from '../../images/faphone.png';
import pin from '../../images/pin.png';
import phone2 from '../../images/Phone2.png';
import { Helmet } from 'react-helmet';

function Contactus1() {
    return (
        <div>
            <NavbarV3 />
            <Helmet>
                <title>Contact Us - Whizamet</title>
            </Helmet>
            <section className='contbner1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
                            <div className='contactus1'>
                                <h1>Contact Us</h1>
                                <p className='bnrtext'>Let’s connect to discuss your marketing goals  </p>
                            </div>
                        </div>

                        <ul className="box-area">
                            <li className='myconactbox'>
                                <img src={phone} className='phone' alt='' />
                            </li>
                            <li className='myconactbox'><img src={phone2} className='phone2' alt='' /></li>
                        </ul>

                    </div>

                    <section className='contform'>
                        <div className='box'>
                            <div className='row'>

                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12" >
                                    <div className="row">
                                        <div className="col-lg-11">
                                            <div className="ltn__contact-address-item1 box-shadow">
                                                <img src={faemail} alt='' />
                                                <h3 className='contaddd'>Email Us</h3>
                                                <p className='contaddd'><a href="mailto:reach@whizamet.com">reach@whizamet.com</a>
                                                </p>
                                            </div>


                                        </div>
                                        <div className="col-lg-11">
                                            <div className="ltn__contact-address-item1 box-shadow">
                                                <img src={faphone} className='phone' alt='' />
                                                <h3 className='contaddd'>Call Us</h3>
                                                <ul class="calll">
                                                    <li className='contadddd'>For Business : <a href="tel:+91 9876769096">+91 9876769096</a></li>
                                                    <li className='contadddd'>For Training : <a href="tel:+91 9779444969">+91 9779444969</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-11">
                                            <div className="ltn__contact-address-item1 box-shadow">
                                                <img src={pin} className='location' alt='' />
                                                <h3 className='contaddd'>Office Address</h3>
                                                <p className='contaddd'>IT-C-7, Netsmartz center, 6th Floor, Sector 67 SAS Nagar, Punjab-160062</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-12' id='fomnew'>
                                    <div className="ltn__form-box contact1-form-box box-shadow white-bg">
                                        <h4 className="title-22">Let’s Grow Your <span class="colororange"> brand </span> Together</h4>
                                        <form action="contact.php" method="post" enctype="contact/form-data">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-name ">
                                                        <input type="text" name="name" placeholder="First Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-name ">
                                                        <input type="text" name="lastname" placeholder="Last Name" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-email ">
                                                        <input type="email" name="email" placeholder="Email" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-phone">
                                                        <input type="text" name="phone" placeholder="Phone" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-name ">
                                                        <input type="text" name="companyname" placeholder="Company Name" required />
                                                    </div>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-item-name ">
                                                        <input type="text" name="postition" placeholder="Postition" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-item input-item-textarea">
                                                <textarea name="message" placeholder="Message" defaultValue={""} />
                                            </div>

                                            <div className="btn-wrapper mt-0">
                                                <button className="btnc theme-btn-c btnc-effect-1 text-uppercase" type="submit">Send Us</button>
                                            </div>
                                            <p className="form-messege mb-0 mt-20" />
                                        </form>
                                    </div>


                                </div>


                            </div>

                        </div>
                    </section>
                </div>
            </section>
            {/* <div className='last-content'>
                <h3>
                    Let’s grow your <span class="colororange"> brand </span> Together
                </h3>
                <p>
                    Ready to grow your business online? Feel free to start a conversation about your marketing goals, and we’ll let you know how we can work <br></br> together to achieve your marketing goals.{" "}
                </p>
            </div> */}
            <ContMap />
            <Footer_v1 />
        </div>
    )
}

export default Contactus1;
