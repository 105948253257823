
import React from 'react';
import about1 from '../../images/about1.webp';
import about3 from '../../images/about3.webp';
import about4 from'../../images/about4.webp';
import about5 from '../../images/about5.webp';
import about6 from '../../images/about6.webp';
import about7 from '../../images/about7.webp';
import about8 from '../../images/about8.webp';
import about11 from '../../images/about11.webp';
import about12 from '../../images/about12.webp';
import about13 from '../../images/about13.webp';
import about14 from '../../images/about14.webp';
// import about15 from '../../images/about15.png';
import team1 from '../../images/team1.jpg';
import team2 from '../../images/team2.jpg';
import team3 from '../../images/team3.webp';
import team4 from '../../images/team4.jpg';
import team5 from '../../images/team5.webp';

const AboutGallery = () => {
    return (

<div className="container overflow-hidden py-6">
<div className="Personalized">
   
    <h2>
    We work and celebrate 
    </h2>
    <p className="bnrtext">
    Every milestone achieved is a reason to celebrate. Have a sneak peek into our <br />moments of fun, laughter, and celebration. 
  </p>
  </div>
    <div className="grid-wrapper col">
      <div className="lc-block">
        <img
          className="img-fluid rounded"
          src={about1}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="tall lc-block">
        <img
          className="img-fluid rounded"
          src={about3}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="wide lc-block">
        <img
          className="img-fluid rounded"
          src={about4}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
        <img
          className="img-fluid rounded"
          src={about5}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="tall lc-block">
        <img
          className="img-fluid rounded"
          src={about7}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="big lc-block">
        <img
          className="img-fluid rounded"
          src={about8}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
        <img
          className="img-fluid rounded"
          src={about6}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="wide lc-block">
        <img
          className="img-fluid rounded"
          src={about1}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="big lc-block">
        <img
          className="img-fluid rounded"
          src={about11}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="tall lc-block">
        <img
          className="img-fluid rounded"
          src={about12}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
        <img
          className="img-fluid rounded"
          src={about13}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
        <img
          className="img-fluid rounded"
          src={about14}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
      <img
          className="img-fluid rounded"
          src={team1}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
      <img
          className="img-fluid rounded"
          src={team2}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="wide lc-block">

      <h2 className='gallery'>Passionate about building brands? 
      <div class="culture">
      <a class="theme-btn btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Join Us</a>
      </div>
      
      </h2>
    
      </div>
    
      <div className="lc-block">
      <img
          className="img-fluid rounded"
          src={team3}
          alt=""
          loading="lazy"
        />
      </div>
      <div className="lc-block">
      <img
          className="img-fluid rounded"
          src={team4}
          alt=""
          loading="lazy"
        />
      </div>
      
      <div className="lc-block">
      <img
          className="img-fluid rounded"
          src={team5}
          alt=""
          loading="lazy"
        />
      </div>
      
     
     
    </div>
  </div>
)
}

export default AboutGallery
