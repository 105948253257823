import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Ormverticaltabs extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="liton__wishlist-area">
	  <div className="container">
		<div className="row">
		  <div className="col-lg-12">
			{/* PRODUCT TAB AREA START */}
			<div className="ltn__product-tab-area">
			  <div className="container">
				<div className="row">
				  <div className="col-xxl-6 col-lg-6 col-xl-6 col-md-6">
					<div className="ltn__tab-menu-list mb-50">
					  <div className="nav">                                            
                      <a className="active show tbcv" data-bs-toggle="tab"  href="#ltn_tab_1_1">Our valuable experience</a>
                      	<a className="tbcv" data-bs-toggle="tab" href="#ltn_tab_1_2">Constant monitoring</a>
						<a className="tbcv" data-bs-toggle="tab" href="#ltn_tab_1_3">Service for every business</a>
						<a className="tbcv" data-bs-toggle="tab" href="#ltn_tab_1_4">Value for money solutions</a>
																						
					  </div>
					</div>
				  </div>
				  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6" id='Hence'>
					<div className="tab-content">
					  <div className="tab-pane fade active show" id="ltn_tab_1_1">
						<div className="ltn__myaccount-tab-content-inner">
						<div className='content-matrix'>
                            <p>With years of experience in ORM, we possess the expertise to build your brand’s positive image.</p>
                        </div>
						</div>
					  </div>
					  <div className="tab-pane fade" id="ltn_tab_1_2">
						<div className="ltn__myaccount-tab-content-inner">
                        <div className='content-matrix'>
                            {/* <h4>Bounce rate</h4> */}
                            <p>We don’t merely post positive reviews but also monitor your online mentions to know the buzz about your business.</p>
                        </div>
						</div>
					  </div>
					  <div className="tab-pane fade" id="ltn_tab_1_3">
						<div className="ltn__myaccount-tab-content-inner">
						<div className='content-matrix'>
						  <p>Whether you’re a well-established business or a budding startup, our services are tailored for every business regardless of size.</p>
						  </div>
						</div>
					  </div>
					  <div className="tab-pane fade" id="ltn_tab_1_4">
						<div className="ltn__myaccount-tab-content-inner">
						<div className='content-matrix'>
                        {/* <h4>Bounce rate</h4> */}
						  <p>With Whizamet, you can protect your image without breaking the bank. We offer cost-effective ORM services to foster long-term relations with our clients.</p>
						 </div>
						 </div>
					  </div>
							 							 
					</div>
				  </div>
				</div>
			  </div>
			</div>
			{/* PRODUCT TAB AREA END */}
		  </div>
		</div>
	  </div>
	</div>
	
        }
}

export default Ormverticaltabs