import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform';
import circle from  '../../images/circle.png';
import guestposting from '../../images/guestposting.png';
import landingbnr from '../../images/landingbnr.webp';
import landingfocus from '../../images/landingfocus.webp';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import Landingpagetool from '../section-components/landingpagetool';
import Conversation from '../../images/Conversation.webp';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Industriesserve from '../section-components/clients/client1';

const Landingpage = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title> Landing Page Design Services | Creative Landing Page Design</title>
<meta name="description" content="At Whizamet, we specialize in providing result-oriented landing page design services. We design creative and high-converting landing pages to propel your online growth." />
</Helmet>
<section className='Landingpage'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
            <div className='seocontentbg1'>
            <h1>Landing Page Design</h1>
                <p className='bnrtext'>Grow your sales, generate better leads and maximize your revenue with our high-quality landing pages.  </p>
               
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Contact Us</a></div>
            </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <img src={landingbnr} className='landingbnr' alt='' />
            </div>
            
        </div>
      
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='shopiseclast'>
    <div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">What we do</h3>
     <h2>We create Landing page designs that <br />get you more conversions </h2>
     <p class="content">Not attracting enough visitors? Losing money on campaigns? Worry not. With our conversion-focused landing page, you can turn your visitors into conversions. Whether you want to transform your existing design into a personalized landing page or create a brand-new one, we'll take care of it. </p>

     </div>
    
        </div>
            </div>
            <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
    <div className="serviceBox">
      <h3 className="title">Custom landing pages </h3>
      <p>
      Looking to create a bespoke landing page from scratch? We can provide you with a beautifully designed & expertly coded landing page.
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
    <div className="serviceBox">
      <h3 className="title">Responsive design  </h3>
      <p>
      Our landing pages are designed to fit various devices and screen sizes- be it a desktop computer, laptop, tablet or smartphone. 
      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12">
    <div className="serviceBox">
      <h3 className="title">PSD to landing page conversion </h3>
      <p>
      If you already have your design files in formats like PSD, we can convert them into fully functional landing pages. 
      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
 </div>
     
    </div>
</section>
<section className='landing-page2'>
<div className='container'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h2>No templates. Only bespoke designs. </h2>
                <p class="content">Landing pages aren’t one size fits all in our approach. We believe that every brand is unique and deserves a digital presence that reflects its business essence and individuality. That’s why we don’t use templates but create landing pages that are as distinctive as your brand. From layout to colors and everything in between, our every detail is purposefully chosen to capture your audience’s attention. </p>
                <p class="content">If you want a landing page design that does not conform to templates but portrays the distinct essence of your brand, Whizamet is your go-to place. </p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <img src={Conversation} className='Conversation' alt='' />
            </div>
        </div>
    </div>
</div>
</section>
  <section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='landing-focus'>
    <div className='container'>
    <div className='row'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
    <h2>Our difference: Features that help us stand out from the crowd </h2>
    <p className='content'>No more boring landing pages and wasted budget because our expert team will design landing pages that will maximize your conversions.</p>
    <ul className='Visitors'>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We design high-quality landing pages that turn clicks into customers. </li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We possess more than 5 years of experience in creating revenue-generating landing pages. </li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Our quick turnaround ensures swift delivery without compromising quality. </li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>We won’t stop unless we design a landing page that leaves you satisfied. </li>
        <li><span><i class="fa fa-angle-right" aria-hidden="true"></i></span>Whizamet is your all-in-one digital marketing agency. With a team of experts in marketing & creativity, we can manage every aspect of your project.  </li>
    </ul>
  </div>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
  <img src={landingfocus} className='landingfocus' alt='' />
  </div>
  </div>
    </div>
</section>
<section className='shopi-expert'>
    <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading"> Our tech expertise </h3>
     <h2>We're masters of cutting-edge technologies </h2>
     <p class="content">We stay ahead of the latest technologies to provide the most advanced solutions. </p>
     </div>
        </div>
      <Landingpagetool />
            </div>
    </div>
</section>



<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Landingpage

