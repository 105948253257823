import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import question from '../../images/question.png';
import Faqorm from '../section-components/faqorm';
import badgepic from '../../images/badgepic.png';
import sendmail from '../../images/sendmail.png';
import increase from '../../images/increase.png';
import ormr2 from '../../images/ormr2.webp';
import guestposting from '../../images/guestposting.png';
import bloging from '../../images/bloging.png';
import ormr1 from '../../images/ormr1.webp';
import ormimg1 from '../../images/ormimg1.jpg';
import ormimg2 from '../../images/ormimg2.jpg';
import ormimg3 from '../../images/ormimg3.jpg';
import ormimg4 from '../../images/ormimg4.jpg';
import KeywordResearch from '../../images/KeywordResearch.png';
import Technical from '../../images/Technical.png';
import localseo from '../../images/localseo.png';
import EcommerceSEO from '../../images/EcommerceSEO.png';
import Ormverticaltabs from '../section-components/ormverticaltabs';
import Serivcecontactform from '../section-components/serivcecontactform';
import faqvideo from '../../images/faqvideo.mp4';
import { Helmet } from 'react-helmet';

const Onlinereputation = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Online Reputation Management Services - Whizamet</title>
<meta name="description" content="Whizamet is your one-stop shop for reliable and result-driven online reputation management services to build your positive online image. Get in touch with us today." />
</Helmet>
<section className='Onlinereputation'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Online Reputation Management</h1>
                <p className='bnrtext'>Your brand is not defined by your words. It’s defined by what other people say about it. <br />We specialize in making people say positive things about your brand.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>
       <div className='row' id='reputation'>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='reputationbox'>
            <h4>Build trust</h4>
            <p>ORM helps you maintain a positive brand image & build trust among customers.</p>
            <img src={badgepic} alt='' className='badgepic' />
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='reputationbox'>
            <img src={sendmail} alt='' className='sendmail' />
            <h4>Build connection</h4>
            <p>Through positive interactions and responses, ORM strengthens the connection with the audience.</p>
           </div>
        </div>
        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
        <div className='reputationbox'>
            <h4>Build value</h4>
            <p>ORM helps brands build long-term value, helping them attract more customers. </p>
            <img src={increase} alt='' className='badgepic' />
           </div>
        </div>
       </div>
    </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='ormserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our services</h3>
     <h2>Solutions that help you boost<br />your brand image</h2>
     </div>
    
        </div>
            </div>
            <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Assessing your reputation</h3>
      <p className="description">
      We begin the ORM process by reviewing your brand reputation, its positioning & competitors. Also, we diligently tackle both positive and negative reviews regarding your brand. Our foremost goal is to resolve your customer issues to satisfy them.
      </p>
          <div className="service-icon">
          <img src={localseo} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Social media engagement</h3>
      <p className="description">
      We interact with your target audience on various social media platforms as well as proactively respond to their comments, thus building positive interactions crucial for strengthening your online presence.
      </p>
          <div className="service-icon">
          <img src={EcommerceSEO} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Crisis management</h3>
      <p className="description">
      Our ORM experts specialize in designing a robust crisis management plan to handle potential reputation issues. We’re always on hand to respond swiftly and effectively to minimize damage to your brand reputation.
      </p>
          <div className="service-icon">
          <img src={guestposting} alt='' className='localseo' />
           </div>
    </div>
  </div>
 </div>
           <div className="row" id='adipisicing'>
           <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Influencer management</h3>
      <p className="description">
      Our team collaborates with influencers or influential organizations to create positive narratives for your brand. Through collaborations, we aim to improve your brand image, thus connecting you with a wider target audience.
      </p>
          <div className="service-icon">
          <img src={bloging} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Constant monitoring</h3>
      <p className="description">
      Through constant monitoring of your online actions, our ORM experts strive to make sure that your image stays clean. We keep a close watch on every platform that is crucial for your company so that you reach closer to your goals.
      </p>
          <div className="service-icon">
          <img src={KeywordResearch} alt='' className='localseo' />
           </div>
    </div>
  </div>
  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-12">
    <div className="serviceBox">
      <h3 className="title">Performance management</h3>
      <p className="description">
      We constantly track the key performance indicators like sentimental analysis, online visibility, customer feedback and market presence to gauge our strategy’s effectiveness. We swiftly tweak our strategies if needed.
      </p>
          <div className="service-icon">
          <img src={Technical} alt='' className='localseo' />
           </div>
    </div>
  </div>
 
</div>
     
</div>
</section>

<section className='onlinerpu'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-12' id='pc-first'>
             <div class="onlinerpu-left">
               <h3 class="mainsubheading">Our advantages</h3>
              <h2>Why choose Whizamet as your online reputation partner</h2> 
              <p class="content">Brand reputation is earned through hard work. We will protect it from getting tarnished. Our unwavering commitment to safeguarding your hard-earned reputation is what makes us an ideal choice. Choose us as your online reputation partner to be assured that your brand will always stay protected from negative reviews.</p>
              
               </div>
                </div> 
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-12'>
               <Ormverticaltabs />
                </div> 
            </div>
          
        </div>
 </section>

 {/* Callcenter section */}
 <section className='bottom-serivces'>
<div className='container'>
  <div className='row'>
    <div className='col-xxl-12 col-xl-12 col-md-12 col-12'>
      <div className='srv-cta'> 
        <h2> Let’s talk <span className="slimtext">about your brand goals </span></h2>
        <p className='contenthome'>No matter what business goals you have, we have creative solutions for all your challenges. <br />Together, let’s shape the future of your brand. </p>
<div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="tel:+91 9876769096" tabindex="0">Call Us Now</a></div>
      </div>
    </div>
  </div>
</div>
   </section>


 <div className='ormresult'>
  <div className='container'>
    <div className='row'>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
      <h3 className='mainsubheading'>Our results</h3>
          <h2>Maximizing ROI with our proven ORM solutions.</h2>
      </div>
      <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <p class="content">At Whizamet, we believe there are no shortcuts to creating your brand reputation. It takes time, dedication and decades of experience to get a favourable impression of a business in the minds of your target audience.</p>
        <p class="content">With customer satisfaction at the core of our approach, we devise tailor-made strategies for our client’s individual needs that help them increase sales and ROI.</p>
      </div>
    </div>
    <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <img src={ormimg1} className='ormr1' alt='' />
    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <img src={ormimg2} className='ormr1' alt='' />
    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <img src={ormimg3} className='ormr1' alt='' />
    </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <img src={ormimg4} className='ormr1' alt='' />
    </div>
    </div>
  </div>
 </div>

 <section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered</h3>
          <h2>Frequently asked questions</h2>
          <Faqorm />
          
        </div>
      </div>
    </div>
   </section>
<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Onlinereputation

