import React, { useRef, useState } from "react";

import b2bicon1 from '../../images/b2bicon1.png';
import widenbusinessreach from '../../images/widenbusinessreach.png';
import ehancedbrandvisiblity from '../../images/ehancedbrandvisiblity.png';
import increasesales from '../../images/increasesales.png';
import morequalifiedleads from '../../images/morequalifiedleads.png';
import improveeffeciency from '../../images/improveeffeciency.png';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const B2btools = () => {
  return (
    <>
      <div className="B2btools">
            <div className="container-fluid">
            <div className="B2btools-wrapper align-items-center">
         <div className="B2btools-slider">
          <div className="swiper-B2btools">
          <Swiper
                          slidesPerView={1}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 1,
                       spaceBetween: 30,
                         },
                         580: {
                       slidesPerView: 2,
                       spaceBetween:20,
                         },
                         700: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         768: {
                        slidesPerView: 3,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1200: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1241: {
                    slidesPerView: 3,
                        spaceBetween: 10,
                           },
                           1440: {
                    slidesPerView: 3,
                        spaceBetween: 0,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={widenbusinessreach} className='b2bicon1' alt='' />
            <h4>Widen <br />business<br /> reach </h4>
             </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={improveeffeciency} className='b2bicon1' alt='' />
            <h4>Higher<br /> conversion <br />rate </h4>
            </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={morequalifiedleads} className='b2bicon1' alt='' />
            <h4>More <br />qualified <br />leads </h4>
                     </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={increasesales} className='b2bicon1' alt='' />
            <h4>Increased <br />sales </h4>
                       </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={ehancedbrandvisiblity} className='b2bicon1' alt='' />
            <h4>Enhanced <br />brand <br />visibility </h4>
                  </div>
            </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='round-container'>
            <div class="round-content">
            <div className='b2b-box'>
            <img src={improveeffeciency} className='b2bicon1' alt='' />
            <h4>Improved<br /> efficiency <br />& productivity </h4>
                     </div>
            </div>
            </div>
          </SwiperSlide>
       
         
         
         
                                    
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default B2btools;