import React from 'react';
import about1 from '../../images/about1.webp';
import about3 from '../../images/about3.webp';
import about4 from '../../images/about4.webp';
import about5 from '../../images/about5.webp';
import about6 from '../../images/about6.webp';
import about7 from '../../images/about7.webp';
import about8 from '../../images/about8.webp';
import abm from '../../images/abm.webp';

const AboutN5 = () => {
    return (
<>

   <section className="about-culture" >
  <div className='container'>
  <div className='row'>
  <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
  <ul className='aboutc'>
    <li className='aboutcc'>
    <img src={about1} alt='' className='about1' />
    </li>
    <li className='aboutcc'>
    <img src={about3} alt='' className='about3' />
    </li>
    <li className='aboutcc'>
    <img src={about4} alt='' className='about4' />
    </li>
    <li className='aboutcc'>
    <img src={about5} alt='' className='about5' />
    </li>
    <li className='aboutcc'>
    <img src={about6} alt='' className='about6' /> 
    </li>
    <li className='aboutcc'>
    <img src={about7} alt='' className='about7' /> 
    </li>
    <li className='aboutcc'>
    <img src={about8} alt='' className='about8' /> 
    </li>
  </ul>
  <img src={abm} alt='' className='abm' /> 
    </div>
   <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'id="culture">
    <h3 class="mainsubheading">Our work culture </h3>
     <h2 className='B2B'>Cultivating a Positive Work Culture </h2>
     <p className='content'>We believe that our employees are our biggest asset. Therefore, our work culture is centred around empowering them in every possible way. In our thriving workplace, we ensure that our employees excel, grow and contribute to our collaborative success. </p>

</div>
</div>
 </div>
</section>
 </>
)
}

export default AboutN5






