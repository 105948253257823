import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import webVideo from '../../images/webVideo.mp4';

class WebVideo extends Component {

    render() {

        
    return <section className="vediosec">
	<div className="container">
		<div className='row'>
			
		<h3 class="mainsubheading animated fadeIn">OUR EXPERTISE</h3>
			<h2 className='vedioh'>Industries</h2>
	{/* HTML5 VIDEO */}
	<video src={webVideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='pseudo-partial-border'></video>
	
	</div>	
	</div>
	</section>

        }
}

export default WebVideo