import React, { Component } from 'react';


class Faqhome extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Why opt for Whizamet as your digital marketing company?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>There are various reasons why you must choose Whizamet as your digital marketing company:
                            </p>
                            <ul>
                                <li><strong>Proven results: </strong> We have a proven track record of delivering exceptional results for our clients. </li>
                                <li><strong>Transparency: </strong> We believe in having open and clear communication at every step. </li>
                                <li><strong>Data-driven decisions:</strong> We leverage data and analytics to make informed decisions.</li>
                                <li><strong>Cutting-edge technologies:</strong> We use the latest tools & technologies for best results. </li>
                                <li><strong>Global Experience:</strong> We possess a wealth of experience in managing successful campaigns across global markets.</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How is your digital marketing strategy different from your competitors?  
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                           
                            <p>We do not believe in a one-size-fits-all approach. At Whizamet, we develop customized digital marketing strategies that align with the business goals and unique requirements of our amiable clients. This customized approach ensures that we deliver outstanding results for our clients that result in increased ROI and business growth. </p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do your web designs lead to better user engagement and conversions?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our web development and designing team create conversion-focused websites using cutting-edge technologies that lead to enhanced user engagement and conversions. We focus on intuitive layouts, responsive and mobile-friendly design, easy navigation, clear calls to action and visually appealing interfaces to keep your website visitors engaged with your websites. 
                            </p>
                            <p>We strategically position compelling CTAs throughout the entire website to propel users to take the desired action. Moreover, we provide 24/7 support to ensure that your website remains functional, secure and optimized at all times. 
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        Why should I partner with Whizamet to develop my brand identity?

                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our team comprises experienced branding and design experts who understand the fundamentals of brand storytelling and excel in translating concepts into visually compelling designs. Our design experts delve deep to understand your goals, competitors and target audience and then create a robust brand identity by leveraging their designing skills that reflect your business and its vision and motivate your audience to interact with you. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        Why should I outsource my contact center requirements to Whizamet?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Outsourcing your call center needs to Whizamet Services offers you a multitude of advantages. They are:</p>
                            <ul>
                                <li>Significant cost savings and increased ROI </li>
                                <li>Streamlined operations, reduced administrative workload and infrastructural hassles. </li>
                                <li>Access to specialized expertise with vast experience.  </li>
                                <li>Increased sales and conversion rates </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqhome