import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import cargo from '../../images/cargo.webp';
import vectord from '../../images/vectord.png';
import moneysaving from '../../images/moneysaving.png';
import I2 from '../../images/I2.png';
import i1 from  '../../images/i1.png';
import rsd from '../../images/rsd.png';
import s222 from '../../images/s222.png';
import FaqDispatch from '../section-components/faqdispatch.js';
import cargo22 from '../../images/cargo22.png';
import ids from '../../images/ids.png';
import BillingInvoicing from '../../images/BillingInvoicing.png';
import BookingLoads from '../../images/BookingLoads.png';
import CreditChecks from '../../images/CreditChecks.png';
import Factoring from '../../images/Factoring.png';
import LumperDetention from '../../images/LumperDetention.png';
import PaperworkCompletion from '../../images/PaperworkCompletion.png';
import faqvideo from '../../images/faqvideo.mp4';
import worldwideshipping from '../../images/worldwideshipping.png';
import groundshipping from '../../images/groundshipping.png';
import seashipping from '../../images/seashipping.png';
import { Helmet } from 'react-helmet';
import Serivcecontactform from '../section-components/serivcecontactform.js';

const Dispatch = () => {
  
  
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best Dispatch Service Provider in India</title>
<meta name="description" content="Efficient dispatch services for seamless operations. Timely, reliable, and tailored solutions for your business. Elevate your logistics with our dispatch expertise." />
</Helmet>
<section className='mybanner-two'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12'>
            <div className='bnr-content'>
            <h3 className='Expert'>Truck Dispatch Services</h3>
                <h1 className='Integrated'>Reliable and Trustworthy Truck Dispatch Services </h1>
                <p className='value-driven'>No more broker calls, paperwork hassles or invoice stress. We will handle it all for you. </p>
                <div className='bnrbtn'>
                <a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a>
                </div>
                </div>
              </div>
             </div>
            </div> </section>
            <section className='seoserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our Services</h3>
     <h2>Truck dispatch solutions that take off your load </h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={BookingLoads} className='smmicon2' alt='' />
         
           </div>
   
      <h3 className="title2">Booking loads </h3>
      <p className="description2">
      No more empty hauls. Our dedicated team works tirelessly to find the highest-paying loads and expertly negotiates with brokers to help you get top-dollar rates. 
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={PaperworkCompletion} className='smmicon2' alt='' />
         
           </div>
   
      <h3 className="title2">Paperwork completion</h3>
      <p className="description2">
      Struggling with paperwork? From filling carrier packets to rate confirmations, we’ve got you covered for every paperwork hassle. 
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={CreditChecks} className='smmicon2' alt='' />
           </div>
   
      <h3 className="title2">Credit checks </h3>
      <p className="description2">
      We conduct thorough credit checks on every broker we work with to ensure smooth transactions and timely payments. 
      </p>
      </div>    
  </div>
  
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={Factoring} className='smmicon2' alt='' />
           </div>
   
      <h3 className="title2">Factoring </h3>
      <p className="description2">
      We offer factoring services to help you get immediate funds to cover your fuel, maintenance and other expenses without delays. 
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={BillingInvoicing} className='smmicon2' alt='' />
           </div>
   
      <h3 className="title2">Billing & invoicing </h3>
      <p className="description2">
      No more billing headaches. We ensure that your invoices are submitted quickly to the factoring company for timely payment. 
      </p>
      </div>    
  </div>
  <div className="col-xxl-4 col-xl-4 col-md-6 col-12">
    <div className="serviceBox22">
    <div className="services-icons2">
    <img src={LumperDetention} className='smmicon2' alt='' />
           </div>
   
      <h3 className="title2">Lumper/detention </h3>
      <p className="description2">
      Whether you want assistance with unloading or managing detention times, we will make sure that you deliver on time. 
      </p>
      </div>    
  </div>
      
</div>
</div>
</section>
<section className='homeportfolio'>
   <div className='container'>
   <div className='row'>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <h3 class="mainsubheading animated fadeIn">Optimized routes. Increased revenue</h3>
    <h2>Maximize profits with every mile you travel </h2>
    <p class="content">With us, there will be no empty miles holding you back. Our expert dispatchers will find the most efficient routes, ensuring that you’re always on the road, delivering loads and increasing your dollars with every mile.</p>
       </div>
    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
    <div class="circle">
    <img src={vectord} className='vectord' alt='' />
    <img src={moneysaving} className='moneysaving' alt='' />
    <h3 className='saving'>Overhead <br></br>Savings</h3>
    <img src={I2} className='I2' alt='' />
    <h3 className='saving1'>Low Rates</h3>
    <img src={i1} className='i1' alt='' />
    <h3 className='saving2'>Stress-Free <br></br>Freight</h3>
    </div>
    <img src={ids} className='ids' alt='' />
    </div>
    </div>
    </div>
    </section>
    <section className='cargo'>
 
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'id='cain'>
            <div className='bnr-content'>
            <h3 className='truckheading'>Experience stress-free hauling</h3>
                <h2 className='trackheading1'>You focus on the haul while we take care of the load </h2>
                <p className='truckp'>We’re here to provide you with a smooth hauling experience with our expert dispatch services. </p>
                <div class="customt">

  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bcircle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg><p className='t1'>No forced dispatch </p>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bcircle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg><p className='t1'>Transparent and upfront pricing</p>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bcircle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg> <p className='t1'>Dedicated dispatchers to assist you</p>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bcircle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
</svg><p className='t1'>Round-the-clock assistance</p>
</div>
                
                </div>
                
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
              <img src={cargo} className='cargo1' alt='' />
              <img src={cargo22} className='cargo12' alt='' />
              </div>
                 </div>
                  </div>
  

    </section>

    <section className='seoserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">our benefit</h3>
     <h2>We relieve you from the stress <br />of managing backend tasks.</h2>
     </div>
        </div>
            </div>
            <div className="row">
  <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
  <div className='map'>
  <img src={rsd} className='rsd' alt='' />

  
  </div>
  </div>     
</div>
</div>
</section>
<section className='slideanimation'>
<div className='container'>
    <div className='row'>
<div className="col-xxl-6 col-xl-6 col-md-6 col-12">
<h2>Dispatch solutions for every truck in your fleet </h2>
    <p class="content">Whether it’s heavy-duty rigs, dry vans, reefers, or flatbeds, we have you covered with our dispatch services. Get in touch to ensure smooth operations, timely deliveries and stress-free trucking. </p>
  
  </div>
  <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
  <div className="loader">
<span className='sb'><h3 className='slide1'>worldwide<br></br>shipping</h3>
<img src={worldwideshipping} className='' alt='' />
</span>

<span className='sb'><h3 className='slide1'>Ground<br></br>shipping</h3>
<img src={groundshipping} className='' alt='' />
</span>
<span className='sb'><h3 className='slide1'>Sea<br></br>shipping</h3>
<img src={seashipping} className='' alt='' />
</span>

</div>

  </div>

  </div>
  </div>

</section>
<section className='dispatchlast'>
<div className='container'>
    <div className='row'>
<div className="col-xxl-6 col-xl-6 col-lg- 12 col-md-12 col-12">
<img src={s222} className='s222' alt='' />
  
  </div>
  <div className="col-xxl-6 col-xl-6  col-lg- 12 col-md-12 col-12"id='space'>
  <h3 className='light-text'>Ready to </h3>
<h2 className='dark-text'>experience</h2>
<h3 div className='light-text2'> hassle-free driving?</h3>
<p className='white-text'>With us by your side, you can focus on the road ahead and embark on a hassle-free driving experience like never before. Contact us today. </p>
<button type="button" class="btn btn-outline-secondary">Get in touch</button>
  </div>

  </div>
  </div>

</section>
<section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
          {/* <img src={question} alt='' className='question' /> */}
          <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
          <FaqDispatch />
                 </div>
      </div>
    </div>
   </section>

   <Serivcecontactform />
<Footer_v1 />
</>
)
}

export default Dispatch

