import React from 'react';
import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import circle from  '../../images/circle.png';
import webdesigning from '../../images/webdesigning.png';
import gposting from  '../../images/gposting.webp';
import branding from '../../images/branding.png';
import Bgflyinglogos from '../section-components/bgflyinglogos';
import Bgflyinglogos2 from '../section-components/bgflyinglogos2';
import Serivcecontactform from '../section-components/serivcecontactform';
import Industriesserve from '../section-components/clients/client1';
import BrandAwareness from '../../images/BrandAwareness.png';
import DomainAuthority from '../../images/DomainAuthority.png';
import ImproveRankings from '../../images/ImproveRankings.png';
import QualityTraffic from '../../images/QualityTraffic.png';
import { Helmet } from 'react-helmet';
import guestpostvideo from '../../images/guestpostvideo.mp4';

const GuestPosting = () => {
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best Guest Posting Service - Whizamet</title>
<meta name="description" content="At Whizamet, we provide the best guest posting services to earn quality backlinks from trusted and credible websites, drive more traffic, and improve your rankings." />
</Helmet>
<section className='GuestPosting'>
<div className='container'>
        <div className='row'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Guest Post Services</h1>
                <p className='bnrtext'>We help brands grow with high-quality guest posts that <br />boost visibility and traffic.</p>
                <div class="bnrbtn"><a class="theme-btn-1 btn btn-effect-1 text-uppercase" href="/contact-us" tabindex="0">Contact Us</a></div>
            </div>
            </div>
        </div>

        <div className='row' id='digi-reputation'>
        <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={ImproveRankings} className='whizserv' alt='' />
                </div>
                <h3>Improve rankings</h3>
                <hr />
             <p>With our guest post services, you can get quality backlinks from popular websites that will improve your search engine rankings.</p>
            
                </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={QualityTraffic} className='whizserv' alt='' />
                </div>
                <h3>Quality traffic</h3>
                <hr />
              <p>By guest posting on credible websites related to your niche, you’ll attract genuine leads for your business.</p>
            
                </div>
            </div> 
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={BrandAwareness} className='whizserv' alt='' />
                </div>
                <h3>Brand awareness</h3>
                <hr />
                <p>When your target audience sees your brand name on popular websites, they will become aware of your brand & offerings.</p>
              
                </div>
            </div>
            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
                <div className='whiz-digital-box'>
                <div className='digi-outer-img'>
                <img src={DomainAuthority} className='whizserv' alt='' />
                </div>
                <h3>Domain authority</h3>
                <hr />
              <p>Through guest posts, you can get quality backlinks from reputed websites that will help you build your domain authority.</p>
          
                </div>
            </div>        
        </div>
     
           </div>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle2' alt='circle' />
</section>
<section className='Scalable'>
    <div className='container'>
        <div className='row text-center'>
            <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <h3 className='mainsubheading'>Our services</h3>
          <h2>Top-tier guest post services that boost your SEO</h2>
          <p className='content'>Whizamet’s guest posting services provide a hassle-free approach to ensure your website links placement on credible websites, expand your network and improve your SEO efforts. Leverage our services to create a strong network of links that will help grow your website.</p>
            </div>
        </div>
        <div className='row'>
      <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
      <ul className="process">
  <li className="process__item">
  <span className="process__number">1</span>
       <span className="process__title">Website research</span>
    <span className="process__subtitle">
    We will help you identify top-quality websites by assessing their domain authority, traffic and relevance to your industry.
    </span>
  </li>
  <li className="process__item">
  <span className="process__number">2</span>
    <span className="process__title">Outreach services</span>
    <span className="process__subtitle">
    With our blogger outreach services, we will connect and pitch guest post ideas to selected websites.
    </span>
  </li>
  <li className="process__item">
  <span className="process__number">3</span>
    <span className="process__title"> Link placement</span>
    <span className="process__subtitle">
    Our expert writers will create premium guest posts for you, including relevant links back to your website.
    </span>
  </li>
  <li className="process__item">
  <span className="process__number">4</span>
     <span className="process__title">Guest post publishing</span>
    <span className="process__subtitle">
    We’ll reach out to the editors of the targeted websites to get the guest posts published with appropriate and relevant links.
    </span>
  </li>
</ul>
      </div>
    </div>
    </div>
</section>

<section className='digi-logos'>
  <div className='container'>
 <h3 class="mainsubheading animated fadeIn">Our Esteemed Customers</h3>
    <h2 className='Minimize'>Clients who trust us </h2>
   <p class="bnrtext">We’ve partnered with hundreds of clients ranging from budding startups to big giants. <br />Take a look at our valuable associations. </p>
   <div className='mobile-client'>
   <Industriesserve />
   </div>
      </div>
    <div className='logos-reviws'>
    <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  <br />
  <Bgflyinglogos2 />
  <br />
  <br />
  <Bgflyinglogos />
  <br />
  </div>
</section>
<section className='work-guest'>
    <div className='container'>
    <div className='row text-center'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Why us</h3>
     <h2>Exceptional services to enhance your online presence</h2>
     <p class="content">Every business is unique, and so are our services. Here, at Whizamet, we use our experience and expertise <br /> to understand your niche and create tailored strategies that deliver results.</p>
     </div>
        </div>
            </div>
            <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
             <h4>Keyword-focused content</h4>
            <p>Our content is optimised for relevant keywords. Quality is our topmost priority when it comes to posting and choosing websites.</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
               <h4>Authority & reliability</h4>
            <p>For guest posting, we only choose websites having high domain authority, organic traffic, and domain life.</p>
           </div>  
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-12'>
           <div className='E-Commerce-box'>
            <h4>SEO best practices</h4>
            <p>We follow the ethical and legal practices for keyword placements, outbound links, etc.</p>
           </div>  
            </div>
        </div> 
    </div>
</section>
<section className='drivwrn'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                <h2>No unethical tactics. Only relevant, white hat strategies</h2>
                <p class="content">At Whizamet, we adhere strictly to white hat strategies to boost search engine results. When we say “white hat strategies,” we mean that each link is generated using ethical and organic techniques that align with search engine guidelines.</p>
                <p class="content">We are focused not only on attracting visitors but also on building long-term relationships with your customers.</p>
                <p class="content">By choosing us as your guest post services partner, you’re choosing an agency that values your integrity and credibility.</p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12 text-center'>
           {/* <img src={gposting} className=' gposting' alt='' /> */}
          <video src={guestpostvideo}   
          autoPlay={"autoplay"}
          controls={"muted"}
          preLoad="auto"
          loop id='pseudo-partial-border-guestvid'>
         </video>
            </div>
        </div>
    </div>
</section>


<Serivcecontactform />
<Footer_v1 />
</>
)
}

export default GuestPosting

