import React, { useRef, useState } from "react";
import c2 from '../../../images/c2.png';
import c3 from '../../../images/c3.png';
import c4 from '../../../images/c4.png';
import c5 from '../../../images/c5.png';
import c6 from '../../../images/c6.png';
import c8 from '../../../images/c8.png';
import c9 from '../../../images/c9.png';
import c10 from '../../../images/c10.png';
import c11 from '../../../images/c11.png';
import c12 from '../../../images/c12.png';
import c13 from '../../../images/c13.png';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Industriesserve = () => {
  return (
    <>
      <div className="Industriesserve">
            <div className="container-fluid">
            <div className="Industriesserve-wrapper align-items-center">
         <div className="Industriesserve-slider">
          <div className="swiper-Industriesserve">
          <Swiper
                          slidesPerView={2}
                      spaceBetween={5}
                       pagination={{
                    clickable: true,
                      }}
                    navigation={true}
                       loop={'true'}
                     autoplay={{
                      delay: 4000,
                      disableOnInteraction: true,
                      
                                }}
                                modules={[Autoplay]}
                     breakpoints={{
                        320: {
                       slidesPerView: 2,
                       spaceBetween: 10,
                         },
                         580: {
                       slidesPerView: 3,
                       spaceBetween: 10,
                         },
                         700: {
                       slidesPerView: 4,
                       spaceBetween: 20,
                         },
                         768: {
                        slidesPerView: 4,
                       spaceBetween: 10,
                          },
                          1024: {
                    slidesPerView: 6,
                        spaceBetween: 50,
                           },
                           1241: {
                    slidesPerView: 6,
                        spaceBetween: 20,
                           },
                           1400: {
                    slidesPerView: 8,
                        spaceBetween: 20,
                           },
                              }}
              
                    className="mySwiper"
                               >
        
          <SwiperSlide>
            <img src={c6} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c8} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c9} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c10} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c11} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c13} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c2} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c3} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c4} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c5} alt="" className="c2" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={c12} alt="" className="c2" />
          </SwiperSlide>
         
    
                  
      </Swiper>
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </>
  );
}
export default Industriesserve;