import React, { Component } from 'react';


class Bpofaq extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        Why should I consider outsourcing call center services?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Outsourcing your call center services to a reputed offshoring services provider can provide access to skilled talent, advanced technology, state-of-art infrastructure, cost savings, increased sales, maximized ROI and the ability to scale your business rapidly. It allows companies to focus on their core business activities while the calling experts handle their customer interactions, driving customer satisfaction, increased sales and revenue growth.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How can I track the performance of my call center operations with Whizamet? 
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                           
                            <p>We provide our clients with detailed analytics and performance reports, including call volumes, satisfaction scores, and service level adherence to offer them complete transparency and valuable insights for improvement.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How can I ensure the safety and integrity of sensitive data related to my business?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We have deployed robust security frameworks to protect your sensitive information from unauthorized use. Moreover, our staff is bound by non-disclosure agreements, a violation of which could lead to legal action.
                            </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How many languages do you support?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>The majority of our clients come from the US and UK. Therefore, we provide support primarily in English. However, our team is proficient in offering multi-lingual support as well to cater to diverse needs. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        What makes Whizamet stand out from competitors in the industry?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our integration of advanced technologies, cutting-edge infrastructure and interaction platforms help us stand out from a myriad of competitors. Furthermore, our detailed performance reports and transparent insights allow our clients to track progress and make informed decisions.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Bpofaq