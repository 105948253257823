import Footer_v1 from '../global-components/footer';
import NavbarV3 from '../global-components/navbar-v3';
import Faqsmm from '../section-components/faqsmm';
import question from '../../images/question.png';
import html from '../../images/html.png';
import css from '../../images/css.png';
import word from '../../images/word.png';
import js from '../../images/js.png';
import wix from '../../images/wix.png';
import pa from '../../images/pa.png';
import cold from '../../images/cold.png';
import shopi from '../../images/shopi.png';
import git from '../../images/git.png';
import react from '../../images/react.png';
import php from '../../images/php.png';
import boot from '../../images/boot.png';

import WebSlider from '../section-components/webslider';
import WebVideo from '../section-components/webvideo';
import circle from  '../../images/circle.png';
import CustomerCentricApproach from  '../../images/CustomerCentricApproach.png';
import CustomSolutions from  '../../images/CustomSolutions.png';
import DataSecurityAssurance from  '../../images/DataSecurityAssurance.png';
import QualityAssurance from  '../../images/QualityAssurance.png';
import QuickDelivery from  '../../images/QuickDelivery.png';
import SmoothNavigation from  '../../images/SmoothNavigation.png';
import Transparency from  '../../images/Transparency.png';
import ExpertDevelopers from  '../../images/ExpertDevelopers.png';
import $ from 'jquery';
import React, { useEffect } from 'react';
import Webfaq from '../section-components/Webdfaq';
import { Helmet } from 'react-helmet';
import faqvideo from '../../images/faqvideo.mp4';
import responsivedesign from '../../images/responsivedesign.png';
import uiuxdesign from '../../images/uiuxdesign.png';
import seodesign from '../../images/seodesign.png';
import customdesign from '../../images/customdesign.png';
import Serivcecontactform from '../section-components/serivcecontactform';



const WebDesigning = () => {
  useEffect(() => {
    const initializeSlick = () => {
      $('.center-slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        speed: 300,
        centerPadding: '20px',
        infinite: true,
        autoplaySpeed: 5000,
        autoplay: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    };

    // Check if the DOM is ready before initializing Slick
    if (document.readyState === 'complete') {
      initializeSlick();
    } else {
      $(document).ready(initializeSlick);
    }
  }, []);
    // Adjust slidesToShow based on screen width
    $(window).on('resize', function() {
      var windowWidth = $(window).width();
      var slidesToShow = (windowWidth < 768) ? 3: 3;
      $('.center-slider').slick('slickSetOption', 'slidesToShow', slidesToShow, true);
    });
    $(window).on('resize', function() {
      var windowWidth = $(window).width();
      var slidesToShow = (windowWidth < 1400) ? 3: 5;
      $('.center-slider').slick('slickSetOption', 'slidesToShow', slidesToShow, true);
    });
    $(window).on('resize', function() {
      var windowWidth = $(window).width();
      var slidesToShow = (windowWidth < 480) ? 1: 5;
      $('.center-slider').slick('slickSetOption', 'slidesToShow', slidesToShow, true);
    });
    return (
<>
<NavbarV3 />
<Helmet>
<title>Best Website Development Company in India</title>
<meta name="description" content="Whizamet is the best website development company in India specializing in designing captivating, user-friendly and responsive websites. Get in touch with us today." />
</Helmet>
<section className='websbner'>
    <div className='container'>
        <div className='row'>
<div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
            <div className='seocontentbg1'>
            <h1>Website Design & Development</h1>
                <p className='bnrtext'> We help you stand out in the digital world with our innovative, flawless and custom websites. </p>
            </div>
            </div>
        </div>
        
    </div>
    <WebSlider/>
    <div className='left-seoshape1'></div>
    <img src={circle} className='seocircle' alt='circle' />
    <img src={circle} className='seocircle2' alt='circle' />
</section>


<section className='webserv'>
    <div className='container'>
        <div className='row'>
          <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
          <div className='Personalized'>
          <h3 class="mainsubheading">Our core services </h3>
          <h2 className='webheadong'>We design custom-based websites that generate qualified leads </h2>
          </div>
          </div>

          <div className='row webpad just'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-center'>
            <img src={responsivedesign} className='' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h3 className='reshead'>Responsive Web Design </h3>
                <p class="content">At Whizamet, our mobile-first approach is the cornerstone of our innovative techniques, designed to deliver exceptional results for our clients. We create mobile-friendly, responsively designed websites that seamlessly fit every screen size and enhance user experience.</p>
            </div>
            </div>

            <div className='row webpad just rwreverse'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
               <h3 className='reshead'>UI/UX design </h3>
                <p class="content">Exceptional user experiences take place only through top-notch UI/UX design services. At Whizamet, we bring together a well-planned UI/UX design strategy with high-end design aesthetics to create a smooth and intuitive user experience for your website visitors. </p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-center'>
            <img src={uiuxdesign} className='' alt='' />
            </div>
            </div>

            <div className='row webpad just'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-center'>
            <img src={seodesign} className='' alt='' />
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                <h3 className='reshead'>SEO-friendly web design </h3>
                <p class="content">We optimize every element of your website to attract high-quality traffic and boost your search engine visibility. From keyword-rich content to seamless navigation and responsive design, we use SEO-friendly features to ensure your website ranks higher in search engines and drives more organic traffic.</p>
            </div>
            </div>

            <div className='row webpad just rwreverse'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
               <h3 className='reshead'>Custom Designs</h3>
                <p class="content">Our custom web design is the first step towards establishing a compelling brand presence. We strive to create customized websites with perfect user experiences that not only captivate your audience's interest but also motivate them to engage with your brand.  </p>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 text-center'>
            <img src={customdesign} className='' alt='' />
            </div>
            </div>

        </div>
    </div>
</section>


{/* <section className='webserv'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our core services </h3>
     <h2 className='webheadong'>We design custom-based websites that generate qualified leads
</h2>
     </div>
        </div>
            </div>
            <div className="row">
             
        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web1} alt="" className="localweb"/>

            </div>
            <div className="feature-content">
              <h5>Responsive Web Design </h5>
              <p>
              We create websites that provide seamless viewing across all devices.
              </p>
            </div>
          </div>
        </div>
   
        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web2} alt="" className="localweb"/>
            </div>
            <div className="feature-content">
              <h5>E-commerce website development </h5>
              <p>
              Our experts develop powerful online stores with functional payment gateways. 

              </p>
            </div>
          </div>
        </div>
  
        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web3} alt="" className="localweb"/>
            </div>
            <div className="feature-content">
              <h5>Content management solutions</h5>
              <p>
              We design websites by implementing robust content management solutions that allow you to update content easily. 
              </p>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web4} alt="" className="localweb"/>
            </div>
            <div className="feature-content">
              <h5>Website maintenance</h5>
              <p>
              We provide ongoing support & maintenance to improve your website’s security and performance. 

              </p>
            </div>
          </div>
        </div>
   
        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web5} alt="" className="localweb"/>
            </div>
            <div className="feature-content">
              <h5>SEO-friendly web design </h5>
              <p>
              Our websites are optimized for search engines to ensure better visibility and traffic attraction. 

              </p>
            </div>
          </div>
        </div>
   
        <div className="col-sm-6 col-lg-4">
          <div className="feature-box-1">
            <div className="icon">
            <img src={web6} alt="" className="localweb"/>
            </div>
            <div className="feature-content">
              <h5>UI/UX design </h5>
              <p>
              We create stunning user interfaces & experiences that attract visitors and boost conversions. 
              </p>
            </div>
          </div>
        </div>
        </div>
  
</div>
</section> */}


<section className='grphictools'>
    <div className='container'>
        <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-4 col-md-6 col-12'>
             <div class="seo2-left">
             <div class="seo2-left-inner"> 
             <div>
             <h3 class="mainsubheading">Technologies we use</h3>
              <h2>Our weapons for creating <br />killer designs</h2> 
              <p class="content">Technology leads to the best results when used effectively. By harnessing the power of cutting-edge technologies, we turn visions into realities.</p>
              </div>
               </div>
                </div> 
                </div>
                <div className='col-xxl-7 col-xl-7 col-lg-8 col-md-6 col-12' id='gtool'>
                <div className='row' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={html} alt='' className='localweb' />
               
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={css} alt='' className='localweb' />
            
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={wix} alt='' className='localweb' />

                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={word} alt='' className='localweb' />
       
                        </div>
                    </div>
                </div>
                <div className='row' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={js} alt='' className='localweb' />
             
                       
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={pa} alt='' className='localweb' />

                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={cold} alt='' className='localweb' />
             
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={shopi} alt='' className='localweb' />
            
                        </div>
                    </div>
                </div>
                <div className='row last' id='fully'>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6' >
                        <div className='stoll'>
                        <img src={git} alt='' className='localweb' />
                 
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={react} alt='' className='localweb' />
                       
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                        <img src={php} alt='' className='localweb' />
                  
                        </div>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6'>
                    <div className='stoll'>
                    <img src={boot} alt='' className='localweb' />
        
                        </div>
                    </div>
                </div>
                </div> 
            </div>
          
        </div>
  
 </section>

{/* <section className='webicons'>
    <div className='container'>
        <div className='row'>
           
        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-6 col-12">
    <div className="seo2-left">
      <div className="seo2-left-inner">
        <div>
          <h3 className="mainsubheading animated fadeIn">Our technologies </h3>
          <h2>
          Our extensive tech capabilities 

          </h2>
          <p className="content">
          At Whizamet, we stay at the forefront of emerging web technologies that allow us to create high-performing websites that engage users. Our web designers use diverse frameworks, tools and programming languages to design appealing websites. 
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-6 col-12">
    <div className="row" id="fully">
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
        <img
          src={html}
        
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
          src={css}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
           src= {wix}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
          src={word}
            alt=""
            className="localweb"
          />
        </div>
      </div>
    </div>
    <div className="row" id="fully">
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
            src={js}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
           src={pa}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
           src={cold}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
          src={shopi}
            alt=""
            className="localweb"
          />
        </div>
      </div>
    </div>
    <div className="row" id="fully">
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
           src={git}
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
          src={react}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
        <div className="stoll2">
          <img
          src={php}
            alt=""
            className="localweb"
          />
        </div>
      </div>
      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-6">
      <div className="stoll2">
          <img
          src={boot}
            alt=""
            className="localweb"
          />
        </div>
      </div>
    </div>
  </div>
        </div>
    </div>
    


</section> */}


{/* <div className='section'>
    <video src={webagency}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='wb'> 
</video>
</div> */}


<section className='whyweb'>
<div className='container'>
    <div className='row'>
        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12'>
        <div className='Personalized'>
        <h3 class="mainsubheading">Our advantages </h3>
        <h2 className='webheadong'>Our services come with a bag full of advantages </h2>
     </div>
        </div>
            </div>
<div className='row' id='business'>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={CustomSolutions} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4><a href="/">Custom solutions </a></h4>
    <p>We specialize in crafting bespoke websites that align with your vision, goals and brand identity. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={CustomerCentricApproach} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Customer-centric approach </h4>
    <p>We understand every stage of your user journey to create an interactive & smooth user experience. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={Transparency} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Transparency </h4>
    <p>Our open communication loop allows you to engage with our developers anytime. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={ExpertDevelopers} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Expert developers </h4>
    <p>Our developers possess exceptional expertise in creating websites with zero tech hurdles. </p>
    </div>
    </div>
    </div>
    </div>
    <div className='row'>
    </div>
    </div>
    <div className='row' id='business'>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={QualityAssurance} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Quality assurance </h4>
    <p>Our seasoned quality analysts leave no room for bugs. We make sure that your website delivers outstanding performance.
</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={DataSecurityAssurance} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Data security assurance</h4>
    <p>Our robust tech solutions safeguard your valuable information from threats and breaches.
</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={QuickDelivery} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Quick delivery </h4>
    <p>Your website’s quality is of utmost importance to us. We strive to deliver it promptly without unnecessary delays.
</p>
    </div>
    </div>
    </div>
    </div>
    <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12'>
    <div className='aallserweb'>
    <div class="ltn__feature-item ltn__feature-item-3">
    <div class="ltn__feature-icon">
    <span>
    <img src={SmoothNavigation} className='speaker' alt='' />
    </span></div>
    <div class="ltn__feature-info">
    <h4>Smooth navigation </h4>
    <p>Our finest solutions guarantee effortless navigation, creating a seamless user experience. 
</p>
    </div>
    </div>
    </div>
    </div>
    </div>
      </div>
</section>

<WebVideo/>



<section className='homefaqs'>
    <div className='container'>
      <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <video src={faqvideo}   controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop id='faqvideo'> 
          </video>
          {/* <img src={question} alt='' className='question' /> */}
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
        <h3 className='mainsubheading'>Your doubts answered </h3>
          <h2>Frequently Asked Questions</h2>
      <Webfaq /> 
        </div>
      </div>
    </div>
   </section>

   <Serivcecontactform />

<Footer_v1 />
</>
)
}

export default WebDesigning

