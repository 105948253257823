import React, { Component } from 'react';


class Faqdigital extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__faq-area">
        
                <div className="ltn__faq-inner ltn__faq-inner-2">
                    <div id="accordion_2">
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-1" aria-expanded="false">
                        How do you ensure that digital marketing strategy aligns with our business goals?
                        </h3>
                        <div id="faq-item-2-1" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We take a personalized approach to understanding your business objectives and target audience. Our team conducts in-depth research and analysis to develop a customized digital marketing strategy that drives results and aligns with your specific brand goals.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-2" aria-expanded="true"> 
                        How do your digital marketing services stand out from the others in the industry?
                        </h3>
                        <div id="faq-item-2-2" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <div className="ltn__video-img alignleft">
                            </div>
                            <p>Our innovative strategies, data-driven approach, and dedication to client success help us stand out from the clutter. We leverage cutting-edge tools and technologies to deliver measurable results and maximize ROI for our clients.</p>
                        </div>
                        </div>
                    </div>                          
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-3" aria-expanded="false">
                        How do you measure the success of digital marketing campaigns?
                        </h3>
                        <div id="faq-item-2-3" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>We consistently track key performance indicators, such as website traffic, conversion rates, lead generation, and ROI, to measure the success of our digital marketing campaigns. Moreover, our robust analytics and reporting tools provide real-time insights into campaign performance.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-4" aria-expanded="false">
                        How do you stay updated with the latest trends and technologies in digital marketing?
                        </h3>
                        <div id="faq-item-2-4" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>Our team of digital marketing experts undergo regular training and certification programs to stay updated with the emerging trends and technologies in the industry. Furthermore, we attend industry conferences, participate in webinars, and engage with online communities to stay ahead of the curve.</p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                    <div className="card">
                        <h3 className="collapsed ltn__card-title" data-bs-toggle="collapse" data-bs-target="#faq-item-2-5" aria-expanded="false">
                        Why should I choose Whizamet’s digital marketing services over others?
                        </h3>
                        <div id="faq-item-2-5" className="collapse" data-bs-parent="#accordion_2">
                        <div className="card-body">
                            <p>There are several reasons to choose our digital marketing services over others, such as our proven expertise, personalized approach, transparent communication, global expertise and commitment to delivering results. We provide unparalleled support and expertise every step of the way to help our clients achieve their objectives. </p>
                        </div>
                        </div>
                    </div>
                    {/* card */}
                
                    </div>
                   
                </div>
                </div>
   
  
        }
}

export default Faqdigital